import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';

import contractReducer from './slices/contract.slice';
import contractsReducer from './slices/contracts.slice';
import userReducer from './slices/user.slice';

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    user: userReducer,
    contract: contractReducer,
    contracts: contractsReducer,
  });

export default rootReducer;
