// eslint-disable-next-line import/no-extraneous-dependencies
import { Contracts } from '@mbdt/shared/datacy';
import { Button, Form, Input, Select } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { dropdownOptions } from '../../constants';
import { ContractUIContext } from '../../containers/Contract/ContractUIContext';
import { addPlacement } from '../../store/actions/contract.actions';

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const AddPlacementMagazine = ({ isOpen, setIsOpen }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id, mediaType } = useParams();
  const { isAddingPlacement } = useSelector((state) => state.contract);
  const { activeTab: sectionType } = useContext(ContractUIContext);

  const handleOk = () => {
    form.resetFields();
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        const placementName = form.getFieldValue(['placementName']);

        const valuesObj = Object.entries(values).reduce((acc, [key, value]) => {
          // Remove medium from object
          if (key === 'weighting') return acc;

          return {
            ...acc,
            [key]: {
              currentPhaseValue: value,
            },
          };
        }, {});

        await dispatch(
          addPlacement(
            id,
            mediaType,
            { ...valuesObj, placementName },
            sectionType,
            null,
            handleOk
          )
        );
      })
      .catch((info) => {
        // eslint-disable-next-line no-console
        console.log('Validation Failed:', info);
      });
  };

  return (
    <>
      <Modal
        visible={isOpen}
        title="Add a new placement"
        onCancel={handleCancel}
        destroyOnClose
        forceRender
        maskClosable={false}
        footer={[
          <Button
            key="back"
            onClick={handleCancel}
            data-cy={`${Contracts.AddNewPlacementModal}-${Contracts.PlacementModalCancel}`}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isAddingPlacement}
            onClick={handleSubmit}
            data-cy={`${Contracts.AddNewPlacementModal}-${Contracts.PlacementModalAdd}`}
          >
            Add placement
          </Button>,
        ]}
        data-cy={Contracts.AddNewPlacementModal}
      >
        <Form
          form={form}
          layout="horizontal"
          name="add-placement"
          {...formItemLayout}
        >
          <Form.Item
            name="placementName"
            label="Medium"
            rules={[
              {
                required: true,
                message: 'Please enter a medium',
              },
            ]}
          >
            <Input
              placeholder="Enter a medium"
              style={{ width: '100%' }}
              disabled={isAddingPlacement}
              data-cy={Contracts.FieldMedium}
            />
          </Form.Item>

          <Form.Item
            name="frequency"
            label="Frequency"
            rules={[
              {
                required: true,
                message: 'Please select a frequency',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a frequency"
              disabled={isAddingPlacement}
              data-cy={Contracts.FieldFrequency}
            >
              {Object.entries(dropdownOptions.frequency).map(
                ([key, value], index) => (
                  <Option
                    key={key}
                    value={value}
                    data-cy={`${Contracts.FieldFrequency}-${Contracts.SelectItem}${index}`}
                  >
                    {value}
                  </Option>
                )
              )}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

AddPlacementMagazine.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default AddPlacementMagazine;
