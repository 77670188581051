import {
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  InboxOutlined,
  LockOutlined,
} from '@ant-design/icons';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Contracts } from '@mbdt/shared/datacy';
import React from 'react';
import { useSelector } from 'react-redux';

import { contractStatusLabels } from '../../constants';
import {
  selectIsContractArchived,
  selectIsContractInClientReview,
  selectIsContractInReview,
  selectIsContractInRevision,
  selectIsContractLocked,
} from '../../store/selectors';
import ContractStatusTag from './ContractStatusTag';

const ContractStatus = () => {
  const isContractInReview = useSelector(selectIsContractInReview);
  const isContractInRevision = useSelector(selectIsContractInRevision);
  const isContractInClientReview = useSelector(selectIsContractInClientReview);
  const isContractLocked = useSelector(selectIsContractLocked);
  const isContractArchived = useSelector(selectIsContractArchived);

  return (
    <>
      {isContractInReview && (
        <ContractStatusTag
          label={contractStatusLabels.agencyToReview}
          color="warning"
          icon={<ClockCircleOutlined />}
          dataCy={Contracts.ContractStatusTag}
        />
      )}

      {isContractInRevision && (
        <ContractStatusTag
          label={contractStatusLabels.vendorToReview}
          color="error"
          icon={<ExclamationCircleOutlined />}
          dataCy={Contracts.ContractStatusTag}
        />
      )}

      {isContractInClientReview && (
        <ContractStatusTag
          label={contractStatusLabels.pendingApproval}
          color="processing"
          icon={<ClockCircleOutlined />}
          dataCy={Contracts.ContractStatusTag}
        />
      )}

      {isContractLocked && (
        <ContractStatusTag
          label={contractStatusLabels.locked}
          color="success"
          icon={<LockOutlined />}
          dataCy={Contracts.ContractStatusTag}
        />
      )}

      {isContractArchived && (
        <ContractStatusTag
          label={contractStatusLabels.archived}
          color="default"
          icon={<InboxOutlined />}
          dataCy={Contracts.ContractStatusTag}
        />
      )}
    </>
  );
};

export default ContractStatus;
