/* eslint-disable react/forbid-prop-types */
import './CustomTable.less';

// eslint-disable-next-line import/no-extraneous-dependencies
import { Contracts as ContractDataCy } from '@mbdt/shared/datacy';
import { Empty, Table } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const CustomTable = ({
  data,
  columns,
  onRow,
  loading,
  paginationOptions,
  ...rest
}) => {
  return (
    <Table
      className="custom-table"
      dataSource={data}
      columns={columns}
      onRow={onRow}
      loading={loading}
      pagination={{
        style: { padding: '8px 24px' },
        showSizeChanger: true,
        ...paginationOptions,
      }}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No data"
            data-cy={ContractDataCy.ContractTableEmptyData}
          />
        ),
      }}
      {...rest}
    />
  );
};

CustomTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  onRow: PropTypes.func,
  loading: PropTypes.bool,
  paginationOptions: PropTypes.object,
};

export default CustomTable;
