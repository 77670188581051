import React from 'react';
import { useParams } from 'react-router-dom';

import { mediaTypes } from '../../constants';
import { getKeyByValue } from '../../helpers';
import AddPlacementCinema from './AddPlacementCinema';
import AddPlacementDigital from './AddPlacementDigital';
import AddPlacementMagazine from './AddPlacementMagazine';
import AddPlacementNewspaper from './AddPlacementNewspaper';
import AddPlacementOOH from './AddPlacementOOH';
import AddPlacementRadio from './AddPlacementRadio';
import AddPlacementTelevision from './AddPlacementTelevision';

const AddPlacementModal = ({ ...rest }) => {
  const { mediaType } = useParams();

  const renderAddPlacementModal = () => {
    switch (mediaType) {
      case getKeyByValue(mediaTypes, mediaTypes.newspapers): {
        return <AddPlacementNewspaper {...rest} />;
      }

      case getKeyByValue(mediaTypes, mediaTypes.television): {
        return <AddPlacementTelevision {...rest} />;
      }

      case getKeyByValue(mediaTypes, mediaTypes.cinema): {
        return <AddPlacementCinema {...rest} />;
      }

      case getKeyByValue(mediaTypes, mediaTypes.radio): {
        return <AddPlacementRadio {...rest} />;
      }

      case getKeyByValue(mediaTypes, mediaTypes.magazines): {
        return <AddPlacementMagazine {...rest} />;
      }

      case getKeyByValue(mediaTypes, mediaTypes.ooh): {
        return <AddPlacementOOH {...rest} />;
      }

      case getKeyByValue(mediaTypes, mediaTypes.digital): {
        return <AddPlacementDigital {...rest} />;
      }

      default:
        return null;
    }
  };
  return renderAddPlacementModal();
};

export default AddPlacementModal;
