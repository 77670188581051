/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  isLoading: false,
  error: null,
  itemCount: null,
};

const handleLoading = (state) => {
  state.isLoading = true;
  state.error = null;
};

const handleError = (state, { payload }) => {
  state.error = payload;
  state.isLoading = false;
};

const contracts = createSlice({
  name: 'contracts',
  initialState,
  reducers: {
    // Fetch Contract List
    fetchContractListStart: handleLoading,
    fetchContractListSuccess(state, { payload }) {
      state.data = payload;
      state.isLoading = false;
    },
    fetchContractListFailure: handleError,
    updateItemCount(state, { payload }) {
      state.itemCount = Number(payload);
    },
    resetContractsState() {
      return initialState;
    },
  },
});

export const {
  fetchContractListStart,
  fetchContractListSuccess,
  fetchContractListFailure,
  updateItemCount,
  resetContractsState,
} = contracts.actions;

export default contracts.reducer;
