/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line import/no-extraneous-dependencies
import { Contracts } from '@mbdt/shared/datacy';
import { Space } from 'antd';
// import { Button } from 'antd/lib/radio';
import React from 'react';
import { useSelector } from 'react-redux';

import ContractStatusTag from '../ContractHeader/ContractStatusTag';

const RoundsMenu = () => {
  const {
    data: { phase: round },
  } = useSelector((state) => state.contract);
  const roundLabel = round === 0 ? 'Draft' : `Round ${round}`;

  // TODO: This has been temporarily hidden because we will use this code later on
  // when there is time/budget to work on the feature to switch between rounds
  // const DropdownMenu = () => (
  //   <Menu>
  //     {[...Array(round + 1).keys()].map((value) => (
  //       <Menu.Item key={value}>{roundLabel}</Menu.Item>
  //     ))}
  //   </Menu>
  // );

  return (
    // TODO: This has been temporarily hidden because we will use this code later on
    // when there is time/budget to work on the feature to switch between rounds
    // <Dropdown overlay={DropdownMenu} trigger={['click']}>
    //   <Button
    //     type="button"
    //     className="ant-dropdown-link"
    //     onClick={(e) => e.preventDefault()}
    //   >
    //     {roundLabel}
    //     <DownOutlined style={{ marginLeft: 8 }} />
    //   </Button>
    // </Dropdown>
    <Space>
      <ContractStatusTag
        color="default"
        label={roundLabel}
        style={{
          background: 'white',
          paddingLeft: 16,
          paddingRight: 16,
          height: 32,
          display: 'flex',
          alignItems: 'center',
          marginRight: 0,
          fontWeight: 500,
          fontSize: 14,
        }}
        dataCy={Contracts.ContractRound}
      />
    </Space>
  );
};

export default RoundsMenu;
