import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
} from '@ant-design/icons';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

export const ToastSuccess = ({ message }) => (
  <div style={{ display: 'flex', alignItems: 'flex-start' }}>
    <CheckCircleTwoTone
      twoToneColor="#52c41a"
      style={{ marginRight: 12, fontSize: 24 }}
    />
    <Typography>{message}</Typography>
  </div>
);

ToastSuccess.propTypes = {
  message: PropTypes.string.isRequired,
};

export const ToastError = ({ message }) => (
  <div style={{ display: 'flex', alignItems: 'flex-start' }}>
    <ExclamationCircleTwoTone
      twoToneColor="#FF3B30"
      style={{ marginRight: 12, fontSize: 24 }}
    />
    <Typography>{message}</Typography>
  </div>
);

ToastError.propTypes = {
  message: PropTypes.string.isRequired,
};
