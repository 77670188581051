import { Layout } from 'antd';
import React from 'react';

import SiteLayout from '../../components/Layout/Layout';

const { Content } = Layout;

const Dashboard = () => {
  return (
    <SiteLayout>
      <Content style={{ margin: '24px 16px 0' }}>
        <div
          className="site-layout-background"
          style={{ padding: 24, minHeight: 360, whiteSpace: 'pre-wrap' }}
        >
          <p>Dashboard</p>
        </div>
      </Content>
    </SiteLayout>
  );
};

export default Dashboard;
