/* eslint-disable consistent-return */
// eslint-disable-next-line import/no-extraneous-dependencies
import Calculations from '@mbdt/shared/calculation';
import { Form, Input, Select, Tooltip } from 'antd';
import Text from 'antd/lib/typography/Text';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import {
  columnWidths,
  contractStatuses,
  displayTypePosition,
  displayTypes,
  displayTypeSymbol,
  dropdownOptions,
  fullColourLoadDisplayTypes,
  placementFields,
} from '../../constants';
import { useContractForm } from '../../context/contractForm-context';
import { checkIsRecordObject } from '../../helpers';
import PreviousYearData from '../Common/PreviousYearData';
import InputDecimal from './InputDecimal';
import InputInteger from './InputInteger';
import InputMoney from './InputMoney';
import InputPercentage from './InputPercentage';

const { Option } = Select;

const EditableCell = React.memo(
  ({
    title,
    editable,
    required = true,
    isEditing,
    children,
    dataIndex,
    record,
    handleSave,
    displayType,
    displayTypePos,
    fieldName,
    sectionId,
    showPrevData,
    ...restProps
  }) => {
    const inputRef = useRef(null);
    const formContext = useContractForm();

    // Override display type
    const [inputDisplayType, setInputDisplayType] = useState(displayType);

    // For Market column width
    const marketColStyle = 'placementMarket';
    const sizeSpecColStyle = 'sizeSpecifications';

    // Determine whether the fieldName property value is an object with nested values
    // (used to display multiple values in the cell)
    const isRecordObject = checkIsRecordObject(record, fieldName);

    // Return fullColourLoadValue based on costType/colourLoadType
    const getFullColourLoadValue = (costType) => {
      if (
        costType !== dropdownOptions.costType.Dollar &&
        costType !== dropdownOptions.costType.Percentage
      ) {
        return costType;
      }
      return Number(record?.data[fieldName]?.currentPhaseValue) || 0;
    };

    // Debounce the handleSave function when the user is typing in the input field
    const debounced = useDebouncedCallback((value) => {
      handleSave(value);
    }, 500);

    // Save the input field when the value changes
    const save = async () => {
      try {
        const value = formContext.state.forms[sectionId].getFieldValue([
          `${sectionId}-${record.id}-${fieldName}`,
        ]);

        // Special Case for Newspapers
        // Update fullColourLoad value and input type when colourLoadType changes
        if (fieldName === placementFields.newspaper.colourLoadType) {
          const fullColourLoad = getFullColourLoadValue(value);

          formContext.state.forms[sectionId].setFieldValue(
            `${sectionId}-${record.id}-${placementFields.newspaper.fullColourLoad}`,
            fullColourLoad
          );

          handleSave({
            recordId: record.id,
            fieldName: 'fullColourLoad',
            currentPhaseValue: fullColourLoad,
          });
        }

        debounced({
          recordId: record.id,
          fieldName,
          currentPhaseValue: value,
        });
      } catch (errInfo) {
        // eslint-disable-next-line no-console
        console.log('Save failed:', errInfo);
      }
    };

    // Retrieve the initial value for form.item
    const getInitialValue = () => {
      if (isRecordObject) {
        // Special Case for Newspapers
        // Update fullColourLoad value and input type when colourLoadType changes
        if (fieldName === placementFields.newspaper.fullColourLoad) {
          const costType =
            record?.data[placementFields.newspaper.colourLoadType]
              ?.currentPhaseValue;

          return getFullColourLoadValue(costType);
        }
        return record.data[fieldName].currentPhaseValue;
      }

      return children;
    };

    // Format any data that needs some massaging
    const formatData = (valueDisplayType, value) => {
      if (value !== null && value !== '-' && value !== '') {
        switch (valueDisplayType) {
          case displayTypes.money:
          case displayTypes.percentage:
            return Calculations.formatCalculatedValue(value, 2).toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            );
          case displayTypes.integer:
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          default:
            return value;
        }
      }
      return '-';
    };

    // Retrieve the current data to display in the field
    const renderCurrentData = () => {
      if (isRecordObject) {
        if (record.data[fieldName].displayType === displayTypes.string) {
          return (
            <Tooltip
              placement="bottomLeft"
              title={record.data[fieldName].currentPhaseValue}
            >
              {record.data[fieldName].currentPhaseValue
                ? record.data[fieldName].currentPhaseValue
                : '-'}
            </Tooltip>
          );
        }
        return (
          <Text>
            {record.data[fieldName].currentPhaseValue !== null
              ? `
              ${
                displayTypePos === displayTypePosition.prefix
                  ? displayTypeSymbol.money
                  : ''
              }
              ${formatData(
                record.data[fieldName].displayType,
                record.data[fieldName].currentPhaseValue
              )}
              ${
                displayTypePos === displayTypePosition.suffix
                  ? displayTypeSymbol.percentage
                  : ''
              }
            `
              : '-'}
          </Text>
        );
      }

      return children;
    };

    // Retrieve the previous year data to display in the field
    const renderPreviousYearData = () => {
      if (isRecordObject) {
        return (
          <PreviousYearData
            value={record.data[fieldName].previousYearValue}
            fontSize={11}
            prefix={
              displayTypePos === displayTypePosition.prefix
                ? displayTypeSymbol.money
                : undefined
            }
            suffix={
              displayTypePos === displayTypePosition.suffix
                ? displayTypeSymbol.percentage
                : undefined
            }
            precision={
              record.data[fieldName].displayType === displayTypes.money ||
              record.data[fieldName].displayType === displayTypes.percentage
                ? 2
                : 0
            }
          />
        );
      }

      return null;
    };

    // Determine what component to render for the Input Field (ie. InputNumber, Input, Dropdown)
    const renderInputNode = () => {
      switch (inputDisplayType) {
        case displayTypes.integer: {
          return (
            <InputInteger
              ref={inputRef}
              onChange={save}
              style={{
                fontSize: 12,
                position: 'relative',
              }}
              placeholder={record.data[fieldName].label}
              stringMode
              data-cy={record?.dataCy || ''}
            />
          );
        }
        case displayTypes.decimal: {
          return (
            <InputDecimal
              ref={inputRef}
              onChange={save}
              style={{
                fontSize: 12,
                position: 'relative',
              }}
              placeholder={record.data[fieldName].label}
              stringMode
              data-cy={record?.dataCy || ''}
            />
          );
        }
        case displayTypes.money: {
          return (
            <InputMoney
              ref={inputRef}
              onChange={save}
              style={{
                fontSize: 12,
                position: 'relative',
              }}
              placeholder={record.data[fieldName].label}
              stringMode
              data-cy={record?.dataCy || ''}
            />
          );
        }
        case displayTypes.percentage: {
          return (
            <InputPercentage
              ref={inputRef}
              onChange={save}
              style={{
                fontSize: 12,
                position: 'relative',
              }}
              placeholder={record.data[fieldName].label}
              stringMode
              data-cy={record?.dataCy || ''}
            />
          );
        }

        case displayTypes.dropdown:
          return (
            <Select
              data-cy={record?.dataCy || ''}
              placeholder="Select one"
              onChange={save}
              style={{ fontSize: 12 }}
            >
              {dropdownOptions[fieldName] &&
                Object.entries(dropdownOptions[fieldName]).map(
                  ([value, label]) => <Option value={value}>{label}</Option>
                )}
            </Select>
          );

        default: {
          return (
            <Input
              ref={inputRef}
              onChange={save}
              placeholder={record.data[fieldName].label}
              style={{ fontSize: 12 }}
              data-cy={record?.dataCy || ''}
            />
          );
        }
      }
    };

    // Determine whether to show the Form element (if user is in editMode) or a div (if user is in viewMode)
    // as the childNode that is displayed in the table cell
    let childNode = children;

    if (editable && record?.data?.status !== contractStatuses.approved) {
      childNode = isEditing ? (
        <Form.Item
          initialValue={getInitialValue()}
          style={{
            margin: 0,
          }}
          name={`${sectionId}-${record.id}-${fieldName}`}
          rules={[
            {
              required,
              message: `Field is required.`,
            },
          ]}
          validateTrigger="onChange"
        >
          {renderInputNode()}
        </Form.Item>
      ) : (
        <div
          className="ant-table-cell-ellipsis"
          style={{
            paddingRight: 24,
            maxWidth: columnWidths[fieldName] || '250px',
          }}
        >
          {renderCurrentData()}
        </div>
      );
    } else {
      childNode = renderCurrentData();
    }

    useEffect(() => {
      // Rerender input type for Newspaper fullColourLoad
      if (
        fieldName === placementFields.newspaper.fullColourLoad &&
        formContext &&
        formContext.state.forms[sectionId]
      ) {
        const costType = formContext.state.forms[sectionId].getFieldValue([
          `${sectionId}-${record.id}-${placementFields.newspaper.colourLoadType}`,
        ]);

        setInputDisplayType(fullColourLoadDisplayTypes[costType]);
      }
    }, [formContext]);

    return (
      <td {...restProps}>
        <div
          style={{
            display: 'flex',
            flexFlow: 'column wrap',
            whiteSpace:
              fieldName === marketColStyle || fieldName === sizeSpecColStyle
                ? 'normal'
                : 'unset',
          }}
        >
          {childNode}
          {showPrevData && renderPreviousYearData()}
        </div>
      </td>
    );
  }
);

EditableCell.displayName = 'EditableCell';

// TODO: Update these propTypes
/* eslint-disable react/forbid-prop-types */
EditableCell.propTypes = {
  required: PropTypes.bool,
  editable: PropTypes.any,
  isEditing: PropTypes.any,
  dataIndex: PropTypes.any,
  title: PropTypes.any,
  displayType: PropTypes.string,
  displayTypePos: PropTypes.string,
  record: PropTypes.any,
  index: PropTypes.any,
  children: PropTypes.any,
  prefix: PropTypes.any,
  handleSave: PropTypes.any,
  fieldName: PropTypes.any,
  sectionId: PropTypes.any,
  showPrevData: PropTypes.bool,
};

export default EditableCell;
