// eslint-disable-next-line import/no-extraneous-dependencies
import Calculations from '@mbdt/shared/calculation';
import { createSlice } from '@reduxjs/toolkit';

import {
  bonus,
  calculationDecimalPlaces,
  placementFields,
  sectionTypes,
} from '../../constants';

const initialState = {
  data: {},
  overview: {},
  tableData: [],
  isLoading: false,
  isEditing: true,
  isSaving: false,
  isSubmittingRates: false,
  isUpdatingPlacement: false,
  isAddingPlacement: false,
  error: null,
  mediumsByNetwork: [],
  visualCues: null,
  bonus: {},
};

const handleLoading = (state) => {
  state.isLoading = true;
  state.error = null;
};

const handleError = (state, { payload }) => {
  state.error = payload;
  state.isLoading = false;
};

const isTelevisionMedia = (payload) => payload?.mediaType === 'Television';

const contract = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    // Fetch Contract
    fetchContractStart: handleLoading,
    fetchContractSuccess(state, { payload }) {
      const overview = payload.sections.find(({ type }) => type === 'overview');
      const tableData = payload.sections.filter(
        ({ type }) => type !== 'overview'
      );

      // clearing bonus state if changed to another media type
      if (!isTelevisionMedia(payload) && Object.keys(state?.bonus).length > 0) {
        state.bonus = {};
      }

      if (isTelevisionMedia(payload)) {
        // Extract the necessary data from the overview object
        const {
          contractualBonus = null,
          contractualBonusYOY = null,
          id = null,
        } = overview.data || {};

        // Construct the contractualBonus object
        const contractualBonusData = {
          type: 'contractual_bonus',
          label: 'Contractual Bonus',
          data: {
            contractualBonus,
            contractualBonusYOY,
          },
          id,
        };

        // Update the state with the new contractualBonus object
        state.bonus = contractualBonusData;
      }
      state.data = payload;
      state.overview = overview;
      state.tableData = tableData;
      state.isLoading = false;
      state.isSaving = false;
      state.isSubmittingRates = false;
      state.isUpdatingPlacement = false;
      state.isAddingPlacement = false;
      state.isRemovingPlacement = false;
      state.isRemovingWeighting = false;
      state.visualCues = payload.visualCues;
      state.sectionTypes = payload.sectionTypes;
      state.selectedPlacementRows = null;
      state.selectedWeightingRows = null;

      // Mock visualCues for testing
      // TODO: Remove these when no longer need to manual test them
      // state.visualCues = {
      //   canStartNegotiations: true,
      //   canSubmitForReview: true,
      //   canSubmitForRevision: true,
      //   canSubmitForApproval: true,
      //   canApproveTemplate: false,
      //   canEditPlacement: true,
      //   canAddPlacement: true,
      //   canRemovePlacement: false,
      //   canReviewPlacement: true,
      // };
    },
    fetchContractFailure: handleError,

    // Toggle editing of contract rates
    toggleIsEditing(state, { payload }) {
      state.isEditing = payload;
    },

    // Update section data (ie. rates)
    updateSectionDataStart: handleLoading,
    updateSectionDataSuccess(state, { payload }) {
      const { recordId, fieldName, currentPhaseValue } = payload;
      // Special case for contractualBonus
      if (
        isTelevisionMedia(state?.data) &&
        fieldName === bonus.contractualBonus
      ) {
        state.bonus.data[fieldName].currentPhaseValue = currentPhaseValue;
        return;
      }
      const index = state.tableData.findIndex(({ id }) => id === recordId);

      if (index !== -1) {
        state.tableData[index].data[fieldName].currentPhaseValue =
          currentPhaseValue;

        // Special case for Radio Cost Per Thousand and CPMYoYVariance
        if (
          state.tableData[index].type === sectionTypes.radioPlacements &&
          (fieldName === placementFields.radio.averageAudience ||
            fieldName === placementFields.radio.clientRate)
        ) {
          const record = state.tableData[index];
          const { function: calculationName } =
            record.data[placementFields.radio.costPerThousand];

          // Set/Update costPerThousand on averageAudience or clientRate update
          const costPerThousand = Calculations.calculatePlacementValue(
            calculationName,
            record,
            calculationDecimalPlaces
          );
          state.tableData[index].data[
            placementFields.radio.costPerThousand
          ].currentPhaseValue = costPerThousand;
        }
      }
    },
    updateSectionDataFailure: handleError,

    // Save contract
    updateContractStart(state) {
      state.isSaving = true;
      state.error = null;
    },
    updateContractFailure(state, { payload }) {
      state.error = payload;
      state.isSaving = false;
      state.isLoading = false;
    },

    // Submit rates
    submitRatesStart(state) {
      state.isSubmittingRates = true;
      state.error = null;
    },

    submitRatesFailure(state, { payload }) {
      state.error = payload;
      state.isSubmittingRates = false;
      state.isSaving = false;
      state.isLoading = false;
    },

    discardContractChanges(state) {
      const { sections } = state.data;
      state.tableData = sections.filter(({ type }) => type !== 'overview');
      // Special case for contractualBonus
      if (state.bonus) {
        const overview = sections.find(({ type }) => type === 'overview')?.data;
        if (overview && state.bonus.data) {
          state.bonus.data.contractualBonus = overview.contractualBonus;
        }
      }
    },

    resetContractState() {
      return initialState;
    },

    updatePlacementStart(state) {
      state.isUpdatingPlacement = true;
      state.error = null;
    },

    updatePlacementSuccess(state, { payload }) {
      const dataIndex = state.data.sections.find(
        (section) => section.id === payload.id
      );
      const tableDataIndex = state.tableData.find(
        (section) => section.id === payload.id
      );

      state.data.sections[dataIndex] = payload;
      state.tableData[tableDataIndex] = payload;
    },

    updatePlacementFailure(state, { payload }) {
      state.error = payload;
      state.isUpdatingPlacement = false;
      state.isLoading = false;
    },

    // Add Placement to Contract
    addPlacementStart(state) {
      state.isAddingPlacement = true;
      state.error = null;
    },
    addPlacementFailure(state, { payload }) {
      state.error = payload;
      state.isAddingPlacement = false;
      state.isLoading = null;
    },

    addWeightingStart(state) {
      state.isAddingWeighting = true;
      state.error = null;
    },
    addWeightingFailure(state, { payload }) {
      state.error = payload;
      state.isAddingWeighting = false;
      state.isLoading = null;
    },

    removeWeightingStart(state) {
      state.isRemovingWeighting = true;
      state.error = null;
    },
    removeWeightingFailure(state, { payload }) {
      state.error = payload;
      state.isRemovingWeighting = false;
      state.isLoading = null;
    },

    // Set selected placements
    setSelectedPlacementRows(state, { payload }) {
      state.selectedPlacementRows = payload;
    },

    // Set selected weighting
    setSelectedWeightingRows(state, { payload }) {
      state.selectedWeightingRows = payload;
    },

    removePlacementsStart(state) {
      state.isRemovingPlacement = true;
      state.error = null;
    },
    removePlacementsFailure(state, { payload }) {
      state.error = payload;
      state.isRemovingPlacement = false;
      state.isLoading = null;
    },
  },
});

export const {
  fetchContractStart,
  fetchContractSuccess,
  fetchContractFailure,
  toggleIsEditing,
  updateSectionDataStart,
  updateSectionDataSuccess,
  updateSectionDataFailure,
  setContractHeaderHeight,
  setContractTableHeaderHeight,
  updateContractStart,
  updateContractFailure,
  submitRatesStart,
  submitRatesSuccess,
  submitRatesFailure,
  resetContractState,
  addPlacementStart,
  addPlacementFailure,
  setSelectedWeightingRows,
  setSelectedPlacementRows,
  removeWeightingStart,
  removeWeightingFailure,
  addWeightingStart,
  addWeightingFailure,
  removePlacementsStart,
  removePlacementsFailure,
  updatePlacementStart,
  updatePlacementFailure,
  discardContractChanges,
} = contract.actions;

export default contract.reducer;
