import { InfoCircleOutlined } from '@ant-design/icons';
import { Modal, Tooltip, Typography } from 'antd';
import { format } from 'date-fns';
import React from 'react';

import PlacementActions from '../components/Actions/PlacementActions';
import { EditedBadge } from '../components/Common/EditedBadge';
import PlacementStatus from '../components/TemplateTable/PlacementStatus';
import { columnWidths, placementTypes, statusInfo } from '../constants';

export const columnsRadioDurationMultipliers = [
  {
    title: 'Name',
    dataIndex: 'label',
    key: 'label',
    width: columnWidths.multiplierName,
    showPrevData: false,
  },
  {
    title: '15 sec',
    dataIndex: ['data', 'multiplier15sec'],
    key: 'multiplier15sec',
    width: columnWidths.durationMultiplier,
    showPrevData: true,
  },
  {
    title: '30 sec',
    dataIndex: ['data', 'multiplier30sec'],
    key: 'multiplier30sec',
    width: columnWidths.durationMultiplier,
    showPrevData: true,
  },
  {
    title: '45 sec',
    dataIndex: ['data', 'multiplier45sec'],
    key: 'multiplier45sec',
    width: columnWidths.durationMultiplier,
    ellipsis: true,
    showPrevData: true,
  },
  {
    title: '60 sec',
    dataIndex: ['data', 'multiplier60sec'],
    key: 'multiplier60sec',
    width: columnWidths.durationMultiplier,
    showPrevData: true,
  },
  {
    title: 'Year on Year Variance',
    fixed: 'right',
    children: [
      {
        title: '15 sec',
        dataIndex: ['data', 'multiplier15secYOYVariance'],
        key: 'multiplier15secYOYVariance',
        width: columnWidths.durationMultiplierYoY,
      },
      {
        title: '30 sec',
        dataIndex: ['data', 'multiplier30secYOYVariance'],
        key: 'multiplier30secYOYVariance',
        width: columnWidths.durationMultiplierYoY,
      },
      {
        title: '45 sec',
        dataIndex: ['data', 'multiplier45secYOYVariance'],
        key: 'multiplier45secYOYVariance',
        width: columnWidths.durationMultiplierYoY,
      },
      {
        title: '60 sec',
        dataIndex: ['data', 'multiplier60secYOYVariance'],
        key: 'multiplier60secYOYVariance',
        width: columnWidths.durationMultiplierYoY,
      },
    ],
  },
];

export const columnsRadioPlacements = [
  {
    width: columnWidths.actions,
    fixed: 'left',
    render: (record, status, index) => {
      return <PlacementActions placement={record} index={index} />;
    },
  },
  {
    title: 'Progress',
    dataIndex: ['data', 'status'],
    key: 'status',
    fixed: 'left',
    width: columnWidths.status,
    render: (status, record, index) => (
      <PlacementStatus
        status={status}
        placementId={record.id}
        disabled={record.data.placementType === placementTypes.RETIRED}
        index={index}
      />
    ),
  },
  {
    title: (
      <Typography.Text style={{ display: 'flex' }}>
        Status
        <Tooltip
          trigger="click"
          title={statusInfo}
          placement="top"
          color="#ffffff"
          mouseEnterDelay={0.2}
          overlayInnerStyle={{ color: 'rgba(0, 0, 0, 0.85)' }}
        >
          <span style={{ margin: '0 0 0 5px' }}>
            <InfoCircleOutlined />
          </span>
        </Tooltip>
      </Typography.Text>
    ),
    dataIndex: ['data', 'placementType'],
    key: 'placementType',
    width: columnWidths.placementType,
    showPrevData: false,
    fixed: 'left',
    render: (placementType, record) => {
      const commentInfo = () => {
        Modal.info({
          title: 'Retired Information',
          content: (
            <>
              <div style={{ margin: '10px 0 5px 0' }}>
                <b>Publication</b>:
                <span style={{ margin: '0 0 0 10px' }}>
                  {record?.data?.placementName}
                </span>
              </div>
              <div style={{ margin: '5px 0 5px 0' }}>
                <b>Retired On</b>:
                <span style={{ margin: '0 0 0 10px' }}>
                  {format(
                    new Date(record.data.retiredComments.updatedAt),
                    'yyyy-MM-dd, hh:mm:ss a zzz'
                  )}
                </span>
              </div>
              <div style={{ margin: '5px 0 5px 0' }}>
                <b>Retired By</b>:
                <span style={{ margin: '0 0 0 10px' }}>
                  {record?.data?.retiredComments?.updatedBy?.displayName}
                </span>
              </div>
              <div style={{ margin: '5px 0 5px 0' }}>
                <b>Comment</b>:
                <span style={{ margin: '0 0 0 10px' }}>
                  {record?.data?.retiredComments?.currentPhaseValue}
                </span>
              </div>
            </>
          ),
          onOk() {},
        });
      };
      return (
        <div>
          <Typography.Text>{placementType}</Typography.Text>
          {placementType === placementTypes.RETIRED &&
          record?.data?.retiredComments ? (
            <span style={{ margin: '0 0 0 5px' }}>
              <InfoCircleOutlined onClick={commentInfo} />
            </span>
          ) : null}
        </div>
      );
    },
  },
  {
    title: 'Medium',
    dataIndex: ['data', 'placementName'],
    key: 'placementName',
    fixed: 'left',
    render: (text, row) => (
      <>
        <Typography.Text style={{ display: 'block' }}>
          {row.data.placementName}
        </Typography.Text>
        {row.data.editedByVendor ? <EditedBadge /> : <></>}
      </>
    ),
  },
  {
    title: 'Classification',
    fixed: 'left',
    width: columnWidths.weightingName,
    render: (text, row) => {
      return (
        <div>
          <Typography.Text style={{ display: 'block' }}>
            {row.data.weighting.name}
          </Typography.Text>
        </div>
      );
    },
  },
  {
    title: 'State',
    dataIndex: ['data', 'state', 'currentPhaseValue'],
    key: 'placementState',
    width: columnWidths.state,
  },
  {
    title: 'Market',
    dataIndex: ['data', 'market', 'currentPhaseValue'],
    key: 'placementMarket',
    width: columnWidths.market,
  },
  {
    title: 'Demographic',
    dataIndex: ['data', 'demographic'],
    key: 'demographic',
    width: columnWidths.demographic,
    textWrap: 'word-break',
  },
  {
    title: 'Language',
    dataIndex: ['data', 'language'],
    ellipsis: true,
    showTitle: true,
    width: columnWidths.language,
    render: (text, row) => {
      const valueWithSpace = row.data.language.currentPhaseValue?.replace(
        /,/g,
        ', '
      );
      return (
        <div
          className="ant-table-cell-ellipsis"
          style={{
            width: columnWidths.language,
            maxWidth: columnWidths.language,
          }}
        >
          <Tooltip placement="topLeft" title={valueWithSpace}>
            {valueWithSpace}
          </Tooltip>
        </div>
      );
    },
  },
  {
    title: 'Casual Rate',
    dataIndex: ['data', 'casualRate'],
    key: 'casualRate',
    width: columnWidths.casualRate,
    showPrevData: true,
  },
  {
    title: 'Client Rate',
    dataIndex: ['data', 'clientRate'],
    key: 'clientRate',
    width: columnWidths.clientRate,
    ellipsis: false,
    showPrevData: true,
  },
  {
    title: 'Contractual Bonus',
    dataIndex: ['data', 'contractualBonus'],
    key: 'contractualBonus',
    width: columnWidths.contractualBonus,
    showPrevData: true,
  },
  {
    title: 'Discount',
    dataIndex: ['data', 'discount'],
    key: 'discount',
    width: columnWidths.discount,
    showPrevData: true,
  },
  {
    title: 'Average Audience 18+',
    dataIndex: ['data', 'averageAudience'],
    key: 'averageAudience',
    width: columnWidths.averageAudience,
    ellipsis: false,
    showPrevData: true,
  },
  {
    title: 'Cost Per Thousand',
    dataIndex: ['data', 'costPerThousand'],
    key: 'costPerThousand',
    width: columnWidths.costPerThousand,
    showPrevData: true,
  },
  {
    title: 'Comments',
    dataIndex: ['data', 'comment'],
    ellipsis: true,
    width: columnWidths.comment,
    key: 'comment',
  },
  {
    title: 'Year on Year Variance',
    fixed: 'right',
    children: [
      {
        title: 'CPM Variance',
        dataIndex: ['data', 'costPerThousandYOYVariance'],
        key: 'costPerThousandYOYVariance',
        width: columnWidths.yoyVariance,
      },
      {
        title: 'Rate Variance',
        dataIndex: ['data', 'rateYOYVariance'],
        key: 'rateYOYVariance',
        width: columnWidths.yoyVariance,
      },
    ],
  },
];
