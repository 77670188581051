import { Space } from 'antd';
import React from 'react';

import { contractPlacementOptionsHeight } from '../../constants';
import WeightingActions from '../Weighting/WeightingActions';
import PlacementActions from './PlacementActions';
import PlacementFilters from './PlacementFilters';

const PlacementsOptions = () => {
  return (
    <Space
      style={{
        padding: '8px 10px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between', // Adjust the content spacing
        alignItems: 'center', // Align items vertically centered
        height: contractPlacementOptionsHeight,
      }}
    >
      <div style={{ flex: 1 }}>
        <PlacementFilters />
      </div>
      <Space>
        <PlacementActions />
        <WeightingActions />
      </Space>
    </Space>
  );
};

export default PlacementsOptions;
