// eslint-disable-next-line import/no-extraneous-dependencies
import { Dashboard } from '@mbdt/shared/datacy';
import { Tag } from 'antd';
import Text from 'antd/lib/typography/Text';
import React from 'react';

import { appEnvironment, appVersion } from '../../config';

const Logo = () => {
  return (
    <div
      style={{ display: 'flex', alignItems: 'center' }}
      data-cy={Dashboard.DashboardHome}
    >
      <Text style={{ color: 'white', fontSize: 16, fontWeight: 600 }}>
        Gravity
      </Text>
      {appEnvironment && (
        <Tag color="#2db7f5" style={{ marginLeft: 8 }}>
          {appEnvironment} {appVersion && `v${appVersion}`}
        </Tag>
      )}
    </div>
  );
};

export default Logo;
