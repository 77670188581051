// eslint-disable-next-line import/no-extraneous-dependencies
import { Statistic, Tooltip } from 'antd';
import Text from 'antd/lib/typography/Text';
import PropTypes from 'prop-types';
import React from 'react';

const PreviousYearData = React.memo(
  ({
    value,
    fontSize = 12,
    yoyVariance = '',
    prefix,
    suffix,
    tooltipPlacement = 'right',
    showYoYVariance = false,
    showParentheses = false,
    precision = 2,
  }) => {
    return (
      <div style={{ marginTop: 1, display: 'flex', alignItems: 'center' }}>
        {showParentheses && <Text style={{ marginRight: 2 }}>(</Text>}
        <Tooltip
          title="Previous year"
          placement={tooltipPlacement}
          mouseEnterDelay={0.4}
          overlayStyle={{ fontSize: 11 }}
          style={{ display: 'flex' }}
        >
          <Text
            type="secondary"
            style={{ display: 'flex', alignItems: 'center', fontSize }}
          >
            vs.
            <Statistic
              value={value !== null ? value : '-'}
              valueStyle={{ fontSize, opacity: 0.5, marginLeft: 4 }}
              prefix={value && prefix ? prefix : undefined}
              suffix={value && suffix ? suffix : undefined}
              thousandSeparator=","
              precision={precision}
            />
          </Text>
        </Tooltip>
        {showYoYVariance ? (
          <Text
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: 8,
            }}
          >
            {' '}
            (
            <Statistic
              value={yoyVariance || 0}
              valueStyle={{ fontSize }}
              suffix="% YoY"
              thousandSeparator=","
              precision={precision}
            />
            )
          </Text>
        ) : null}
        {showParentheses && <Text style={{ marginLeft: 2 }}>)</Text>}
      </div>
    );
  }
);

PreviousYearData.displayName = 'PreviousYearData';

PreviousYearData.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontSize: PropTypes.number,
  yoyVariance: PropTypes.number,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  tooltipPlacement: PropTypes.string,
  showYoYVariance: PropTypes.bool,
  showParentheses: PropTypes.bool,
  precision: PropTypes.number,
};

export default PreviousYearData;
