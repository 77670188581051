/* eslint-disable symbol-description */
import axios from 'axios';
import { v4 as uuid } from 'uuid';

import { apiURL, correlationHeaderName } from '../config';

const singleton = Symbol();
const singletonEnforcer = Symbol();

const generateCorrelationId = () => uuid();

class StreamApiService {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) {
      throw new Error('Cannot construct singleton');
    }

    this.session = axios.create({
      baseURL: apiURL,
      responseType: 'arraybuffer',
      headers: {
        [correlationHeaderName]: generateCorrelationId(),
        Pragma: 'no-cache',
        Accept: 'application/pdf',
      },
      params: {},
    });
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new StreamApiService(singletonEnforcer);
    }

    return this[singleton];
  }

  get(...params) {
    return this.session.get(...params);
  }
}

export default StreamApiService.instance;
