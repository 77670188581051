// eslint-disable-next-line import/no-extraneous-dependencies
import { Contracts } from '@mbdt/shared/datacy';
import { Button, Form, Input, InputNumber, Select } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import Text from 'antd/lib/typography/Text';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  contractSectionTypes,
  dropdownOptions,
  mediumStatuses,
  stateOptions,
} from '../../constants';
import { ContractUIContext } from '../../containers/Contract/ContractUIContext';
import { extractContractPlacementValues } from '../../helpers';
import { updatePlacement } from '../../store/actions/contract.actions';

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const EditPlacementNewspaper = ({ isOpen, setIsOpen, placement }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id, mediaType } = useParams();
  const {
    isUpdatingPlacement,
    data: { sections },
  } = useSelector((state) => state.contract);
  const disabled =
    isUpdatingPlacement || placement?.data?.status === mediumStatuses.approved;
  const { activeTab: sectionType } = useContext(ContractUIContext);

  const weightingSections = sections.filter(
    (s) => s.type === contractSectionTypes.weighting
  );

  const handleOk = () => {
    form.resetFields();
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        const placementName = form.getFieldValue(['placementName']);
        const weightingId = form.getFieldValue(['weighting']);

        const valuesObj = Object.entries(values).reduce((acc, [key, value]) => {
          // Remove weighting from object
          if (key === 'weighting') return acc;

          // Convert distributionDays from array to comma-separated string
          if (key === 'distributionDays') {
            return {
              ...acc,
              [key]: {
                currentPhaseValue: value?.join(', '),
              },
            };
          }

          if (key === 'state') {
            return {
              ...acc,
              [key]: {
                currentPhaseValue: value?.join(', '),
              },
            };
          }

          return {
            ...acc,
            [key]: {
              currentPhaseValue: value,
            },
          };
        }, {});

        await dispatch(
          updatePlacement(
            id,
            mediaType,
            placement.id,
            { ...valuesObj, placementName },
            sectionType,
            weightingId,
            handleOk
          )
        );
      })
      .catch((info) => {
        // eslint-disable-next-line no-console
        console.log('Validation Failed:', info);
      });
  };

  // Configure the properties we need to extract from the contract placement and load into the form as

  const initialValues = extractContractPlacementValues(placement, [
    { key: 'placementName', path: 'placementName' },
    {
      key: 'state',
      path: 'state.currentPhaseValue',
      transform: (value) => value?.split(','),
    },
    { key: 'market', path: 'market.currentPhaseValue' },
    {
      key: 'distributionDays',
      path: 'distributionDays.currentPhaseValue',
      transform: (value) => value?.split(','),
    },
    { key: 'sectionPositioning', path: 'sectionPositioning.currentPhaseValue' },
    { key: 'calculationSize', path: 'calculationSize.currentPhaseValue' },
    { key: 'frequency', path: 'frequency.currentPhaseValue' },
    { key: 'distributionMethod', path: 'distributionMethod.currentPhaseValue' },
    { key: 'height', path: 'height.currentPhaseValue' },
    { key: 'width', path: 'width.currentPhaseValue' },
    { key: 'weighting', path: 'weighting.id' },
  ]);

  return (
    <>
      <Modal
        visible={isOpen}
        title="Edit placement"
        onCancel={handleCancel}
        destroyOnClose
        forceRender
        maskClosable={false}
        footer={[
          <Button
            key="back"
            onClick={handleCancel}
            data-cy={`${Contracts.EditPlacementModal}-${Contracts.PlacementModalCancel}`}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            disabled={disabled}
            loading={isUpdatingPlacement}
            onClick={handleSubmit}
            data-cy={`${Contracts.EditPlacementModal}-${Contracts.PlacementModalConfirm}`}
          >
            Update placement
          </Button>,
        ]}
        data-cy={Contracts.EditPlacementModal}
      >
        <Form
          form={form}
          layout="horizontal"
          name="update-newspaper-placement"
          initialValues={initialValues}
          {...formItemLayout}
        >
          <Form.Item
            name="placementName"
            label="Medium"
            rules={[
              {
                required: true,
                message: 'Please enter a Medium',
              },
            ]}
          >
            <Input
              placeholder="Enter a Medium"
              style={{ width: '100%' }}
              disabled={disabled}
              data-cy={Contracts.FieldMedium}
            />
          </Form.Item>

          <Form.Item
            name="state"
            label="State"
            rules={[
              {
                required: true,
                message: 'Please select one or more state',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select one or more state"
              mode="multiple"
              allowClear
              disabled={disabled}
              data-cy={Contracts.FieldState}
            >
              {stateOptions?.map((stateName, index) => (
                <Option
                  key={stateName}
                  value={stateName}
                  data-cy={`${Contracts.FieldState}-${Contracts.SelectItem}${index}`}
                >
                  {stateName}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="market"
            label="Market"
            rules={[
              {
                required: true,
                message: 'Please enter market',
              },
            ]}
          >
            <Input
              placeholder="Enter market"
              style={{ width: '100%' }}
              disabled={disabled}
              data-cy={Contracts.FieldMarket}
            />
          </Form.Item>

          <Form.Item
            name="distributionDays"
            label="Distribution Days"
            rules={[
              {
                required: true,
                message: 'Please select the distribution days',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select the distribution days"
              mode="multiple"
              allowClear
              disabled={disabled}
              data-cy={Contracts.FieldDistributionDays}
            >
              {Object.entries(dropdownOptions.distributionDays).map(
                ([key, value], index) => (
                  <Option
                    key={key}
                    value={key.charAt(0).toUpperCase() + key.slice(1)}
                    data-cy={`${Contracts.FieldDistributionDays}-${Contracts.SelectItem}${index}`}
                  >
                    {value}
                  </Option>
                )
              )}
            </Select>
          </Form.Item>

          <Form.Item
            name="sectionPositioning"
            label="Section / Positioning"
            rules={[
              {
                required: true,
                message: 'Please select a section/positioning',
              },
            ]}
          >
            <Input
              placeholder="Please enter the section/positioning"
              style={{ width: '100%' }}
              disabled={disabled}
              data-cy={Contracts.FieldSectionPositioning}
            />
          </Form.Item>

          <Form.Item
            name="calculationSize"
            label="Calculation Size"
            rules={[
              {
                required: true,
                message: 'Please select a calculation size',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a calculation size"
              disabled={disabled}
              data-cy={Contracts.FieldCalculationSize}
            >
              {Object.entries(dropdownOptions.calculationSize).map(
                ([key, value], index) => (
                  <Option
                    key={key}
                    value={value}
                    data-cy={`${Contracts.FieldCalculationSize}-${Contracts.SelectItem}${index}`}
                  >
                    {value}
                  </Option>
                )
              )}
            </Select>
          </Form.Item>

          <Form.Item
            name="frequency"
            label="Frequency"
            rules={[
              {
                required: true,
                message: 'Please select a frequency',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a frequency"
              disabled={disabled}
              data-cy={Contracts.FieldFrequency}
            >
              {Object.entries(dropdownOptions.frequency).map(
                ([key, value], index) => (
                  <Option
                    key={key}
                    value={value}
                    data-cy={`${Contracts.FieldFrequency}-${Contracts.SelectItem}${index}`}
                  >
                    {value}
                  </Option>
                )
              )}
            </Select>
          </Form.Item>

          <Form.Item
            name="distributionMethod"
            label="Distribution Method"
            rules={[
              {
                required: true,
                message: 'Please select a distribution method',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a distribution method"
              disabled={disabled}
              data-cy={Contracts.FieldDistributionMethod}
            >
              {Object.entries(dropdownOptions.distributionMethod).map(
                ([key, value], index) => (
                  <Option
                    key={key}
                    value={value}
                    data-cy={`${Contracts.FieldDistributionMethod}-${Contracts.SelectItem}${index}`}
                  >
                    {value}
                  </Option>
                )
              )}
            </Select>
          </Form.Item>

          <Form.Item
            name="height"
            label="Height"
            rules={[
              {
                required: true,
                message: 'Please enter the height',
              },
            ]}
          >
            <InputNumber
              placeholder="Please enter the height"
              style={{ width: '100%' }}
              stringMode
              disabled={disabled}
              data-cy={Contracts.FieldHeight}
            />
          </Form.Item>

          <Form.Item
            name="width"
            label="Width"
            rules={[
              {
                required: true,
                message: 'Please enter the width',
              },
            ]}
          >
            <InputNumber
              placeholder="Please enter the width"
              style={{ width: '100%' }}
              stringMode
              disabled={disabled}
              data-cy={Contracts.FieldWidth}
            />
          </Form.Item>
          <Form.Item
            name="weighting"
            label="Classification"
            rules={[
              {
                required: true,
                message: 'Please select a classification',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a classification"
              disabled={disabled}
              data-cy={Contracts.FieldWeighting}
            >
              {weightingSections?.map(
                (
                  {
                    id: weightId,
                    data: {
                      name: { currentPhaseValue: weightName },
                      weight: { currentPhaseValue: weight },
                    },
                  },
                  index
                ) => (
                  <Option
                    key={weightId}
                    value={weightId}
                    data-cy={`${Contracts.FieldWeighting}-${Contracts.SelectItem}${index}`}
                  >
                    <Text>{weightName}</Text>
                    <Text type="secondary" style={{ marginLeft: 4 }}>
                      ({weight}%)
                    </Text>
                  </Option>
                )
              )}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

EditPlacementNewspaper.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  placement: PropTypes.object.isRequired,
};

export default EditPlacementNewspaper;
