import { Menu, Space, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import { actionsMenuButtons } from '../../constants';

const { Text } = Typography;

const ActionsMenuItem = ({ onClick, type, dataCy }) => {
  return (
    <Menu.Item
      onClick={onClick}
      style={{
        padding: '16px',
        borderBottom: '1px solid #eaeaea',
        '&:lastChild': { border: 'none' },
      }}
      data-cy={dataCy}
    >
      <Space direction="vertical" size={4}>
        <Text strong>{actionsMenuButtons[type].heading}</Text>
        <Text
          type="secondary"
          style={{
            display: 'flex',
            maxWidth: 280,
            whiteSpace: 'pre-wrap',
          }}
        >
          {actionsMenuButtons[type].description}
        </Text>
      </Space>
    </Menu.Item>
  );
};

ActionsMenuItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  dataCy: PropTypes.string,
};

export default ActionsMenuItem;
