import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ContractFormProvider } from '../../context/contractForm-context';
import { fetchContract } from '../../store/actions/contract.actions';
import ContractContent from './ContractContent';
import { ContractUIContext, useContractUIContext } from './ContractUIContext';

const Contract = () => {
  const { sectionTypes, isLoading } = useSelector((state) => state.contract);
  const dispatch = useDispatch();
  const { id, mediaType } = useParams();
  const contractUIContext = useContractUIContext();

  const [contractLoading, setContractLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchContract(id, mediaType));
  }, [id, mediaType]);

  useEffect(() => {
    if (isLoading) {
      setContractLoading(true);
      return;
    }

    const contractHasFinishedLoading = sectionTypes && contractLoading;
    if (contractHasFinishedLoading) {
      contractUIContext.setActiveTab(sectionTypes[0]?.type);
      setContractLoading(false);
    }
  }, [isLoading]);

  return (
    <ContractUIContext.Provider value={contractUIContext}>
      <ContractFormProvider>
        <ContractContent />
      </ContractFormProvider>
    </ContractUIContext.Provider>
  );
};

export default Contract;
