import { ClockCircleOutlined } from '@ant-design/icons';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Contracts } from '@mbdt/shared/datacy';
import Text from 'antd/lib/typography/Text';
import { formatDistance } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

const UpdatedTime = ({ updatedAt }) => (
  <Text
    type="secondary"
    style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}
    data-cy={Contracts.UpdatedTime}
  >
    <ClockCircleOutlined style={{ fontSize: 14, marginRight: 6 }} />
    Contract updated{' '}
    {formatDistance(new Date(updatedAt), new Date(), {
      addSuffix: true,
    })}
  </Text>
);

UpdatedTime.propTypes = {
  updatedAt: PropTypes.string.isRequired,
};

export default UpdatedTime;
