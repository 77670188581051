import './Logout.less';

import { Button, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Title from 'antd/lib/typography/Title';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { logoutUser } from '../../store/actions/user.actions';

const Logout = () => {
  const logoutRedirectURL = useSelector(
    (state) => state.user.logoutRedirectURL
  );
  const dispatch = useDispatch();

  const onLogout = (e) => {
    e.preventDefault();
    dispatch(logoutUser());
  };

  useEffect(() => {
    if (logoutRedirectURL) {
      window.location.href = logoutRedirectURL;
    }
  }, [logoutRedirectURL]);

  return (
    <div className="logout">
      <Content
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {logoutRedirectURL ? (
          <Typography className="content">Please wait...</Typography>
        ) : (
          // Display a logout button in case user decides to visit /logout directly
          <>
            <Title level={3}>Are you sure you want to log out?</Title>
            <Typography className="content">
              To continue to log out, please click on the &ldquo;Log out&ldquo;
              button.
            </Typography>
            <Button
              type="primary"
              size="large"
              onClick={onLogout}
              style={{ marginTop: '1rem' }}
            >
              Logout
            </Button>
          </>
        )}
      </Content>
    </div>
  );
};

export default Logout;
