import React from 'react';
import { toast } from 'react-toastify';

import { ToastError } from '../../components/Common/ToastMessages';
import { login, logout } from '../../services/user.service';
import { resetContractState } from '../slices/contract.slice';
import { resetContractsState } from '../slices/contracts.slice';

const {
  fetchUserFailure,
  fetchUserStart,
  setUserSuccess,
  logoutSuccess,
} = require('../slices/user.slice');

export const fetchUser = () => async (dispatch) => {
  try {
    dispatch(fetchUserStart());

    const { data: { redirect } = {} } = await login();

    window.location = redirect;
  } catch (error) {
    dispatch(fetchUserFailure(error));
    toast(
      <ToastError message="Looks like there was an issue logging in. Please try again or contact support." />,
      { autoClose: 7000 }
    );
  }
};

export const setUser = (user) => async (dispatch) => {
  try {
    user.tokenCreated = new Date().getTime();
    dispatch(setUserSuccess(user));
  } catch (error) {
    toast(
      <ToastError message="Looks like there was an issue logging in. Please try again or contact support." />,
      { autoClose: 7000 }
    );
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    const { data: { redirect } = {} } = await logout();

    if (redirect) {
      dispatch(logoutSuccess(redirect));
      dispatch(resetContractState());
      dispatch(resetContractsState());
    }
  } catch (error) {
    toast(
      <ToastError message="Looks like there was an issue logging out. Please try again or contact support." />,
      { autoClose: 7000 }
    );
  }
};
