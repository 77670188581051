// eslint-disable-next-line import/no-extraneous-dependencies
import { Contracts } from '@mbdt/shared/datacy';
import { Empty, Tabs } from 'antd';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import TabPaneContent from '../../components/Common/TabPaneContent';
import UpdatedTime from '../../components/ContractHeader/UpdatedTime';
import ContractTableWrapper from '../../components/ContractTable/ContractTableWrapper';
import ContractualBonusTable from '../../components/ContractualBonusTable/ContractualBonusTable';
import MultipliersTableWrapper from '../../components/MultipliersTable/MultipliersTableWrapper';
import PlacementsOptions from '../../components/PlacementsOptions/PlacementsOptions';
import {
  SavePrompt,
  useHasChangesToSave,
} from '../../components/SavePrompt/SavePrompt';
import WeightingOptions from '../../components/Weighting/WeightingOptions';
import WeightingTableWrapper from '../../components/WeightingTable/WeightingTableWrapper';
import { contractSectionTypes, topNavigationHeight } from '../../constants';
import { useContractForm } from '../../context/contractForm-context';
import { ContractUIContext } from './ContractUIContext';

const { TabPane } = Tabs;

const ContractTabs = () => {
  const { data, sectionTypes } = useSelector((state) => state.contract);
  const { mediaType } = useParams();
  const { activeTab, setActiveTab, contractHeaderHeight } =
    useContext(ContractUIContext);
  const [displaySavePrompt, setDisplaySavePrompt] = useState(false);
  const hasChanges = useHasChangesToSave();
  const formContext = useContractForm();

  const tabBarExtraContent = data?.updatedAt && (
    <UpdatedTime updatedAt={data.updatedAt} />
  );

  const formErrors = formContext?.state?.errors;
  const tableStyles = { height: '100%', paddingBottom: '50px' };

  const sectionTable = (sectionType) => {
    switch (sectionType.type) {
      case contractSectionTypes.weighting:
        return (
          <TabPane
            tab={
              <TabPaneContent
                name={sectionType.label}
                sectionId={sectionType.type}
                datacy={Contracts.TabClassification}
              />
            }
            key={sectionType.type}
            forceRender
            style={tableStyles}
          >
            <WeightingOptions />
            <WeightingTableWrapper
              sectionId={sectionType.type}
              sectionType={sectionType.type}
            />
          </TabPane>
        );
      case contractSectionTypes.multipliers:
        return (
          <TabPane
            tab={
              <TabPaneContent
                name={sectionType.label}
                sectionId={sectionType.type}
                datacy={Contracts.TabMultipliers}
              />
            }
            key={sectionType.type}
            forceRender
            style={tableStyles}
          >
            <MultipliersTableWrapper
              mediaType={mediaType}
              sectionId={sectionType.type}
              sectionType={sectionType.type}
            />
          </TabPane>
        );
      case contractSectionTypes.contractualBonus:
        return (
          <TabPane
            tab={
              <>
                <TabPaneContent
                  name={sectionType.label}
                  sectionId={sectionType.type}
                  datacy={Contracts.TabContractualBonus}
                  error={
                    formErrors?.contractual_bonus?.values
                      ? 'Please complete the required fields to proceed'
                      : undefined
                  }
                />
              </>
            }
            key={sectionType.type}
            forceRender
            style={tableStyles}
          >
            <ContractualBonusTable
              mediaType={mediaType}
              sectionId={sectionType.type}
              sectionType={sectionType.type}
            />
          </TabPane>
        );
      default:
        return (
          <TabPane
            tab={
              <TabPaneContent
                name={sectionType.label}
                sectionId={sectionType.type}
                datacy={Contracts.TabPlacements}
              />
            }
            key={sectionType.type}
            forceRender
            style={tableStyles}
          >
            <PlacementsOptions />
            <ContractTableWrapper
              sectionId={sectionType.type}
              sectionType={sectionType.type}
            />
          </TabPane>
        );
    }
  };

  const ChangeTab = (tab) => {
    if (tab === activeTab) return;

    if (!hasChanges) {
      setActiveTab(tab);
    }
    setDisplaySavePrompt(hasChanges);
  };

  return (
    <>
      {displaySavePrompt && hasChanges ? (
        <SavePrompt
          show={displaySavePrompt}
          onSavePromptClose={() => {
            setDisplaySavePrompt(false);
          }}
        />
      ) : null}
      <Tabs
        tabBarExtraContent={tabBarExtraContent}
        tabBarStyle={{ padding: '0 24px', marginBottom: 0 }}
        style={{
          height: `calc(100vh - ${
            topNavigationHeight + contractHeaderHeight
          }px)`,
        }}
        activeKey={activeTab}
        onChange={ChangeTab}
        onchan
      >
        {sectionTypes ? (
          sectionTypes.map(sectionTable)
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No data to display"
            />
          </div>
        )}
      </Tabs>
    </>
  );
};

export default ContractTabs;
