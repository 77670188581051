import axios from 'axios';

import api from './api';
/**
 * NOTE: We are intentionally ignoring pageLimit and pageNumber temporarily. Time does not
 * permit to implemet filtering and search in the API so we are pulling all records from the
 * API and performing those operations in the UI.
 */
const { CancelToken } = axios;
let cancel;

export const fetchContracts = async (
  mediaType,
  // eslint-disable-next-line no-unused-vars
  pageLimit = 100,
  // eslint-disable-next-line no-unused-vars
  pageNumber = 0,
  orderBy = 'name:desc'
) => {
  if (cancel) {
    // cancel the request
    cancel();
  }
  const contracts = await api.get(`${mediaType}?orderBy=${orderBy}`, {
    cancelToken: new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      cancel = c;
    }),
  });
  cancel = null;

  return contracts;
};
