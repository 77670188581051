import './SavePrompt.less';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Prompt } from 'react-router-dom';

import { ContractUIContext } from '../../containers/Contract/ContractUIContext';
import {
  publishDiscardEvent,
  publishSaveEvent,
} from '../../containers/Contract/ContractUIEvents';
import { discardContractChanges } from '../../store/slices/contract.slice';

const RouterSavePrompt = () => {
  const [modalVisible, setModalVisible] = useState(false);

  const dispatch = useDispatch();

  const {
    dataHasChanged: hasChanged,
    hasValidationErrors: validationErrors,
    setDataHasChanged,
    setHasValidationErrors,
  } = useContext(ContractUIContext);

  const showModal = () => {
    setModalVisible(true);
  };

  const closeModal = (callback = () => {}) => {
    setModalVisible(false);
    callback();
  };

  const handleBlockedNavigation = () => {
    showModal();
    return false;
  };

  const handleReset = () => {
    dispatch(discardContractChanges());
    setDataHasChanged(false);
    setHasValidationErrors(false);
    publishDiscardEvent();
    closeModal();
  };

  const handleSave = () => {
    publishSaveEvent();
    closeModal();
  };

  if (!modalVisible)
    return <Prompt when={hasChanged} message={handleBlockedNavigation} />;

  return (
    <>
      <Prompt when={hasChanged} message={handleBlockedNavigation} />
      <Modal
        title={
          <>
            <ExclamationCircleOutlined /> Warning!
          </>
        }
        className="save-confirmation"
        destroyOnClose
        forceRender
        maskClosable={false}
        visible
        onCancel={() => closeModal()}
        footer={[
          <Button
            key="back"
            className="save-confirmation-cancel"
            onClick={() => closeModal()}
          >
            Cancel
          </Button>,
          <Button
            key="discard"
            type="button"
            danger
            loading={false}
            onClick={handleReset}
          >
            Discard
          </Button>,
          <Button
            key="submit"
            disabled={validationErrors}
            type="primary"
            loading={false}
            onClick={handleSave}
          >
            Save
          </Button>,
        ]}
      >
        <p>
          {validationErrors
            ? 'Please review the rates to ensure they have all been entered in correctly.'
            : 'Please save your changes before proceeding.'}
        </p>
      </Modal>
    </>
  );
};

export default RouterSavePrompt;
