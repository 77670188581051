/* eslint-disable react/forbid-prop-types */
// eslint-disable-next-line import/no-extraneous-dependencies
import { Contracts } from '@mbdt/shared/datacy';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const CustomModal = ({
  children,
  isOpen,
  handleCloseModal,
  title,
  width = 520,
  bodyStyle,
  footer,
}) => {
  const handleOk = () => {
    handleCloseModal(false);
  };

  const handleCancel = () => {
    handleCloseModal(false);
  };

  return (
    <Modal
      title={title}
      visible={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      width={width}
      bodyStyle={bodyStyle}
      footer={footer}
      data-cy={Contracts.ModelOverview}
    >
      {children}
    </Modal>
  );
};

CustomModal.propTypes = {
  children: PropTypes.any,
  isOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  title: PropTypes.string,
  width: PropTypes.number,
  bodyStyle: PropTypes.object,
  footer: PropTypes.func,
};

export default CustomModal;
