/* eslint-disable react/prop-types */
import './MultipliersTable.less';

// eslint-disable-next-line import/no-extraneous-dependencies
import { Contracts } from '@mbdt/shared/datacy';
import { createSelector } from '@reduxjs/toolkit';
import { Form } from 'antd';
import _ from 'lodash';
import React, { useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { placementEditTypes } from '../../constants';
import { ContractUIContext } from '../../containers/Contract/ContractUIContext';
import { useDiscardChangesNotification } from '../../containers/Contract/ContractUIEvents';
import { useContractForm } from '../../context/contractForm-context';
import { contractTableColumns } from '../../data';
import { renderDisplayTypePosition } from '../../helpers';
import { selectContractSections } from '../../store/selectors';
import { updateSectionDataSuccess } from '../../store/slices/contract.slice';
import TemplateTable from '../TemplateTable/TemplateTable';

const makeSelectTableDataBySectionType = () =>
  createSelector(
    [selectContractSections, (_state, sectionType) => sectionType],
    (sections, sectionType) =>
      sections
        .filter(({ type }) => type === sectionType)
        .map((data, idx) => {
          const { data: placementData } = data;
          const formattedFields = {};
          _.forEach(placementData, (value, fieldName) => {
            if (value.editTypes) {
              formattedFields[fieldName] = {
                ...value,
                // set editable value on table
                editable: _.includes(
                  value.editTypes,
                  placementEditTypes.EDIT_PLACEMENT_INLINE
                ),
              };
            } else {
              formattedFields[fieldName] = value;
            }
          });

          // update data with formatted fields
          data = {
            ...data,
            data: formattedFields,
          };
          return { key: data?.id || idx, ...data };
        })
  );

const MultipliersTableWrapper = ({ sectionId, sectionType, mediaType }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const formContext = useContractForm();
  const { contractTableHeaderHeight, tableHeight } =
    useContext(ContractUIContext);

  const selectTableDataBySectionType = useMemo(
    makeSelectTableDataBySectionType,
    []
  );

  // Prevent this from trigger re-renders in the ant design table
  const tableData = useSelector(
    (state) => selectTableDataBySectionType(state, sectionType),
    (state, prevState) => _.isEqual(state, prevState)
  );

  const { canEditPlacement } = useSelector(
    (state) => state.contract.visualCues
  );

  const handleSave = async (row) => {
    await dispatch(updateSectionDataSuccess(row));
  };

  const mapColumns = (col) => {
    const newCol = {
      ...col,
      onHeaderCell: (column) => {
        const columnKey = column.key ?? col?.children?.[0]?.key;
        return {
          'data-cy': `${Contracts.TableHeaderMultiplier}${sectionId}-${columnKey}`, // Ensure the attribute is set for header cells
        };
      },
      onCell: (record, index) => ({
        record: {
          ...record,
          dataCy: `${Contracts.TableMultiplierEdit}${index}-${col.key}`,
        },
        displayType: record?.data[col.key]?.displayType,
        displayTypePos: renderDisplayTypePosition(
          record?.data[col.key]?.displayType
        ),
        isCalculatedCell: !!record?.data[col.key]?.function,
        dataIndex: col.dataIndex,
        title: col.title,
        editable: record?.data[col.key]?.editable,
        handleSave,
        isEditing: !!(record?.data[col.key]?.editable && canEditPlacement),
        fieldName: col.key,
        sectionId,
        showPrevData: col.showPrevData,
        'data-cy': `${Contracts.TableCellMultiplier}${sectionId}-${index}-${col.key}`,
      }),
    };

    if (col.children) {
      newCol.children = col.children.map(mapColumns);
    }

    return newCol;
  };

  const columns = useMemo(
    () => contractTableColumns[`${mediaType}_${sectionType}`]?.map(mapColumns),
    [canEditPlacement]
  );

  const ResetFields = () => {
    form.resetFields();
  };

  useEffect(() => {
    formContext.dispatch({
      type: 'addForm',
      payload: {
        [sectionId]: form,
      },
    });
  }, []);

  useDiscardChangesNotification(ResetFields);

  return (
    <Form form={form} component={false}>
      <TemplateTable
        tableData={tableData}
        columns={columns}
        tableHeight={tableHeight}
        tableHeaderHeight={contractTableHeaderHeight}
      />
    </Form>
  );
};

export default MultipliersTableWrapper;
