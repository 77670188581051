/* eslint-disable react/forbid-prop-types */
import { Table } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import { bonus, mediaTypes } from '../../constants';

const OverviewTable = ({ columns, dataSource = {}, mediaType }) => {
  const { id, ...data } = dataSource;
  const tableData = [];
  // Function to determine if a field should be hidden based on media type
  const shouldHideField = (key) =>
    mediaType === mediaTypes.television && key === bonus.contractualBonus;
  // eslint-disable-next-line no-unused-vars
  Object.entries(data).forEach(([key, value]) => {
    if (value.function === null && !shouldHideField(key)) {
      tableData.push(value);
    }
  });

  const modifiedColumns = columns.map((col) => ({
    ...col,
    render: (text, record, index) => (
      <div data-cy={`table-cell-${col.dataIndex}-${index}`}>
        {col.render ? col.render(text, record, index) : text}
      </div>
    ),
  }));

  return (
    <Table
      columns={modifiedColumns}
      dataSource={tableData}
      pagination={false}
      style={{ fontSize: 13 }}
      size="large"
    />
  );
};

OverviewTable.propTypes = {
  mediaType: PropTypes.string,
  columns: PropTypes.array.isRequired,
  dataSource: PropTypes.object.isRequired,
};

export default OverviewTable;
