import { useEffect } from 'react';

const EVENT_DISCARD = 'resetContractChanges';
const EVENT_SAVE = 'saveContractChanges';
const EVENT_SAVE_PROMPT = 'savePromptContractChanges';

const useDiscardChangesNotification = (handler) => {
  useEffect(() => {
    document.addEventListener(EVENT_DISCARD, handler);
    return () => {
      document.removeEventListener(EVENT_DISCARD, handler);
    };
  }, []);
};

const publishDiscardEvent = () => {
  document.dispatchEvent(new CustomEvent(EVENT_DISCARD));
};

const useSaveChangesNotification = (handler) => {
  useEffect(() => {
    document.addEventListener(EVENT_SAVE, handler);
    return () => {
      document.removeEventListener(EVENT_SAVE, handler);
    };
  }, []);
};

const publishSaveEvent = () => {
  document.dispatchEvent(new CustomEvent(EVENT_SAVE));
};

const useCheckForChangesNotification = (handler) => {
  useEffect(() => {
    document.addEventListener(EVENT_SAVE_PROMPT, handler);
    return () => {
      document.removeEventListener(EVENT_SAVE_PROMPT, handler);
    };
  }, []);
};

const publishCheckForChangesEvent = () => {
  document.dispatchEvent(new CustomEvent(EVENT_SAVE_PROMPT));
};

export {
  publishCheckForChangesEvent,
  publishDiscardEvent,
  publishSaveEvent,
  useCheckForChangesNotification,
  useDiscardChangesNotification,
  useSaveChangesNotification,
};
