import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, useLocation } from 'react-router-dom';

import * as config from '../../config';
import { setRequestedURL } from '../../store/slices/user.slice';

const ProtectedRoute = ({ ...props }) => {
  const { pathname, search } = useLocation();
  const { logoutRedirectURL, token } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  if (!config.enforceAuthentication || token) {
    return <Route {...props} />;
  }

  const url = `${pathname}${search}`;

  // Save the requested route so we can redirect once the user is logged in.
  dispatch(setRequestedURL(url));

  if (logoutRedirectURL) {
    return <Redirect to={config.routes.LOGOUT} />;
  }

  return <Redirect to={config.routes.LOGIN} />;
};

export default ProtectedRoute;
