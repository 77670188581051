import { ExclamationCircleOutlined } from '@ant-design/icons';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Contracts } from '@mbdt/shared/datacy';
import { Button } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';

import { contractSectionTypes, warnings } from '../../constants';
import { ContractUIContext } from '../../containers/Contract/ContractUIContext';
import {
  selectContractSections,
  selectVisualCues,
} from '../../store/selectors';
import AddPlacementModal from '../AddPlacement/AddPlacementModal';
import EditPlacementModal from '../EditPlacement/EditPlacementModal';
import { SavePrompt, useHasChangesToSave } from '../SavePrompt/SavePrompt';
import RetirePlacementModal from './RetirePlacementModal';

const PlacementActions = () => {
  const [isAddPlacementModalVisible, setIsAddPlacementModalVisible] =
    useState(false);
  const { canAddPlacement } = useSelector(selectVisualCues);
  const sectionTypes = useSelector((state) => state.contract.sectionTypes);
  const contractSections = useSelector(selectContractSections);
  const { activeTab } = useContext(ContractUIContext);
  const hasChanges = useHasChangesToSave();

  const sectionType = sectionTypes?.find(
    (section) => section.type === activeTab
  );

  // Show a confirmation modal if the user selects an Action Menu Item
  const showConfirmModal = async () => {
    confirm({
      title: warnings.addPlacementNoWeighting.title,
      icon: <ExclamationCircleOutlined />,
      content: warnings.addPlacementNoWeighting.description,
      okText: warnings.addPlacementNoWeighting.submitButtonLabel,
      onOk() {},
      okCancel: false,
      width: 500,
    });
  };

  const BaseAddPlacementButton = (props) => (
    <Button
      size="middle"
      style={{
        padding: 8,
        display: 'flex',
        alignItems: 'center',
        fontSize: 12,
        fontWeight: 500,
      }}
      {...props}
      data-cy={Contracts.PlacementBtnAdd}
    >
      Add new placement
    </Button>
  );

  const OnSavePromptClose = () => {
    setIsAddPlacementModalVisible(false);
  };

  const AddPlacementButton = () => {
    // If the user can add placements and the contract allows adding placements
    if (canAddPlacement && sectionType?.canEditPlacements) {
      const weightingSections = contractSections.filter(
        (s) => s.type === contractSectionTypes.weighting
      );
      const contractHasWeighting =
        sectionTypes.filter((s) => s.type === contractSectionTypes.weighting)
          .length > 0;

      // If the template does have weighting, ensure we have some before allowing placements to be added
      if (contractHasWeighting && weightingSections.length === 0) {
        return <BaseAddPlacementButton onClick={showConfirmModal} />;
      }
      return (
        <BaseAddPlacementButton
          onClick={() => setIsAddPlacementModalVisible(true)}
        />
      );
    }
    return null;
  };

  return (
    <>
      <AddPlacementButton />
      <>
        <SavePrompt
          show={isAddPlacementModalVisible}
          onSavePromptClose={OnSavePromptClose}
        />
        <AddPlacementModal
          isOpen={isAddPlacementModalVisible && !hasChanges}
          setIsOpen={setIsAddPlacementModalVisible}
        />
        {/** Hidden modals triggered by query params */}
        <EditPlacementModal />
        <RetirePlacementModal />
      </>
      {/* )} */}
    </>
  );
};

export default PlacementActions;
