import { Tag } from 'antd';
import { format } from 'date-fns';
import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';

import { routes } from '../config';
import {
  contractStatusColors,
  contractStatuses,
  contractStatusLabels,
  contractStatusLabelText,
  mediaTypeCodes,
} from '../constants';

export const createTemplateColumnsRadio = (data) => {
  return [
    {
      title: 'Master Network',
      dataIndex: ['masterNetwork'],
      key: 'masterNetwork',
      filters: _.uniqBy(
        data.map((item) => {
          return {
            text: item.masterNetwork,
            value: item.masterNetwork,
          };
        }),
        'text'
      ),
      filterSearch: true,
      onFilter: (value, record) =>
        _.get(record, ['masterNetwork'])?.toString()?.toLowerCase() ===
        value.toLowerCase(),
    },
    {
      title: 'Media Sub Type',
      dataIndex: ['mediaSubType'],
      key: 'mediaSubType',
      filters: _.uniqBy(
        data.map((item) => {
          return {
            text: item.mediaSubType,
            value: item.mediaSubType,
          };
        }),
        'text'
      ),
      filterSearch: true,
      onFilter: (value, record) =>
        _.get(record, ['mediaSubType'])?.toString()?.toLowerCase() ===
        value.toLowerCase(),
    },
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
      ellipsis: true,
      width: 80,
    },
    {
      title: 'Date Updated',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => format(new Date(date), 'd MMMM, yyy h:mm aaa'),
      filters: _.uniqBy(
        data.map((item) => {
          return {
            text: new Date(item.createdAt).toLocaleString('en-us', {
              month: 'long',
            }),
            value: new Date(item.createdAt).getMonth(),
          };
        }),
        'text'
      ),
      onFilter: (value, record) =>
        record ? new Date(record.createdAt).getMonth() === value : null,
    },
    {
      title: 'Round',
      dataIndex: 'phase',
      key: 'phase',
      ellipsis: true,
      width: 120,
      render: (round) => (round === 0 ? '-' : round),
      sorter: (a, b) => a.phase - b.phase,
      filters: _.uniqBy(
        data.map((item) => {
          return {
            text: item.phase,
            value: item.phase,
          };
        }),
        'text'
      ),
      onFilter: (value, record) => record?.phase === Number(value),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => {
        if (record.phase === 0 && contractStatuses.review) {
          return (
            <Tag className="status-tag" color="default">
              {contractStatusLabels.draft}
            </Tag>
          );
        }
        return (
          <Tag className="status-tag" color={contractStatusColors[text]}>
            {contractStatusLabelText[text]}
          </Tag>
        );
      },
      filters: _.uniqBy(
        data.map((item) => {
          return {
            text:
              item.phase === 0 && contractStatuses.review === item.status
                ? contractStatusLabels.draft
                : contractStatusLabelText[item.status],
            value: item.status,
          };
        }),
        'text'
      ),
      onFilter: (value, record) => record?.status.includes(value),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      // eslint-disable-next-line no-shadow
      render: (_, record) => (
        <Link
          to={`${routes.CONTRACTS}/${mediaTypeCodes.radio}/${record.id}`}
          onClick={(e) => e.stopPropagation()}
        >
          View contract
        </Link>
      ),
    },
  ];
};
