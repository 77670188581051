import './Contracts.less';

// eslint-disable-next-line import/no-extraneous-dependencies
import { Contracts as ContractDataCy } from '@mbdt/shared/datacy';
import { PageHeader } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import Sider from 'antd/lib/layout/Sider';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import CustomTable from '../../components/CustomTable/CustomTable';
import SiteLayout from '../../components/Layout/Layout';
import MediaTypeMenu from '../../components/MediaTypeMenu/MediaTypeMenu';
import { routes } from '../../config';
import { mediaTypeOrderBy, sortOptions } from '../../constants';
import { createContractsListColumns } from '../../data/index';
import useAddDataCyAttributes from '../../hooks/useAddDataCyAttributes';
import { fetchContractList } from '../../store/actions/contracts.actions';
import * as Selectors from '../../store/selectors';

const Contracts = () => {
  const tableRef = useRef(null);
  const history = useHistory();
  const { mediaType } = useParams();
  const dispatch = useDispatch();
  const { isLoading, data } = useSelector(Selectors.selectContractList);
  const [pageLimit, setPageLimit] = useState(20);
  const [pageNumber] = useState(0);
  const [orderBy] = useState(
    mediaTypeOrderBy[mediaType] || sortOptions.masterNetworkAsc
  );

  useAddDataCyAttributes(tableRef, [
    {
      selector: '.ant-table-pagination',
      dataCy: ContractDataCy.ContractTablePagination,
    },
    {
      selector: '.ant-pagination-options',
      dataCy: ContractDataCy.ContractTablePaginationOptions,
    },
  ]);

  useAddDataCyAttributes(
    { data },
    '.ant-table-filter-dropdown',
    ContractDataCy.ContractFilterDropdown
  );

  const handleRowClick = (record) => {
    return {
      onClick: () =>
        history.push(`${routes.CONTRACTS}/${mediaType}/${record.id}`),
    };
  };

  useEffect(() => {
    dispatch(fetchContractList(mediaType, pageLimit, pageNumber, orderBy));
  }, [pageLimit, pageNumber, mediaType]);

  const addDataCyToCells = () => {
    return createContractsListColumns(mediaType, data).map((col) => ({
      ...col,
      onCell: (record, rowIndex) => ({
        'data-cy': `${ContractDataCy.ContractTableCell}${col?.dataIndex}-${rowIndex}`,
      }),
      onHeaderCell: (header) => ({
        'data-cy': `${ContractDataCy.ContractHeaderColumn}${header?.dataIndex}`,
      }),
    }));
  };

  return (
    <SiteLayout>
      <Sider
        width={200}
        className="site-layout-background"
        style={{ borderRight: '1px solid #eaeaea' }}
      >
        <MediaTypeMenu />
      </Sider>
      <Layout>
        <PageHeader
          className="site-page-header"
          title="Contracts"
          style={{ borderBottom: '1px solid #eaeaea' }}
        />
        <Content
          className="site-layout-background contracts-list"
          style={{
            margin: 0,
            minHeight: 280,
          }}
        >
          <div ref={tableRef}>
            <CustomTable
              key={mediaType}
              data={data}
              columns={addDataCyToCells()}
              onRow={handleRowClick}
              loading={isLoading}
              paginationOptions={{
                pageSize: pageLimit,
                onShowSizeChange: (current, size) => {
                  setPageLimit(size);
                },
              }}
            />
          </div>
        </Content>
      </Layout>
    </SiteLayout>
  );
};

export default Contracts;
