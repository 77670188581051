import { ExclamationCircleOutlined } from '@ant-design/icons';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Contracts } from '@mbdt/shared/datacy';
import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ContractUIContext } from '../../containers/Contract/ContractUIContext';
import {
  publishDiscardEvent,
  publishSaveEvent,
} from '../../containers/Contract/ContractUIEvents';
import { discardContractChanges } from '../../store/slices/contract.slice';

const SavePrompt = ({ show, onSavePromptClose }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const dispatch = useDispatch();

  const {
    dataHasChanged: hasChanged,
    hasValidationErrors: validationErrors,
    setDataHasChanged,
    setHasValidationErrors,
  } = useContext(ContractUIContext);

  useEffect(() => {
    setModalVisible(hasChanged && show);
  }, [hasChanged, show]);

  const closeModal = () => {
    setModalVisible(false);
    onSavePromptClose();
  };

  const handleReset = () => {
    dispatch(discardContractChanges());
    setDataHasChanged(false);
    setHasValidationErrors(false);
    publishDiscardEvent();
    closeModal();
  };

  const handleSave = () => {
    publishSaveEvent();
    closeModal();
  };

  return (
    <Modal
      data-cy={Contracts.Confirmation}
      title={
        <>
          <ExclamationCircleOutlined /> Warning!
        </>
      }
      className="save-confirmation"
      destroyOnClose
      forceRender
      maskClosable={false}
      visible={modalVisible}
      onCancel={() => closeModal()}
      footer={[
        <Button
          key="back"
          className="save-confirmation-cancel"
          onClick={() => closeModal()}
        >
          Cancel
        </Button>,
        <Button
          key="discard"
          type="button"
          danger
          loading={false}
          onClick={handleReset}
        >
          Discard
        </Button>,
        <Button
          key="submit"
          disabled={validationErrors}
          type="primary"
          loading={false}
          onClick={handleSave}
        >
          Save
        </Button>,
      ]}
    >
      <p>
        {validationErrors
          ? 'Please review the rates to ensure they have all been entered in correctly.'
          : 'Please save your changes before proceeding.'}
      </p>
    </Modal>
  );
};

const useHasChangesToSave = () => {
  const { dataHasChanged: hasChanged, hasValidationErrors: validationErrors } =
    useContext(ContractUIContext);

  return hasChanged || validationErrors;
};

SavePrompt.propTypes = {
  show: PropTypes.bool,
  onSavePromptClose: PropTypes.func,
};

export { SavePrompt, useHasChangesToSave };
