import { UserOutlined } from '@ant-design/icons';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Dashboard } from '@mbdt/shared/datacy';
import { Avatar, Dropdown, Menu } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';

import { logoutUser } from '../../store/actions/user.actions';

const AccountDropdown = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  const menu = (
    <Menu style={{ minWidth: 120 }}>
      <Menu.Item
        key="1"
        onClick={handleLogout}
        data-cy={Dashboard.DashboardLogout}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Avatar
        icon={<UserOutlined />}
        style={{
          cursor: 'pointer',
          backgroundColor: 'rgba(255, 255, 255, 0.3)',
        }}
      />
    </Dropdown>
  );
};

export default AccountDropdown;
