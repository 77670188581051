import { Tag } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const ContractStatusTag = ({
  color = 'default',
  icon,
  label,
  dataCy,
  ...style
}) => {
  return (
    <Tag
      color={color}
      icon={icon}
      style={{
        paddingLeft: 16,
        paddingRight: 16,
        height: 32,
        display: 'flex',
        alignItems: 'center',
        fontWeight: 500,
        fontSize: 14,
        marginRight: 0,
        ...style,
      }}
      data-cy={dataCy}
    >
      {label}
    </Tag>
  );
};

ContractStatusTag.propTypes = {
  color: PropTypes.string,
  status: PropTypes.string,
  icon: PropTypes.node,
  label: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
  dataCy: PropTypes.string,
};

export default ContractStatusTag;
