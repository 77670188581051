/* eslint-disable react/prop-types */
import './WeightingTable.less';

// eslint-disable-next-line import/no-extraneous-dependencies
import { Contracts } from '@mbdt/shared/datacy';
import { createSelector } from '@reduxjs/toolkit';
import { Form, Table, Tooltip } from 'antd';
import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { placementEditTypes } from '../../constants';
import { useDiscardChangesNotification } from '../../containers/Contract/ContractUIEvents';
import { useContractForm } from '../../context/contractForm-context';
import { contractTableColumns } from '../../data';
import { renderDisplayTypePosition } from '../../helpers';
import {
  selectContractSections,
  selectSelectedWeightingRows,
  selectVisualCues,
} from '../../store/selectors';
import {
  setSelectedWeightingRows,
  updateSectionDataSuccess,
} from '../../store/slices/contract.slice';
import TemplateTable from '../TemplateTable/TemplateTable';

const makeSelectTableDataBySectionType = () =>
  createSelector(
    [selectContractSections, (_state, sectionType) => sectionType],
    (sections, sectionType) =>
      sections
        .filter(({ type }) => type === sectionType)
        .map((data, idx) => {
          const { data: placementData } = data;
          const formattedFields = {};
          _.forEach(placementData, (value, fieldName) => {
            if (value.editTypes) {
              formattedFields[fieldName] = {
                ...value,
                // set editable value on table
                editable: _.includes(
                  value.editTypes,
                  placementEditTypes.EDIT_PLACEMENT_INLINE
                ),
              };
            } else {
              formattedFields[fieldName] = value;
            }
          });

          // update data with formatted fields
          data = {
            ...data,
            data: formattedFields,
          };
          return { key: data?.id || idx, ...data };
        })
  );

const WeightingTemplateTableWrapper = ({ sectionId, sectionType }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const formContext = useContractForm();
  const { contractTableHeaderHeight, tableHeight } = useSelector(
    (state) => state.contract
  );

  const selectedRowKeys = useSelector(selectSelectedWeightingRows);
  const contractSections = useSelector(selectContractSections);

  const selectTableDataBySectionType = useMemo(
    makeSelectTableDataBySectionType,
    []
  );

  // Prevent this from trigger re-renders in the ant design table
  const tableData = useSelector(
    (state) => selectTableDataBySectionType(state, sectionType),
    (state, prevState) => _.isEqual(state, prevState)
  );

  const { canRemoveWeighting, canEditWeighting } =
    useSelector(selectVisualCues);

  // TODO: This is temporarily hidden until we need to implement row selection in the contract table
  const onSelectChange = (selectedRowKeyIds) => {
    dispatch(setSelectedWeightingRows(selectedRowKeyIds));
  };

  const rowSelection = useMemo(
    () => ({
      fixed: true,
      selectedRowKeys,
      onChange: onSelectChange,
      getCheckboxProps: (record) => {
        return {
          disabled:
            contractSections.filter((s) => s.data?.weighting?.id === record.id)
              .length > 0, // Column configuration not to be checked
          name: record.name,
          'data-cy': `${Contracts.TableCellClassificationCheck}input-${record.id}`,
        };
      },
      renderCell(checked, record, index, originNode) {
        const linkedSections = contractSections.filter(
          (s) => s.data?.weighting?.id === record.id
        ).length;
        if (linkedSections > 0) {
          return (
            <Tooltip
              title={`This weight has ${linkedSections} ${
                linkedSections === 1 ? 'placement' : 'placements'
              } associated and can not be removed.`}
            >
              <div
                data-cy={`${Contracts.TableCellClassificationCheck}${index}`}
              >
                {originNode}
              </div>
            </Tooltip>
          );
        }
        return (
          <div data-cy={`${Contracts.TableCellClassificationCheck}${index}`}>
            {originNode}
          </div>
        );
      },
      columnWidth: '48px',
      selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
    }),
    [selectedRowKeys, contractSections]
  );

  const handleSave = async (row) => {
    await dispatch(updateSectionDataSuccess(row));
  };

  const mapColumns = (col) => {
    const newCol = {
      ...col,
      onHeaderCell: (column) => {
        const columnKey = column.key ?? col?.children?.[0]?.key;
        return {
          'data-cy': `${Contracts.TableHeaderClassification}${sectionId}-${columnKey}`, // Ensure the attribute is set for header cells
        };
      },
      onCell: (record, index) => ({
        record: {
          ...record,
          dataCy: `${Contracts.TableCellClassificationEdit}${index}-${col.key}`,
        },
        displayType: record?.data[col.key]?.displayType,
        displayTypePos: renderDisplayTypePosition(
          record?.data[col.key]?.displayType
        ),
        isCalculatedCell: !!record?.data[col.key]?.function,
        dataIndex: col.dataIndex,
        title: col.title,
        editable: true, // Forcing to true since all weighting fields should be editable
        handleSave,
        isEditing: !!canEditWeighting,
        fieldName: col.key,
        sectionId,
        showPrevData: col.showPrevData,
        'data-cy': `${Contracts.TableCellClassification}${sectionId}-${index}-${col.key}`,
      }),
    };

    if (col.children) {
      newCol.children = col.children.map(mapColumns);
    }

    return newCol;
  };

  const columns = useMemo(
    () => contractTableColumns[sectionType]?.map(mapColumns),
    [canEditWeighting]
  );

  const ResetFields = () => {
    form.resetFields();
  };

  const settingDataCyForSelectAllCheckBox = () => {
    const element = document?.querySelector?.(
      'input[aria-label="Custom selection"]'
    );
    if (element) {
      // Set the 'data-cy' attribute directly on the element
      element?.setAttribute?.('data-cy', 'select-all-checkbox');
    }
  };

  useDiscardChangesNotification(ResetFields);

  useEffect(() => {
    formContext.dispatch({
      type: 'addForm',
      payload: {
        [sectionId]: form,
      },
    });
    settingDataCyForSelectAllCheckBox();
  }, []);

  return (
    <Form form={form} component={false}>
      <TemplateTable
        tableData={tableData}
        columns={columns}
        tableHeight={tableHeight}
        tableHeaderHeight={contractTableHeaderHeight}
        rowSelection={canRemoveWeighting ? rowSelection : null}
      />
    </Form>
  );
};

export default WeightingTemplateTableWrapper;
