export const topNavigationHeight = 64;
export const contractTabBarHeight = 46;
export const calculationDecimalPlaces = 2;
export const contractPlacementOptionsHeight = 48;
export const multiLineDelimiter = '<br/>';
export const MIN_ROWS_FOR_BULK_ACTION = 2;

/**
 * Contract Table
 */

// Table Cell Display Types
export const displayTypes = {
  percentage: 'percentage',
  string: 'string',
  integer: 'integer',
  money: 'money',
  dropdown: 'dropdown',
  decimal: 'decimal',
};

export const fullColourLoadDisplayTypes = {
  Dollar: 'money',
  Percentage: 'percentage',
  'Included in rate': 'string',
  Mono: 'string',
  'Flat Rate': 'string',
  'N/A': 'string',
};

// Dropdown fields
export const dropdownOptions = {
  distributionMethod: {
    free: 'Free',
    paid: 'Paid',
    inserted: 'Inserted',
    freePaid: 'Free, Paid',
    notApplicable: 'N/A',
  },
  frequency: {
    daily: 'Daily',
    weekly: 'Weekly',
    biWeekly: 'Bi-Weekly',
    triWeekly: 'Tri-Weekly',
    fortnightly: 'Fortnightly',
    everyThirdWeek: 'Every Third Week',
    monthly: 'Monthly',
    biMonthly: 'Bi-Monthly',
    quarterly: 'Quarterly',
    annual: 'Annual',
    biAnnual: 'Bi-Annual',
    weeklyMonday: 'Weekly, Monday',
    weeklyTuesday: 'Weekly, Tuesday',
    weeklyWednesday: 'Weekly, Wednesday',
    weeklyThursday: 'Weekly, Thursday',
    weeklyFriday: 'Weekly, Friday',
    weeklySaturday: 'Weekly, Saturday',
    weeklySunday: 'Weekly, Sunday',
    monthlyFirstMonday: 'Monthly, First Monday',
    monthlyFirstTuesday: 'Monthly, First Tuesday',
    monthlyFirstWednesday: 'Monthly, First Wednesday',
    monthlyFirstThursday: 'Monthly, First Thursday',
    monthlyFirstFriday: 'Monthly, First Friday',
    monthlyFirstSaturday: 'Monthly, First Saturday',
    monthlyFirstSunday: 'Monthly, First Sunday',
    monthlySecondMonday: 'Monthly, Second Monday',
    monthlySecondTuesday: 'Monthly, Second Tuesday',
    monthlySecondWednesday: 'Monthly, Second Wednesday',
    monthlySecondThursday: 'Monthly, Second Thursday',
    monthlySecondFriday: 'Monthly, Second Friday',
    monthlySecondSaturday: 'Monthly, Second Saturday',
    monthlySecondSunday: 'Monthly, Second Sunday',
    monthlyThirdMonday: 'Monthly, Third Monday',
    monthlyThirdTuesday: 'Monthly, Third Tuesday',
    monthlyThirdWednesday: 'Monthly, Third Wednesday',
    monthlyThirdThursday: 'Monthly, Third Thursday',
    monthlyThirdFriday: 'Monthly, Third Friday',
    monthlyThirdSaturday: 'Monthly, Third Saturday',
    monthlyThirdSunday: 'Monthly, Third Sunday',
    monthlyLastMonday: 'Monthly, Last Monday',
    monthlyLastTuesday: 'Monthly, Last Tuesday',
    monthlyLastWednesday: 'Monthly, Last Wednesday',
    monthlyLastThursday: 'Monthly, Last Thursday',
    monthlyLastFriday: 'Monthly, Last Friday',
    monthlyLastSaturday: 'Monthly, Last Saturday',
    monthlyLastSunday: 'Monthly, Last Sunday',
    everyTwelthWeek: 'Every 12th Week',
    everySixteenthWeek: 'Every 16th Week',
    everyFourthWeek: 'Every 4th Week',
    everyEigthWeek: 'Every 8th Week',
    eightPerYear: '8 per Year',
    na: 'N/A',
  },
  distributionDays: {
    mon: 'Monday',
    tue: 'Tuesday',
    wed: 'Wednesday',
    thu: 'Thursday',
    fri: 'Friday',
    sat: 'Saturday',
    sun: 'Sunday',
  },
  calculationSize: {
    modular: 'Modular',
    insertCPM: 'Insert CPM',
    ccms: 'CCMS',
    flatRate: 'Flat Rate',
  },
  costType: {
    Dollar: 'Dollar',
    Percentage: 'Percentage',
    'Included in rate': 'Included in rate',
    Mono: 'Mono',
    'Flat Rate': 'Flat Rate',
    'N/A': 'N/A',
  },
};

// Table Column Min-widths
export const columnWidths = {
  actions: 120,
  status: 80,
  placementName: 160,
  weightingName: 120,
  dayPart: 160,
  placementNameTv: 120,
  state: 60,
  market: 200,
  distributionDays: 140,
  casualRate: 110,
  clientRate: 110,
  fullColourLoad: 120,
  fullPageColourCost: 150,
  costType: 140,
  discount: 100,
  evCPM: 78,
  viewability: 78,
  discountYoY: 100,
  sectionPositioning: 120,
  calculationSize: 115,
  height: 60,
  width: 60,
  readership: 110,
  readershipCPM: 120,
  frequency: 110,
  distributionMethod: 135,
  yoyFullPageRateVariance: 105,
  yoyReadershipCPMVariance: 120,
  yoyCirculationCPMVariance: 115,
  yoyVariance: 100,
  yoyVarianceOOH: 130,
  multiplierName: 120,
  placementType: 120,
  durationMultiplier: 100,
  durationMultiplierYoY: 80,
  demographic: 120,
  contractualBonus: 140,
  averageAudience: 140,
  costPerThousand: 140,
  averageRate: 150,
  averageAudienceTv: 110,
  averageAudienceTvYoY: 95,
  language: 100,
  audit: 100,
  format: 100,
  installation: 90,
  displayVertical: 120,
  placement: 100,
  sizeSpecifications: 250,
  calculationType: 130,
  circulation: 120,
  comment: 350,
  shareOfVoice: 180,
  rateYOYVariance: 150,
  checkbox: 20,
};

/**
 * Contract / Medium
 */

export const contractStatuses = {
  draft: 'Draft',
  revision: 'Revision',
  review: 'Review',
  masterReview: 'MasterReview',
  locked: 'Locked',
  archived: 'Archived',
  approved: 'Approved',
  rejected: 'Rejected',
};

export const contractStatusLabels = {
  draft: 'Draft',
  vendorToReview: 'Vendor To Review',
  agencyToReview: 'Agency To Review',
  pendingApproval: 'Pending Approval',
  locked: 'Locked',
  archived: 'Archived',
};

export const mediumStatuses = {
  draft: 'Draft',
  revision: 'In Progress',
  approved: 'Approved',
  rejected: 'Rejected',
  review: 'Under Review',
};

export const allMedumStatues = {
  draft: 'Draft',
  revision: 'In Progress',
  approved: 'Approved',
  rejected: 'Rejected',
  review: 'Under Review',
  default: 'Actions',
};

export const displayTypePosition = {
  prefix: 'prefix',
  suffix: 'suffix',
};

export const displayTypeSymbol = {
  money: '$',
  percentage: '%',
};

export const contractSectionTypes = {
  weighting: 'weighting',
  multipliers: 'multipliers',
  newspaperPlacement: 'placements',
  contractualBonus: 'contractual_bonus',
};

/**
 * Media Types
 */
export const mediaTypes = {
  newspapers: 'Newspapers',
  television: 'Television',
  cinema: 'Cinema',
  radio: 'Radio',
  magazines: 'Magazines',
  ooh: 'Out of Home',
  digital: 'Digital',
};

export const mediaTypeCodes = {
  newspapers: 'newspapers',
  television: 'television',
  cinema: 'cinema',
  radio: 'radio',
  magazines: 'magazines',
  ooh: 'ooh',
  digital: 'digital',
};

/**
 * Error Messages
 */

export const errorMessages = {
  'placement-not-approved':
    'Please ensure all placements have been approved before you can proceed to Government approval.',
  'placement-not-reviewed':
    'Please ensure all placements have been reviewed before you can continue.',
  default:
    'Looks like there was an issue. Please try again or contact support.',
};

// Sort options
export const sortOptions = {
  updatedAtAsc: 'updatedAt:asc',
  updatedAtDesc: 'updatedAt:desc',
  createdAtAsc: 'createdAt:asc',
  createdAtDesc: 'createdAt:desc',
  nameAsc: 'name:asc',
  nameDesc: 'name:desc',
  masterNetworkAsc: 'masterNetwork:asc,mediaSubType:asc,network:asc',
  networkAsc: 'network:asc,mediaSubType:asc',
};

export const mediaTypeOrderBy = {
  [mediaTypeCodes.cinema]: sortOptions.masterNetworkAsc,
  [mediaTypeCodes.digital]: sortOptions.masterNetworkAsc,
  [mediaTypeCodes.magazines]: sortOptions.masterNetworkAsc,
  [mediaTypeCodes.newspapers]: sortOptions.masterNetworkAsc,
  [mediaTypeCodes.ooh]: sortOptions.masterNetworkAsc,
  [mediaTypeCodes.radio]: sortOptions.masterNetworkAsc,
  [mediaTypeCodes.television]: sortOptions.networkAsc,
};

// Visual Cues
export const visualCues = {
  canStartNegotiations: 'canStartNegotiations',
  canSubmitForReview: 'canSubmitForReview',
  canSubmitForRevision: 'canSubmitForRevision',
  canSubmitForApproval: 'canSubmitForApproval',
  canApproveTemplate: 'canApproveTemplate',
  canRejectTemplate: 'canRejectTemplate',
  canEditPlacement: 'canEditPlacement',
  canAddPlacement: 'canAddPlacement',
  canRemovePlacement: 'canRemovePlacement',
  canReviewPlacement: 'canReviewPlacement',
};

// Actions Menu Buttons
export const actionsMenuButtons = {
  canStartNegotiations: {
    heading: 'Start negotiation',
    description: 'The contract will enter the first round of negotiations',
    modal: {
      title: 'Are you sure you want to start the negotiation?',
      description:
        'After the negotiation has started, the vendor will be allowed to enter their rates into the contract.',
      submitButtonLabel: 'Start negotiation',
    },
  },
  canSubmitForRevision: {
    heading: 'Create new round',
    description:
      'Send the contract back to the vendor so they can revise their rates.',
    modal: {
      title: 'Create a new round',
      description:
        'Creating a new negotiation round will send the contract back to the vendor for revision, allowing them to re-submit their rates for any placement you have rejected.',
      submitButtonLabel: 'Create new round',
    },
  },
  canSubmitForApproval: {
    heading: 'Approve/Lock Contract',
    description:
      'Please ensure all placements are approved before locking the contract',
    modal: {
      title: 'Approve/Lock Contract',
      description:
        ' By approving this contract, you are accepting all rates within it as final, and it will be locked from further editing. Are you sure you want to approve this contract?',
      submitButtonLabel: 'Submit for Government approval',
    },
  },
  canSubmitForReview: {
    heading: 'Send to UM for review',
    description: 'UM will review the rates and update the status accordingly.',
    modal: {
      title: 'Are you sure you want to submit your rates?',
      description: `After you submit your rates, you won't be able to make any changes until after they have been reviewed.${multiLineDelimiter}Please note, upon clicking Submit rates, Gravity will automatically download a copy of the submitted rates for your records.`,
      submitButtonLabel: 'Submit rates',
    },
  },
  canApproveTemplate: {
    heading: 'Lock contract',
    description: 'No changes can be made to this contract after it is locked.',
    modal: {
      title: 'Are you sure you want to lock this contract?',
      description: `After you lock a contract, you will no longer be able to make any changes to it.`,
      submitButtonLabel: 'Lock contract',
    },
  },
  canRejectTemplate: {
    heading: 'Send back to UM',
    description: 'UM will review rates and re-submit once updated',
    modal: {
      title: 'Send back to UM',
      description: `By sending back to UM, this contract will be unlocked, and further changes will be able to be made by the vendor. Are you sure you want to send back to UM?`,
      submitButtonLabel: 'Send back to UM',
    },
  },
};

// Update Types when updating a media type (ie. PATCH newspaper/[newspaperId])
export const updateTypes = {
  newspaper: 'placements',
};

export const deleteConfirmation = {
  weighting: {
    title: 'Are you sure you want to delete these Classification Item(s)?',
    description: '',
    submitButtonLabel: 'Confirm',
  },
  placements: {
    title: 'Are you sure you want to retire this placement?',
    description: '',
    submitButtonLabel: 'Confirm',
    commentPlaceholder: 'Please enter a reason for retiring',
    required: 'You must enter a comment before retiring',
  },
};

export const warnings = {
  addPlacementNoWeighting: {
    title: 'Classification is required',
    description: 'Please add classification before adding a placement.',
    submitButtonLabel: 'Confirm',
  },
};

export const contractStatusColors = {
  [contractStatuses.review]: 'warning',
  [contractStatuses.revision]: 'default',
  [contractStatuses.masterReview]: 'processing',
  [contractStatuses.locked]: 'success',
  [contractStatuses.archived]: 'success',
};

export const contractStatusLabelText = {
  [contractStatuses.review]: contractStatusLabels.agencyToReview,
  [contractStatuses.revision]: contractStatusLabels.vendorToReview,
  [contractStatuses.masterReview]: contractStatusLabels.pendingApproval,
  [contractStatuses.locked]: contractStatusLabels.locked,
  [contractStatuses.archived]: contractStatusLabels.archived,
};
export const placementTypes = {
  ACTIVE: 'Active',
  RETIRED: 'Retired',
};

export const statusInfo =
  'Weighted Rates information will only reflect Active placements. Retired and New placement will not be included.';

export const stateOptions = [
  'ACT',
  'NSW',
  'NT',
  'QLD',
  'SA',
  'TAS',
  'VIC',
  'WA',
  'National',
];

export const marketOptions = [
  'National',
  'Metro',
  'Regional',
  'Sydney',
  'Melbourne',
  'Brisbane',
  'Adelaide',
  'Perth',
  'NSW',
  'VIC',
  'QLD',
  'SA',
  'WA',
  'TAS',
  'NT',
];

export const placementEditTypes = {
  ADD_PLACEMENT_DIALOG: 'add_placement_dialog',
  ADD_PLACEMENT_INLINE: 'add_placement_inline',
  EDIT_PLACEMENT_DIALOG: 'edit_placement_dialog',
  EDIT_PLACEMENT_INLINE: 'edit_placement_inline',
  RETIRE_DIALOG: 'retire_dialog',
};

export const dropdownOptionsOOH = {
  frequency: {
    weekly: 'Weekly',
    fortnightly: 'Fortnightly',
    monthly: 'Monthly',
  },
};

export const calcFieldLabel = {
  evCPMYOYVariance: 'evCPM (YoY Variance)',
  yoyWeightedCPM: 'Weighted CPM (YoY Variance)',
  yoyWeightedRate: 'Weighted Rate (YoY Variance)',
};

/**
 * Set of fields for placements (used for targetting special use cases)
 */
export const placementFields = {
  newspaper: {
    colourLoadType: 'costType',
    fullColourLoad: 'fullColourLoad',
  },
  radio: {
    averageAudience: 'averageAudience',
    clientRate: 'clientRate',
    costPerThousand: 'costPerThousand',
    costPerThousandYOYVariance: 'costPerThousandYOYVariance',
  },
  television: {
    averageRate: 'averageRate',
    weightedAverageRate: 'weightedAverageRate',
  },
};

export const sectionTypes = {
  overview: 'overview',
  newspaperPlacements: 'placements',
  radioPlacements: 'radio_placements',
  televisionPlacements: 'television_placements',
  magazinPlacements: 'magazine_placements',
  cinemaPlacements: 'cinema_placements',
  digitalPlacements: 'digital_placements',
  oohPlacement: 'ooh_placements',
  televisionMuliplier: 'television_multiplier',
};

export const bonus = {
  contractualBonusYOY: 'contractualBonusYOY',
  contractualBonus: 'contractualBonus',
};
