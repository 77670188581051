// eslint-disable-next-line import/no-extraneous-dependencies
import { Contracts } from '@mbdt/shared/datacy';
import { Button } from 'antd';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { contractSectionTypes, contractStatuses } from '../../constants';
import { ContractUIContext } from '../../containers/Contract/ContractUIContext';
import { useSaveChangesNotification } from '../../containers/Contract/ContractUIEvents';
import { useContractForm } from '../../context/contractForm-context';
import { updateWeighting } from '../../store/actions/contract.actions';

const SaveWeightingButton = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const dispatch = useDispatch();
  const {
    isSaving,
    tableData,
    data: { sections = [], status },
  } = useSelector((state) => state.contract);
  const { id: contractId, mediaType } = useParams();
  const {
    activeTab: currentTab,
    setDataHasChanged,
    setHasValidationErrors,
  } = useContext(ContractUIContext);
  const formContext = useContractForm();

  // Update rates when user clicks on the 'Save rates' button
  const handleSaveData = async () => {
    dispatch(updateWeighting(contractId, mediaType));
  };

  // Detect when a value has been updated in the weighting table, and enable the Save button so the
  // user is able to save the updated data
  const handleIsDataDirty = () => {
    if (currentTab === contractSectionTypes.weighting) {
      const filteredSections = sections.filter(
        ({ type }) => type === contractSectionTypes.weighting
      );
      if (
        _.isEqual(
          filteredSections,
          tableData.filter(
            ({ type }) => type === contractSectionTypes.weighting
          )
        )
      ) {
        setDataHasChanged(false);
        setHasValidationErrors(false);
        return setIsButtonDisabled(true);
      }
    }

    // Check if form has errors
    let hasErrors = false;
    _.forEach(formContext.state.forms[currentTab].getFieldsError(), (err) => {
      hasErrors = hasErrors || !_.isEmpty(err.errors);
    });

    if (hasErrors) {
      setDataHasChanged(true);
      setHasValidationErrors(true);
      return setIsButtonDisabled(true);
    }

    setDataHasChanged(true);
    setHasValidationErrors(false);
    return setIsButtonDisabled(false);
  };

  const customSaveHandler = () => {
    if (currentTab === contractSectionTypes.weighting) {
      handleSaveData();
    }
  };

  useEffect(() => {
    if (status === contractStatuses.review) {
      setIsButtonDisabled(true);
    }
  }, [status]);

  useEffect(() => {
    handleIsDataDirty();
  }, [tableData]);

  useSaveChangesNotification(customSaveHandler);

  return (
    <Button
      type="primary"
      onClick={handleSaveData}
      disabled={isButtonDisabled}
      loading={isSaving}
      data-cy={Contracts.SaveWeightingButton}
    >
      Save
    </Button>
  );
};

export default SaveWeightingButton;
