import { Space } from 'antd';
// import CheckableTag from 'antd/lib/tag/CheckableTag';
import React from 'react';

import AllPlacementStatus from '../TemplateTable/AllPlacementStatus';

const PlacementFilters = () => {
  return (
    <Space style={{ flex: 1, fontWeight: 600 }}>
      <AllPlacementStatus />
      &nbsp;
      {/* Removing as it contains no value at present 
      <CheckableTag key={1} checked onChange={() => null}>
        All placements
  </CheckableTag> */}
      {/* 
        TODO: Keeping this code here because in the future you may want to implement
        client-side filters for the Placement Status (ie. draft/rejected/approved/removed)
      */}
      {/* <CheckableTag key={2} checked={false} onChange={(checked) => null}>
        Draft
      </CheckableTag>
      <CheckableTag key={3} checked={false} onChange={(checked) => null}>
        Approved
      </CheckableTag>
      <CheckableTag key={4} checked={false} onChange={(checked) => null}>
        Rejected
      </CheckableTag>
      <CheckableTag key={4} checked={false} onChange={(checked) => null}>
        Removed
      </CheckableTag> */}
    </Space>
  );
};

export default PlacementFilters;
