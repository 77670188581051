import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import logger from 'redux-logger';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import api from '../services/api';
import authMiddleware from './middleware/auth-middleware';
import rootReducer from './rootReducer';

export const history = createBrowserHistory();

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: ['contract'],
  // There is an issue in the source code of redux-persist (default setTimeout does not cleaning)
  timeout: null,
};

const persistedReducer = persistReducer(persistConfig, rootReducer(history));

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      thunk: {
        extraArgument: api,
      },
    })
      .concat(routerMiddleware(history))
      .concat(authMiddleware)
      .concat(logger),
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    // eslint-disable-next-line global-require
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer);
  });
}

export const persistor = persistStore(store);

export default store;
