import { Layout, Spin } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Text from 'antd/lib/typography/Text';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import ContractHeader from '../../components/ContractHeader';
import SiteLayout from '../../components/Layout/Layout';
import RouterSavePrompt from '../../components/SavePrompt/RouterSavePrompt';
import ContractTabs from './ContractTabs';

const ContractContent = () => {
  const { isLoading } = useSelector((state) => state.contract);

  // Hack to remove scrollbars on contract page
  useEffect(() => {
    document.getElementById('root').style['overflow-y'] = 'hidden';
  }, []);

  if (isLoading)
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          flexFlow: 'column wrap',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin size="large" />
        <Text style={{ marginTop: 16 }}>Fetching contract...</Text>
      </div>
    );

  return (
    <SiteLayout>
      <RouterSavePrompt withPrompt />
      <ContractHeader />
      <Layout>
        <Content>
          <ContractTabs />
        </Content>
      </Layout>
    </SiteLayout>
  );
};

export default ContractContent;
