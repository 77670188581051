import { ExclamationCircleOutlined } from '@ant-design/icons';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Contracts } from '@mbdt/shared/datacy';
import { Button, Space } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { deleteConfirmation } from '../../constants';
import { ContractUIContext } from '../../containers/Contract/ContractUIContext';
import { removeWeighting } from '../../store/actions/contract.actions';
import {
  selectSelectedWeightingRows,
  selectVisualCues,
} from '../../store/selectors';
import { SavePrompt, useHasChangesToSave } from '../SavePrompt/SavePrompt';
import AddWeightingModal from './AddWeightingModal';

const WeightingActions = () => {
  const dispatch = useDispatch();
  const [isWeightingModalVisible, setIsWeightingModalVisible] = useState(false);
  const { canAddWeighting } = useSelector(selectVisualCues);
  const sectionTypes = useSelector((state) => state.contract.sectionTypes);
  const { activeTab } = useContext(ContractUIContext);
  const hasChanges = useHasChangesToSave();

  const sectionType = sectionTypes?.find(
    (section) => section.type === activeTab
  );

  const selectedRows = useSelector(selectSelectedWeightingRows);
  const isWeightingSelected = selectedRows?.length > 0;

  const { id: contractId, mediaType } = useParams();

  // Show a confirmation modal if the user selects an Action Menu Item
  const showConfirmModal = async () => {
    let intervalId;
    confirm({
      'data-cy': Contracts.Confirmation,
      title: deleteConfirmation.weighting.title,
      icon: <ExclamationCircleOutlined />,
      content: deleteConfirmation.weighting.description,
      okText: deleteConfirmation.weighting.submitButtonLabel,
      async onOk() {
        clearInterval(intervalId); // Clear the interval in onOk
        await dispatch(removeWeighting(contractId, mediaType, selectedRows));
      },
      onCancel() {
        clearInterval(intervalId); // Clear the interval in onCancel
      },
      width: 500,
    });
    // Add data-cy attribute
    intervalId = setInterval(() => {
      const modalElement = document.querySelector('.ant-modal-confirm');
      const defaultButton = modalElement?.querySelector('.ant-btn-default');
      const primaryButton = modalElement?.querySelector('.ant-btn-primary');
      if (modalElement && defaultButton && primaryButton) {
        modalElement.setAttribute('data-cy', Contracts.Confirmation);
        defaultButton.setAttribute('data-cy', Contracts.ConfirmationCancelBtn);
        primaryButton.setAttribute('data-cy', Contracts.ConfirmationOkBtn);
        clearInterval(intervalId); // Clear the interval once the attributes are set
      }
    }, 10);
  };

  const OnSavePromptClose = () => {
    setIsWeightingModalVisible(false);
  };

  return (
    <Space>
      {isWeightingSelected && sectionType?.canAddWeighting && (
        <Button
          data-cy={Contracts.ConfirmationRemoveClassficationBtn}
          size="middle"
          style={{
            padding: 8,
            display: 'flex',
            alignItems: 'center',
            fontSize: 12,
            fontWeight: 500,
          }}
          danger
          type="primary"
          onClick={showConfirmModal}
        >
          Remove classification
        </Button>
      )}
      {canAddWeighting && sectionType?.canAddWeighting && (
        <Button
          data-cy={Contracts.ModalClassificationBtn}
          onClick={() => setIsWeightingModalVisible(true)}
          size="middle"
          style={{
            padding: 8,
            display: 'flex',
            alignItems: 'center',
            fontSize: 12,
            fontWeight: 500,
          }}
        >
          Add classification
        </Button>
      )}
      <SavePrompt
        show={isWeightingModalVisible}
        onSavePromptClose={OnSavePromptClose}
      />
      <AddWeightingModal
        isOpen={isWeightingModalVisible && !hasChanges}
        setIsOpen={setIsWeightingModalVisible}
      />
    </Space>
  );
};

export default WeightingActions;
