import axios from 'axios';
import React from 'react';
import { toast } from 'react-toastify';

import { ToastError } from '../../components/Common/ToastMessages';
import { fetchContracts } from '../../services/contracts.service';
import {
  fetchContractListFailure,
  fetchContractListStart,
  fetchContractListSuccess,
  updateItemCount,
} from '../slices/contracts.slice';

export const fetchContractList =
  (mediaType, pageLimit, pageNumber, orderBy) => async (dispatch) => {
    try {
      dispatch(fetchContractListStart());

      const {
        data = [],
        headers: { 'x-itemcount': itemCount },
      } = (await fetchContracts(mediaType, pageLimit, pageNumber, orderBy)) || {
        data: [],
        headers: { 'x-itemcount': 0 },
      };

      // Ensures that we are persisting only API responses. There is a weird bug that hasn't been found yet that
      // causes the localstorage to store a HTML response
      if (Array.isArray(data)) {
        dispatch(fetchContractListSuccess(data));
        dispatch(updateItemCount(itemCount));
      } else {
        dispatch(fetchContractListSuccess([]));
        dispatch(updateItemCount(0));
      }
    } catch (error) {
      // Determine if this error is a cancellation. If it is, we ignore.
      if (!axios.isCancel(error)) {
        // eslint-disable-next-line no-console
        console.log('Failed to fetch: ', error);
        dispatch(fetchContractListFailure(error));
        toast(
          <ToastError message="Looks like there was an issue retrieving the contracts. Please try again or contact support." />,
          { autoClose: 7000 }
        );
      }
    }
  };
