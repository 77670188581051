// eslint-disable-next-line import/no-extraneous-dependencies
import { Contracts } from '@mbdt/shared/datacy';
import React from 'react';
import { useSelector } from 'react-redux';

import { visualCues } from '../../constants';
import { selectVisualCues } from '../../store/selectors';
import ActionsMenuItem from './ActionsMenuItem';

const ActionsMenuList = ({
  handleRolloverPhase,
  handleSubmitPhase,
  handleApprovePhase,
  handleRejectPhase,
}) => {
  const {
    canStartNegotiations,
    canSubmitForReview,
    canSubmitForRevision,
    canSubmitForApproval,
    canApproveTemplate,
    canRejectTemplate,
  } = useSelector(selectVisualCues);

  // Create an object literal comprised of visual cues relating to the Action Buttons
  const actionButtonCues = {
    canStartNegotiations,
    canSubmitForReview,
    canSubmitForRevision,
    canSubmitForApproval,
    canApproveTemplate,
    canRejectTemplate,
  };

  const handleOnClick = (type) => {
    switch (type) {
      // Rollover contract to the next phase (eg. Draft to Round 1)
      case visualCues.canStartNegotiations:
        return handleRolloverPhase(visualCues.canStartNegotiations);

      // Submit phase, which transitions contract to 'Agency to Review'
      case visualCues.canSubmitForReview:
        return handleSubmitPhase(visualCues.canSubmitForReview);

      // Rollover contract to the next phase (eg. Round 1 to Round 2)
      case visualCues.canSubmitForRevision:
        return handleRolloverPhase(visualCues.canSubmitForRevision);

      // Submit phase, which transitions contract to 'Client to Review'
      case visualCues.canSubmitForApproval:
        return handleSubmitPhase(visualCues.canSubmitForApproval);

      // Reject rates and send back to UM for review (eg. Client/Government has rejected them)
      case visualCues.canRejectTemplate:
        return handleRejectPhase(visualCues.canRejectTemplate);

      // Lock a contract after it has been approved by client (eg. Government)
      case visualCues.canApproveTemplate:
        return handleApprovePhase(visualCues.canApproveTemplate);

      default:
        return null;
    }
  };

  // Render the menu items based on whether their visual cue flag is active
  const renderActionMenuItems = () => {
    // Loop over visual cues related to the Action Buttons and render each ActionMenuItem
    return Object.entries(actionButtonCues).map(
      ([key, value], index) =>
        value && (
          <ActionsMenuItem
            key={visualCues[key]}
            type={visualCues[key]}
            onClick={() => handleOnClick(visualCues[key])}
            dataCy={`${Contracts.ContractDropdownActionItem}${index}`}
          />
        )
    );
  };

  return renderActionMenuItems();
};

export default ActionsMenuList;
