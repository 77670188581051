import {
  columnsCinemaDurationMultipliers,
  columnsCinemaPlacements,
} from './columnsCinema';
import { columnsDigitalPlacements } from './columnsDigital';
import { columnsMagazinePlacements } from './columnsMagazine';
import { columnsPlacements } from './columnsNewspaper';
import { columnsOOHPlacements } from './columnsOOH';
import { columnsOverview } from './columnsOverview';
import {
  columnsRadioDurationMultipliers,
  columnsRadioPlacements,
} from './columnsRadio';
import {
  columnsTelevisionMultiplier,
  columnsTelevisionPeakOffPeak,
} from './columnsTelevision';
import { columnsWeighting } from './columnsWeighting';
import { contractualBonus } from './contractualBonus';
import { createTemplateColumnsCinema } from './templateColumnsCinema';
import { createTemplateColumnsDigital } from './templateColumnsDigital';
import { createTemplateColumnsMagazine } from './templateColumnsMagazine';
import { createTemplateColumnsNewspaper } from './templateColumnsNewspaper';
import { createTemplateColumnsOOH } from './templateColumnsOOH';
import { createTemplateColumnsRadio } from './templateColumnsRadio';
import { createTemplateColumnsTelevision } from './templateColumnsTelevision';

// Column mapping for the tables within each tab in a contract template
export const contractTableColumns = {
  // Newspaper
  placements: columnsPlacements,

  // Cinema
  cinema_placements: columnsCinemaPlacements,
  cinema_multipliers: columnsCinemaDurationMultipliers,

  // Television
  television_multipliers: columnsTelevisionMultiplier,
  // TODO: Chat with Justin about potentially merging Peak & Off Peak
  // into 'television_placements' for example
  television_placements: columnsTelevisionPeakOffPeak,

  // Radio
  radio_multipliers: columnsRadioDurationMultipliers,
  radio_placements: columnsRadioPlacements,

  // Magazine
  magazine_placements: columnsMagazinePlacements,

  // OOH
  ooh_placements: columnsOOHPlacements,

  // Digital
  digital_placements: columnsDigitalPlacements,

  // Weighting
  weighting: columnsWeighting,

  // ContractualBonus
  television_contractual_bonus: contractualBonus,
};

// Column mapping for the Overview Table in each contract template
export const contractOverviewColumns = {
  newspapers: columnsOverview,
  cinema: columnsOverview,
  television: columnsOverview,
  radio: columnsOverview,
  magazines: columnsOverview,
  ooh: columnsOverview,
  digital: columnsOverview,
};

export const createContractsListColumns = (mediaType, data) => {
  if (mediaType === 'newspapers') {
    return createTemplateColumnsNewspaper(data);
  }
  if (mediaType === 'cinema') {
    return createTemplateColumnsCinema(data);
  }
  if (mediaType === 'television') {
    return createTemplateColumnsTelevision(data);
  }
  if (mediaType === 'radio') {
    return createTemplateColumnsRadio(data);
  }
  if (mediaType === 'magazines') {
    return createTemplateColumnsMagazine(data);
  }
  if (mediaType === 'ooh') {
    return createTemplateColumnsOOH(data);
  }
  if (mediaType === 'digital') {
    return createTemplateColumnsDigital(data);
  }
};
