import React, { useContext, useEffect, useRef } from 'react';

import { ContractUIContext } from '../../containers/Contract/ContractUIContext';

const TemplateTableHeader = ({ ...props }) => {
  const ref = useRef(null);
  const { setContractTableHeaderHeight } = useContext(ContractUIContext);

  useEffect(() => {
    if (ref.current && ref.current.offsetHeight > 0) {
      setContractTableHeaderHeight(ref.current.offsetHeight);
    }
  }, [ref.current]);

  return <thead {...props} ref={ref} />;
};

export default TemplateTableHeader;
