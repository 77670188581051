import { contractStatuses } from '../../constants';

export const userToken = (state) => state.user.token;
export const selectContractualBonus = (state) => state?.contract?.bonus;
export const selectContractSections = (state) => state.contract.data.sections;
export const selectContractTableData = (state) => state.contract.tableData;
export const selectVisualCues = (state) => state.contract.visualCues || {};

/**
 * Negotiation Workflow Flag: isContractInDraft
 * Contract is in Phase 0, which means only the Agency can see the contract and make edits
 */
export const selectIsContractInDraft = (state) =>
  state.contract?.data?.phase === 0;

/**
 * Negotiation Workflow Flag: isContractInFirstRevision
 * Contract is now in Phase 1 Revision, which means the Vendor can now enter their rates
 */
export const selectIsContractInFirstRevision = (state) =>
  state.contract?.data?.status === contractStatuses.revision &&
  state.contract?.data?.phase === 1;

/**
 * Negotiation Workflow Flag: isContractInReview
 * Contract is now under review, which means the Vendor can not make any edits, and the Agency has to review each placement
 * If any placement has it's status marked as 'Revision', then the contract will be sent back to Revision for the Vendor to update the rates
 * If all placements have been approved, then the contract will progress to the next status
 */
export const selectIsContractInReview = (state) =>
  state.contract?.data?.status === contractStatuses.review &&
  state.contract?.data?.phase > 0;

/**
 * Negotiation Workflow Flag: isContractInRevision
 * Contract has been sent back to the status 'Revision' status by the Agency because they have not approved all placements
 */
export const selectIsContractInRevision = (state) =>
  state.contract?.data?.status === contractStatuses.revision &&
  state.contract?.data?.phase > 0;

/**
 * Negotiation Workflow Flag: isContractInRevision
 * All placements have been approved by the Agency and the contract has been submitted to the client for master review
 */
export const selectIsContractInClientReview = (state) =>
  state.contract?.data?.status === contractStatuses.masterReview;

/**
 * Negotiation Workflow Flag: isContractLocked
 * Contract has been approved by the client and has now been locked by the Agency
 */
export const selectIsContractLocked = (state) =>
  state.contract?.data?.status === contractStatuses.locked;

/**
 * Negotiation Workflow Flag: isContractArchived
 * Contrac has been archived by the Agency
 */
export const selectIsContractArchived = (state) =>
  state.contract?.data?.status === contractStatuses.archived;

export const selectSelectedWeightingRows = (state) =>
  state.contract.selectedWeightingRows;

export const selectSelectedPlacementRows = (state) =>
  state.contract.selectedPlacementRows;

/**
 * Get the current contract list
 * @returns The current list of contracts from localstate
 */
export const selectContractList = (state) => {
  /**
   * There's currently a bug where contracts fail to load and the response contains HTML. Until we
   * catch the specific scenario that causes this issue we have the following workaround
   */
  if (Array.isArray(state.contracts?.data)) {
    return state.contracts;
  }
  return { data: [], isLoading: false, itemCount: 0 };
};
