import { InputNumber } from 'antd';
import React from 'react';

const InputInteger = React.memo(({ ...props }) => {
  const renderInputFormatter = (value) => {
    const removeDecimal = value.replace('.', '');
    return removeDecimal.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const renderInputParser = (value) => {
    return value.replace(/\$\s?|(,*)/g, '');
  };
  return (
    <InputNumber
      formatter={renderInputFormatter}
      parser={renderInputParser}
      {...props}
    />
  );
});

InputInteger.displayName = 'InputInteger';

// TODO: Update these propTypes
/* eslint-disable react/forbid-prop-types */
InputInteger.propTypes = {};

export default InputInteger;
