// eslint-disable-next-line import/no-extraneous-dependencies
import { Contracts } from '@mbdt/shared/datacy';
import { Button, Space } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { mediumStatuses, placementTypes } from '../../constants';

const PlacementActions = ({ placement, index }) => {
  const disabled =
    placement?.data?.placementType === placementTypes.RETIRED ||
    placement?.data?.status === mediumStatuses.approved;

  return (
    <Space>
      <Button
        type="button"
        disabled={disabled}
        style={{
          backgroundColor: '#E6F7FF',
          fontSize: 12,
          padding: 6,
        }}
        data-cy={`${Contracts.PlacementBtnEdit}-${index}`}
      >
        {disabled ? (
          'Edit'
        ) : (
          <Link to={`?action=edit&recordId=${placement.id}`}>Edit</Link>
        )}
      </Button>

      <Button
        type="button"
        danger
        disabled={disabled}
        style={{
          fontSize: 12,
          padding: 6,
        }}
        data-cy={`${Contracts.PlacementBtnRetire}-${index}`}
      >
        {disabled ? (
          'Retire'
        ) : (
          <Link to={`?action=retire&recordId=${placement.id}`}>Retire</Link>
        )}
      </Button>
    </Space>
  );
};

/* eslint-disable react/forbid-prop-types */
PlacementActions.propTypes = {
  placement: PropTypes.any,
  index: PropTypes.number,
};

export default PlacementActions;
