/* eslint-disable prettier/prettier */
import { ExclamationCircleOutlined } from '@ant-design/icons';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Contracts } from '@mbdt/shared/datacy';
import { Button, Dropdown, Menu } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import React, { useState } from 'react';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  actionsMenuButtons,
  contractStatuses,
  multiLineDelimiter,
} from '../../constants';
import { useContractForm } from '../../context/contractForm-context';
import {
  approvePhase,
  downloadContract,
  rolloverPhase,
  submitRates,
} from '../../store/actions/contract.actions';
import { ToastError } from '../Common/ToastMessages';
import { SavePrompt, useHasChangesToSave } from '../SavePrompt/SavePrompt';
import ActionsMenuList from './ActionsMenuList';

const ActionsMenu = () => {
  const dispatch = useDispatch();
  const formContext = useContractForm();
  const { id: contractId, mediaType } = useParams();
  const [displaySavePrompt, setDisplaySavePrompt] = useState(false);

  const hasChanges = useHasChangesToSave();

  // Validate each form (they live inside each contract tab) and update the form context
  // if an error is thrown (ie. a required field is empty)
  const handleValidateForm = async () => {
    const validation = Object.entries(formContext.state.forms).map(
      async ([id, form]) => {
        try {
          await form.validateFields();

          // If we don't receive an validation error for this form, remove error from form context
          formContext.dispatch({ type: 'validateForm', payload: { [id]: null } });
          return true;
        } catch (error) {
          // This form has a validation error, add the error to form context
          formContext.dispatch({ type: 'validateForm', payload: { [id]: error } });
          return false;
        }
      }
    );

    // Validate each form and update the Form Context with the results
    const res = await Promise.all(validation);

    // Return true/false depending on whether the all forms are valid or not
    // return !!res.includes(true);
    return res.every(value => value === true);
  };

  // Show a confirmation modal if the user selects an Action Menu Item
  const showConfirmModal = async (type, onSubmit) => {
    if (hasChanges) return;

    let content = actionsMenuButtons[type].modal.description;
    const multiLine = content.includes(multiLineDelimiter);

    if (multiLine) {
      content = (
        <>
          {content.split(multiLineDelimiter).map((paragraph) => (
            <p>{paragraph}</p>
          ))}
        </>
      );
    }

    confirm({
      title: actionsMenuButtons[type].modal.title,
      icon: <ExclamationCircleOutlined />,
      content,
      okText: actionsMenuButtons[type].modal.submitButtonLabel,
      async onOk() {
        await onSubmit();
      },
      onCancel() {},
      width: 500,
    });
  };

  // Submit phase, which transitions contract to either 'Agency to Review' or 'Client to Review'
  const handleSubmitPhase = async (type) => {
    // Validate each form
    const isFormValid = await handleValidateForm();

    setDisplaySavePrompt(hasChanges);

    // If the form is valid, show the End Round modal
    return isFormValid
      ? showConfirmModal(type, async () => {
          dispatch(submitRates(mediaType, contractId));
          await downloadContract(contractId, mediaType);
        })
      : !hasChanges &&
          toast(
            <ToastError message="Please review the rates to ensure they have all been entered in correctly." />,
            { autoClose: 7000 }
          );
  };

  // Rollover contract to the next phase (eg. Draft to Round 1, Round 1 to Round 2, etc)
  const handleRolloverPhase = (type) => {
    setDisplaySavePrompt(hasChanges);
    showConfirmModal(type, () => dispatch(rolloverPhase(mediaType, contractId)));
  };

  // Approve a contract
  const handleApprovePhase = (type) => {
    setDisplaySavePrompt(hasChanges);
    showConfirmModal(type, () =>
      dispatch(approvePhase(mediaType, contractId, contractStatuses.approved))
    );
  };

  // Reject a contract
  const handleRejectPhase = (type) => {
    setDisplaySavePrompt(hasChanges);
    showConfirmModal(type, () =>
      dispatch(approvePhase(mediaType, contractId, contractStatuses.rejected))
    );
  };

  // Render the Actions Dropdown Menu
  const renderActionsMenu = () => (
    <Menu className="actions-menu">
      <ActionsMenuList
        handleRolloverPhase={handleRolloverPhase}
        handleApprovePhase={handleApprovePhase}
        handleRejectPhase={handleRejectPhase}
        handleSubmitPhase={handleSubmitPhase}
      />
    </Menu>
  );

  return (
    <>
      {displaySavePrompt && hasChanges ? (
        <SavePrompt
          show={displaySavePrompt}
          onSavePromptClose={() => {
            setDisplaySavePrompt(false);
          }}
        />
      ) : null}
      <Dropdown trigger="click" overlay={renderActionsMenu}>
        <Button style={{ padding: 4, display: 'flex', alignItems: 'center' }} data-cy={Contracts.ContractActionDropdown}>
          <BiDotsVerticalRounded size="20px" />
        </Button>
      </Dropdown>
    </>
  );
};

export default ActionsMenu;
