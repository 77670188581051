export const environment = process.env.NODE_ENV || 'development';

export const apiURL =
  environment === 'production'
    ? process.env.REACT_APP_API_URL || '/api/'
    : process.env.REACT_APP_API_URL || 'http://localhost:3006/api/'; // 'https://gravity-sit.gravity.kinesso-apps.com/api';

export const correlationHeaderName =
  process.env.REACT_APP_CORRELATION_HEADER_NAME || 'x-correlation-id';

export const enforceAuthentication = true;

export const routes = {
  DASHBOARD: '/dashboard',
  CONTRACTS: '/contracts',
  NOT_FOUND: '/notfound',
  UNAUTHORISED: '/unauthorised',
  LOGIN: '/',
  LOGOUT: '/logout',
};

export const heapAnalytics = process.env.REACT_APP_HEAP_ID;

export const appEnvironment = process.env.REACT_APP_ENVIRONMENT;
export const appVersion = process.env.REACT_APP_APPLICATION_VERSION;
