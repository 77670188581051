import { PlusOutlined } from '@ant-design/icons';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Contracts } from '@mbdt/shared/datacy';
import { Button, Divider, Form, Input, Select, Space } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import Text from 'antd/lib/typography/Text';
import PropTypes from 'prop-types';
import React, { useContext, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  contractSectionTypes,
  dropdownOptionsOOH,
  marketOptions,
} from '../../constants';
import { ContractUIContext } from '../../containers/Contract/ContractUIContext';
import { addPlacement } from '../../store/actions/contract.actions';

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const AddPlacementOOH = ({ isOpen, setIsOpen }) => {
  const [frequencyOptions, frequencySetOption] = useState(
    Object.values(dropdownOptionsOOH.frequency)
  ); // Frequency field options
  const [frequency, setFrequency] = useState('');
  const inputRef = useRef(null);

  // Add new frequency option on change
  const onFrequencyChange = (event) => {
    setFrequency(event.target.value);
  };

  // Add new frequecy option
  const addItem = (e) => {
    e.preventDefault();
    frequencySetOption([
      ...frequencyOptions,
      frequency.charAt(0).toUpperCase() + frequency.slice(1),
    ]);
    setFrequency('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id, mediaType } = useParams();
  const {
    isAddingPlacement,
    data: { sections },
  } = useSelector((state) => state.contract);
  const { activeTab: sectionType } = useContext(ContractUIContext);

  const weightingSections = sections.filter(
    (s) => s.type === contractSectionTypes.weighting
  );

  const handleOk = () => {
    form.resetFields();
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        const placementName = form.getFieldValue(['placementName']);
        const weightingId = form.getFieldValue(['weighting']);
        const market = form.getFieldValue(['market']);

        const valuesObj = Object.entries(values).reduce((acc, [key, value]) => {
          // Remove medium from object
          if (key === 'weighting') return acc;

          if (key === 'market') {
            return {
              ...acc,
              [key]: {
                currentPhaseValue: market.join(', '),
              },
            };
          }

          return {
            ...acc,
            [key]: {
              currentPhaseValue: value,
            },
          };
        }, {});

        await dispatch(
          addPlacement(
            id,
            mediaType,
            { ...valuesObj, placementName },
            sectionType,
            weightingId,
            handleOk
          )
        );
      })
      .catch((info) => {
        // eslint-disable-next-line no-console
        console.log('Validation Failed:', info);
      });
  };

  return (
    <>
      <Modal
        visible={isOpen}
        title="Add a new placement"
        onCancel={handleCancel}
        destroyOnClose
        forceRender
        maskClosable={false}
        footer={[
          <Button
            key="back"
            onClick={handleCancel}
            data-cy={`${Contracts.AddNewPlacementModal}-${Contracts.PlacementModalCancel}`}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isAddingPlacement}
            onClick={handleSubmit}
            data-cy={`${Contracts.AddNewPlacementModal}-${Contracts.PlacementModalAdd}`}
          >
            Add placement
          </Button>,
        ]}
        data-cy={Contracts.AddNewPlacementModal}
      >
        <Form
          form={form}
          layout="horizontal"
          name="add-placement"
          {...formItemLayout}
        >
          <Form.Item
            name="placementName"
            label="Medium"
            rules={[
              {
                required: true,
                message: 'Please enter a medium',
              },
            ]}
          >
            <Input
              placeholder="Enter a medium"
              style={{ width: '100%' }}
              disabled={isAddingPlacement}
              data-cy={Contracts.FieldMedium}
            />
          </Form.Item>

          <Form.Item
            name="placement"
            label="Placement"
            rules={[
              {
                required: true,
                message: 'Please enter a placement',
              },
            ]}
          >
            <Input
              placeholder="Enter a placement"
              style={{ width: '100%' }}
              disabled={isAddingPlacement}
              data-cy={Contracts.FieldPlacement}
            />
          </Form.Item>

          <Form.Item
            name="market"
            label="Market"
            rules={[
              {
                required: true,
                message: 'Please select or enter market',
              },
            ]}
          >
            <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder="Select or enter market"
              disabled={isAddingPlacement}
              data-cy={Contracts.FieldMarket}
            >
              {marketOptions.map((market, index) => (
                <Option
                  key={market}
                  data-cy={`${Contracts.FieldMarket}-${Contracts.SelectItem}${index}`}
                >
                  {market}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="frequency"
            label="Frequency"
            rules={[
              {
                required: true,
                message: 'Please select a frequency',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a frequency"
              disabled={isAddingPlacement}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider
                    style={{
                      margin: '8px 0',
                    }}
                  />
                  <Space
                    style={{
                      padding: '0 8px 4px',
                    }}
                  >
                    <Input
                      placeholder="Enter new option"
                      value={frequency}
                      onChange={onFrequencyChange}
                      data-cy={`${Contracts.FieldFrequency}-new`}
                    />
                    <Button
                      type="text"
                      icon={<PlusOutlined />}
                      onClick={addItem}
                      data-cy={`${Contracts.FieldFrequency}-btn-add`}
                    >
                      Add new option
                    </Button>
                  </Space>
                </>
              )}
              data-cy={Contracts.FieldFrequency}
            >
              {frequencyOptions.map((value, index) => (
                <Option
                  key={value}
                  value={value}
                  data-cy={`${Contracts.FieldFrequency}-${Contracts.SelectItem}${index}`}
                >
                  {value}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="format"
            label="Format"
            rules={[
              {
                required: true,
                message: 'Please enter a format',
              },
            ]}
          >
            <Input
              placeholder="Enter a format"
              style={{ width: '100%' }}
              disabled={isAddingPlacement}
              data-cy={Contracts.FieldFormat}
            />
          </Form.Item>

          <Form.Item
            name="shareOfVoice"
            label="Share of Voice"
            rules={[
              {
                message: 'Please enter a Share of Voice (Digital only)',
              },
            ]}
          >
            <Input
              placeholder="Enter a Share of Voice (Digital only)"
              style={{ width: '100%' }}
              disabled={isAddingPlacement}
              data-cy={Contracts.FieldShareVoice}
            />
          </Form.Item>

          <Form.Item
            name="weighting"
            label="Classification"
            rules={[
              {
                required: true,
                message: 'Please select a classification',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a classification"
              disabled={isAddingPlacement}
              data-cy={Contracts.FieldWeighting}
            >
              {weightingSections?.map(
                (
                  {
                    id: weightId,
                    data: {
                      name: { currentPhaseValue: weightName },
                      weight: { currentPhaseValue: weight },
                    },
                  },
                  index
                ) => (
                  <Option
                    key={weightId}
                    value={weightId}
                    data-cy={`${Contracts.FieldWeighting}-${Contracts.SelectItem}${index}`}
                  >
                    <Text>{weightName}</Text>
                    <Text type="secondary" style={{ marginLeft: 4 }}>
                      ({weight}%)
                    </Text>
                  </Option>
                )
              )}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

AddPlacementOOH.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default AddPlacementOOH;
