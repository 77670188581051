// eslint-disable-next-line import/no-extraneous-dependencies
import { Contracts } from '@mbdt/shared/datacy';
import { Button } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { downloadContract } from '../../store/actions/contract.actions';

const DownloadRatesButton = () => {
  const [isDownloading, setIsDownloading] = useState(false);
  const { id: contractId, mediaType } = useParams();

  const downloadRates = async () => {
    if (isDownloading) return;
    setIsDownloading(true);
    await downloadContract(contractId, mediaType);
    setIsDownloading(false);
  };

  return (
    <Button
      data-cy={Contracts.CtaDownloadContract}
      type="primary"
      onClick={downloadRates}
      loading={isDownloading}
    >
      Download Contract
    </Button>
  );
};

export default DownloadRatesButton;
