// eslint-disable-next-line import/no-extraneous-dependencies
import { Contracts } from '@mbdt/shared/datacy';
import { Button, Form, Input, InputNumber, Select } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import Text from 'antd/lib/typography/Text';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  contractSectionTypes,
  dropdownOptions,
  stateOptions,
} from '../../constants';
import { ContractUIContext } from '../../containers/Contract/ContractUIContext';
import { addPlacement } from '../../store/actions/contract.actions';

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const AddPlacementNewspaper = ({ isOpen, setIsOpen }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id, mediaType } = useParams();
  const {
    isAddingPlacement,
    data: { sections },
  } = useSelector((state) => state.contract);
  const { activeTab: sectionType } = useContext(ContractUIContext);

  const weightingSections = sections.filter(
    (s) => s.type === contractSectionTypes.weighting
  );

  const handleOk = () => {
    form.resetFields();
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleSubmit = () => {
    const getFullColourLoadValue = (costType) => {
      if (
        costType !== dropdownOptions.costType.Dollar &&
        costType !== dropdownOptions.costType.Percentage
      ) {
        return costType;
      }
      return 0;
    };

    form
      .validateFields()
      .then(async (values) => {
        const distributionDays = form.getFieldValue(['distributionDays']);
        const placementName = form.getFieldValue(['placementName']);
        const weightingId = form.getFieldValue(['weighting']);

        const newPlacementValues = {
          ...values,
          fullColourLoad: getFullColourLoadValue(values.costType),
        };

        const valuesObj = Object.entries(newPlacementValues).reduce(
          (acc, [key, value]) => {
            // Remove publication from object
            if (key === 'weighting') return acc;

            // Convert distributionDays from array to comma-separated string
            if (key === 'distributionDays') {
              return {
                ...acc,
                [key]: {
                  currentPhaseValue: distributionDays.join(', '),
                },
              };
            }

            if (key === 'state') {
              return {
                ...acc,
                [key]: {
                  currentPhaseValue: value.join(', '),
                },
              };
            }

            return {
              ...acc,
              [key]: {
                currentPhaseValue: value,
              },
            };
          },
          {}
        );

        await dispatch(
          addPlacement(
            id,
            mediaType,
            { ...valuesObj, placementName },
            sectionType,
            weightingId,
            handleOk
          )
        );
      })
      .catch((info) => {
        // eslint-disable-next-line no-console
        console.log('Validation Failed:', info);
      });
  };

  return (
    <>
      <Modal
        visible={isOpen}
        title="Add a new placement"
        onCancel={handleCancel}
        destroyOnClose
        forceRender
        maskClosable={false}
        footer={[
          <Button
            key="back"
            onClick={handleCancel}
            data-cy={`${Contracts.AddNewPlacementModal}-${Contracts.PlacementModalCancel}`}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isAddingPlacement}
            onClick={handleSubmit}
            data-cy={`${Contracts.AddNewPlacementModal}-${Contracts.PlacementModalAdd}`}
          >
            Add placement
          </Button>,
        ]}
        data-cy={Contracts.AddNewPlacementModal}
      >
        <Form
          form={form}
          layout="horizontal"
          name="add-placement"
          {...formItemLayout}
        >
          <Form.Item
            name="placementName"
            label="Medium"
            rules={[
              {
                required: true,
                message: 'Please enter a Medium',
              },
            ]}
          >
            <Input
              placeholder="Enter a Medium"
              style={{ width: '100%' }}
              disabled={isAddingPlacement}
              data-cy={Contracts.FieldMedium}
            />
          </Form.Item>

          <Form.Item
            name="state"
            label="State"
            rules={[
              {
                required: true,
                message: 'Please select one or more state',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select one or more state"
              mode="multiple"
              allowClear
              disabled={isAddingPlacement}
              data-cy={Contracts.FieldState}
            >
              {stateOptions?.map((stateName, index) => (
                <Option
                  key={stateName}
                  value={stateName}
                  data-cy={`${Contracts.FieldState}-${Contracts.SelectItem}${index}`}
                >
                  {stateName}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="market"
            label="Market"
            rules={[
              {
                required: true,
                message: 'Please enter market',
              },
            ]}
          >
            <Input
              placeholder="Enter market"
              style={{ width: '100%' }}
              disabled={isAddingPlacement}
              data-cy={Contracts.FieldMarket}
            />
          </Form.Item>

          <Form.Item
            name="distributionDays"
            label="Distribution Days"
            rules={[
              {
                required: true,
                message: 'Please select the distribution days',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select the distribution days"
              mode="multiple"
              allowClear
              disabled={isAddingPlacement}
              data-cy={Contracts.FieldDistributionDays}
            >
              {Object.entries(dropdownOptions.distributionDays).map(
                ([key, value], index) => (
                  <Option
                    key={key}
                    value={key.charAt(0).toUpperCase() + key.slice(1)}
                    data-cy={`${Contracts.FieldDistributionDays}-${Contracts.SelectItem}${index}`}
                  >
                    {value}
                  </Option>
                )
              )}
            </Select>
          </Form.Item>

          <Form.Item
            name="sectionPositioning"
            label="Section / Positioning"
            rules={[
              {
                required: true,
                message: 'Please select a section/positioning',
              },
            ]}
          >
            <Input
              placeholder="Enter the section/positioning"
              style={{ width: '100%' }}
              disabled={isAddingPlacement}
              data-cy={Contracts.FieldSectionPositioning}
            />
          </Form.Item>

          <Form.Item
            name="calculationSize"
            label="Calculation Size"
            rules={[
              {
                required: true,
                message: 'Please select a calculation size',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a calculation size"
              disabled={isAddingPlacement}
              data-cy={Contracts.FieldCalculationSize}
            >
              {Object.entries(dropdownOptions.calculationSize).map(
                ([key, value], index) => (
                  <Option
                    key={key}
                    value={value}
                    data-cy={`${Contracts.FieldCalculationSize}-${Contracts.SelectItem}${index}`}
                  >
                    {value}
                  </Option>
                )
              )}
            </Select>
          </Form.Item>

          <Form.Item
            name="frequency"
            label="Frequency"
            rules={[
              {
                required: true,
                message: 'Please select a frequency',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a frequency"
              disabled={isAddingPlacement}
              data-cy={Contracts.FieldFrequency}
            >
              {Object.entries(dropdownOptions.frequency).map(
                ([key, value], index) => (
                  <Option
                    key={key}
                    value={value}
                    data-cy={`${Contracts.FieldFrequency}-${Contracts.SelectItem}${index}`}
                  >
                    {value}
                  </Option>
                )
              )}
            </Select>
          </Form.Item>

          <Form.Item
            name="distributionMethod"
            label="Distribution Method"
            rules={[
              {
                required: true,
                message: 'Please select a distribution method',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a distribution method"
              disabled={isAddingPlacement}
              data-cy={Contracts.FieldDistributionMethod}
            >
              {Object.entries(dropdownOptions.distributionMethod).map(
                ([key, value], index) => (
                  <Option
                    key={key}
                    value={value}
                    data-cy={`${Contracts.FieldDistributionMethod}-${Contracts.SelectItem}${index}`}
                  >
                    {value}
                  </Option>
                )
              )}
            </Select>
          </Form.Item>

          <Form.Item
            name="height"
            label="Height"
            rules={[
              {
                required: true,
                message: 'Please enter the height',
              },
            ]}
          >
            <InputNumber
              placeholder="Enter the height"
              style={{ width: '100%' }}
              stringMode
              disabled={isAddingPlacement}
              data-cy={Contracts.FieldHeight}
            />
          </Form.Item>

          <Form.Item
            name="width"
            label="Width"
            rules={[
              {
                required: true,
                message: 'Please enter the width',
              },
            ]}
          >
            <InputNumber
              placeholder="Enter the width"
              style={{ width: '100%' }}
              stringMode
              disabled={isAddingPlacement}
              data-cy={Contracts.FieldWidth}
            />
          </Form.Item>
          <Form.Item
            name="weighting"
            label="Classification"
            rules={[
              {
                required: true,
                message: 'Please select a classification',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a classification"
              disabled={isAddingPlacement}
              data-cy={Contracts.FieldWeighting}
            >
              {weightingSections?.map(
                (
                  {
                    id: weightId,
                    data: {
                      name: { currentPhaseValue: weightName },
                      weight: { currentPhaseValue: weight },
                    },
                  },
                  index
                ) => (
                  <Option
                    key={weightId}
                    value={weightId}
                    data-cy={`${Contracts.FieldWeighting}-${Contracts.SelectItem}${index}`}
                  >
                    <Text>{weightName}</Text>
                    <Text type="secondary" style={{ marginLeft: 4 }}>
                      ({weight}%)
                    </Text>
                  </Option>
                )
              )}
            </Select>
          </Form.Item>

          <Form.Item
            name="costType"
            label="Colour Load Type"
            rules={[
              {
                required: true,
                message: 'Please select the Colour Load Type',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select the Colour Load Type"
              allowClear
              disabled={isAddingPlacement}
              data-cy={Contracts.FieldCostType}
            >
              {Object.entries(dropdownOptions.costType).map(
                ([key, value], index) => (
                  <Option
                    key={key}
                    value={key.charAt(0).toUpperCase() + key.slice(1)}
                    data-cy={`${Contracts.FieldCostType}-${Contracts.SelectItem}${index}`}
                  >
                    {value}
                  </Option>
                )
              )}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

AddPlacementNewspaper.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default AddPlacementNewspaper;
