// eslint-disable-next-line import/no-extraneous-dependencies
import { Contracts } from '@mbdt/shared/datacy';
import { Button, Form, Input } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { addWeighting } from '../../store/actions/contract.actions';
import InputPercentage from '../FormInputs/InputPercentage';

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const AddWeightingModal = ({ isOpen, setIsOpen }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id, mediaType } = useParams();
  const { isAddingPlacement } = useSelector((state) => state.contract);

  const handleOk = () => {
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        const valuesObj = Object.entries(values).reduce((acc, [key, value]) => {
          return {
            ...acc,
            [key]: {
              currentPhaseValue: value,
            },
          };
        }, {});

        await dispatch(addWeighting(id, mediaType, valuesObj, handleOk));
      })
      .then(() => form.resetFields())
      .catch((info) => {
        // eslint-disable-next-line no-console
        console.log('Validation Failed:', info);
      });
  };

  return (
    <>
      <Modal
        data-cy={Contracts.ModelClassfication}
        visible={isOpen}
        title="Add classification"
        onCancel={handleCancel}
        destroyOnClose
        forceRender
        footer={[
          <Button
            data-cy={Contracts.BtnCancelClassfication}
            key="back"
            onClick={handleCancel}
          >
            Cancel
          </Button>,
          <Button
            data-cy={Contracts.BtnAddClassfication}
            key="submit"
            type="primary"
            loading={isAddingPlacement}
            onClick={handleSubmit}
          >
            Add classification
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="horizontal"
          name="add-placement"
          {...formItemLayout}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: 'Please enter a name',
              },
            ]}
          >
            <Input
              data-cy={Contracts.InputClassficationName}
              placeholder="Please enter a name"
              style={{ width: '100%' }}
              disabled={isAddingPlacement}
            />
          </Form.Item>

          <Form.Item
            name="weight"
            label="Classification"
            rules={[
              {
                required: true,
                message: 'Please enter a classification',
              },
            ]}
          >
            <InputPercentage
              data-cy={Contracts.InputClassfication}
              placeholder="Please enter a classification"
              style={{
                width: '100%',
              }}
              stringMode
              formatter={null}
              disabled={isAddingPlacement}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

AddWeightingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default AddWeightingModal;
