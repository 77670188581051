import { Button } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Title from 'antd/lib/typography/Title';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { mediaTypes } from '../../constants';
import { fetchUser, setUser } from '../../store/actions/user.actions';
import {
  clearLogoutRedirectURL,
  resetUserLoadingState,
} from '../../store/slices/user.slice';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const {
    token: currentToken,
    isLoading,
    logoutRedirectURL,
  } = useSelector((state) => state.user);

  const handleLogin = () => dispatch(fetchUser());

  useEffect(() => {
    if (currentToken) {
      return history.push(`/contracts/${Object.keys(mediaTypes)[0]}`);
    }

    if (search) {
      const data = queryString.parse(search);

      const {
        username,
        displayName,
        company,
        companyCode,
        userId,
        country,
        email,
        token,
      } = data;

      if (token) {
        dispatch(
          setUser({
            username,
            displayName,
            company,
            companyCode,
            userId,
            country,
            email,
            token,
          })
        );
      }
    }

    return true;
  }, [currentToken]);

  useEffect(() => {
    // If logoutRedirectURL contains a value when the Login Screen mounts, clear it
    if (logoutRedirectURL) {
      dispatch(clearLogoutRedirectURL());
    }

    // Reset user isLoading state if the Login Screen mounts with isLoading set to true
    // Could be due to the SSO flow being interrupted by either an error or the user
    if (isLoading) {
      dispatch(resetUserLoadingState());
    }
  }, []);

  return (
    <div style={{ height: '100vh', display: 'flex', alignItems: 'center' }}>
      <Content
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Title level={2}>Login to Gravity</Title>
        <Button
          size="large"
          type="primary"
          onClick={handleLogin}
          loading={isLoading}
        >
          {!isLoading ? 'Login' : 'Logging in'}
        </Button>
      </Content>
    </div>
  );
};

export default Login;
