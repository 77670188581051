import api from '../../services/api';
import streamApi from '../../services/pdfStreamApi';
import { userToken } from '../selectors';
import { logoutSuccess } from '../slices/user.slice';

const TOKEN_EXPIRY_IN_MILLISECONDS = 28800000; // 8 hours

export default (store) => (next) => (action) => {
  api.session.interceptors.request.use(
    (config) => {
      const state = store.getState();
      const token = userToken(state);

      const tokenCreated = state.user.tokenCreated || undefined;
      let tokenExpired = true;

      if (tokenCreated) {
        const currentTime = new Date().getTime();
        if (currentTime - tokenCreated < TOKEN_EXPIRY_IN_MILLISECONDS) {
          tokenExpired = false;
        }
      } // Note: If tokenCreated is not present, assume the token is invalid

      if (token && !tokenExpired) {
        config.headers.common.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  api.session.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      const { response: { status } = {} } = error;
      if (status === 401) {
        await store.dispatch(logoutSuccess());
      }

      return Promise.reject(error);
    }
  );

  streamApi.session.interceptors.request.use(
    (config) => {
      const state = store.getState();
      const token = userToken(state);

      if (token) {
        config.headers.common.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  streamApi.session.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      const { response: { status } = {} } = error;
      if (status === 401) {
        await store.dispatch(logoutSuccess());
      }

      return Promise.reject(error);
    }
  );

  return next(action);
};
