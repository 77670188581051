import Layout from 'antd/lib/layout/layout';
import PropTypes from 'prop-types';
import React from 'react';

import TopNavigation from '../TopNavigation';

const SiteLayout = ({ children }) => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <TopNavigation />
      <Layout>{children}</Layout>
    </Layout>
  );
};

SiteLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SiteLayout;
