// eslint-disable-next-line import/no-extraneous-dependencies
import Calculations from '@mbdt/shared/calculation';
import { createSelector } from '@reduxjs/toolkit';
import { Statistic } from 'antd';
import Text from 'antd/lib/typography/Text';
import { isNaN } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  bonus,
  displayTypePosition,
  displayTypes,
  displayTypeSymbol,
} from '../../constants';
import { calculate } from '../../helpers';
import {
  selectContractTableData,
  selectContractualBonus,
} from '../../store/selectors';
import PreviousYearData from '../Common/PreviousYearData';

const makeSelectTableData = () =>
  createSelector(
    [selectContractTableData, (_state, recordId) => recordId],
    (tableData, recordId) => tableData.find(({ id }) => id === recordId)
  );

const CalculatedCell = ({
  record,
  fieldName,
  displayTypePos,
  displayType,
  dataIndex,
  handleSave,
  isEditing,
  sectionId,
  showPrevData,
  editable,
  ...restProps
}) => {
  const selectTableData = useMemo(makeSelectTableData, []);
  const tableData = useSelector((state) => selectTableData(state, record.id));
  const [calculatedValue, setCalculatedValue] = useState('');

  const contractualBonus = useSelector(selectContractualBonus);
  useEffect(() => {
    if (record.data[fieldName].key === bonus.contractualBonusYOY) {
      setCalculatedValue(calculate(contractualBonus, fieldName));
      return;
    }
    setCalculatedValue(calculate(tableData, fieldName));
  }, [tableData, contractualBonus]);

  // Format any data that needs some massaging
  const formatData = (valueDisplayType, value) => {
    if (value !== null && value !== '-') {
      switch (valueDisplayType) {
        case displayTypes.money:
        case displayTypes.percentage:
          return Calculations.formatCalculatedValue(value, 2).toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          );
        case displayTypes.integer:
          return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        default:
          return value;
      }
    }
    return '-';
  };

  // Retrieve the previous year data to display in the field
  const renderPreviousYearData = () => {
    return (
      <PreviousYearData
        value={formatData(
          record.data[fieldName].displayType,
          record.data[fieldName].previousYearValue
        )}
        fontSize={11}
        prefix={
          displayTypePos === displayTypePosition.prefix &&
          !isNaN(Number(record.data[fieldName].previousYearValue))
            ? displayTypeSymbol.money
            : undefined
        }
        suffix={
          displayTypePos === displayTypePosition.suffix &&
          !isNaN(Number(record.data[fieldName].previousYearValue))
            ? displayTypeSymbol.percentage
            : undefined
        }
      />
    );
  };

  return (
    <td {...restProps}>
      <div style={{ display: 'flex', flexFlow: 'column wrap' }}>
        <Text>
          <Statistic
            value={calculatedValue !== null ? calculatedValue : '-'}
            precision={2}
            prefix={
              displayTypePos === displayTypePosition.prefix
                ? displayTypeSymbol.money
                : undefined
            }
            suffix={
              displayTypePos === displayTypePosition.suffix &&
              !isNaN(Number(calculatedValue))
                ? displayTypeSymbol.percentage
                : undefined
            }
            thousandSeparator=","
            valueStyle={{ fontSize: 12 }}
          />
        </Text>
        {showPrevData && renderPreviousYearData()}
      </div>
    </td>
  );
};

// TODO: Update these propTypes
/* eslint-disable react/forbid-prop-types */
CalculatedCell.propTypes = {
  editable: PropTypes.bool,
  isEditing: PropTypes.any,
  dataIndex: PropTypes.any,
  title: PropTypes.any,
  displayType: PropTypes.string,
  displayTypePos: PropTypes.string,
  record: PropTypes.any,
  index: PropTypes.any,
  children: PropTypes.any,
  prefix: PropTypes.any,
  handleSave: PropTypes.any,
  fieldName: PropTypes.any,
  sectionId: PropTypes.any,
  showPrevData: PropTypes.bool,
};

export default CalculatedCell;
