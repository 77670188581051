/* eslint-disable consistent-return */
import { InputNumber } from 'antd';
import React from 'react';

import { displayTypeSymbol } from '../../constants';

const InputPercentage = React.memo(({ ...props }) => {
  const renderInputFormatter = (value) => {
    return `${value}${value ? displayTypeSymbol.percentage : ''}`;
  };

  const renderInputParser = (value) => {
    return value.replace('%', '');
  };

  return (
    <InputNumber
      suffix={displayTypeSymbol.percentage}
      formatter={renderInputFormatter}
      parser={renderInputParser}
      {...props}
    />
  );
});

InputPercentage.displayName = 'InputPercentage';

// TODO: Update these propTypes
/* eslint-disable react/forbid-prop-types */
InputPercentage.propTypes = {};

export default InputPercentage;
