import { InputNumber } from 'antd';
import React from 'react';

const InputDecimal = React.memo(({ ...props }) => {
  const renderInputFormatter = (value) => {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const renderInputParser = (value) => {
    return value.replace(/\$\s?|(,*)/g, '');
  };

  return (
    <InputNumber
      formatter={renderInputFormatter}
      parser={renderInputParser}
      {...props}
    />
  );
});

InputDecimal.displayName = 'InputDecimal';

export default InputDecimal;
