// eslint-disable-next-line import/no-extraneous-dependencies
import { Contracts } from '@mbdt/shared/datacy';
import { Button, Dropdown, Menu } from 'antd';
import Text from 'antd/lib/typography/Text';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { mediumStatuses } from '../../constants';
import { publishCheckForChangesEvent } from '../../containers/Contract/ContractUIEvents';
import { updatePlacementStatus } from '../../store/actions/contract.actions';
import { selectVisualCues } from '../../store/selectors';
import { IconAccepted, IconDropdownArrow, IconReview } from '../Icons/Icons';
import { useHasChangesToSave } from '../SavePrompt/SavePrompt';
import StatusIcon from './StatusIcon';
import StatusLabel from './StatusLabel';

const PlacementStatus = ({ status, placementId, disabled, index }) => {
  const { canReviewPlacement } = useSelector(selectVisualCues);
  const [isSaving, setIsSaving] = useState(false);

  const dispatch = useDispatch();
  const { id: resourceId, mediaType } = useParams();

  const hasChanges = useHasChangesToSave();

  const handleSelectOption = async (option) => {
    // User has selected the same status, so do nothing
    if (option === status) return;

    if (hasChanges) {
      publishCheckForChangesEvent();
      return;
    }

    setIsSaving(true);

    // Update placement with new status
    await dispatch(
      updatePlacementStatus(mediaType, resourceId, placementId, option)
    );

    setIsSaving(false);
  };

  const menu = (
    <Menu>
      <Menu.Item
        data-cy={`${Contracts.PlacementStatusApproved}-${index}`}
        style={{ display: 'flex', alignItems: 'center' }}
        onClick={() => handleSelectOption(mediumStatuses.approved)}
      >
        <IconAccepted />
        <Text style={{ marginLeft: 8 }}>{mediumStatuses.approved}</Text>
      </Menu.Item>
      <Menu.Item
        data-cy={`${Contracts.PlacementStatusRejected}-${index}`}
        style={{ display: 'flex', alignItems: 'center' }}
        onClick={() => handleSelectOption(mediumStatuses.rejected)}
      >
        <IconReview />
        <Text style={{ marginLeft: 8 }}>{mediumStatuses.rejected}</Text>
      </Menu.Item>
    </Menu>
  );

  return canReviewPlacement ? (
    <Dropdown overlay={menu} disabled={disabled} trigger={['click']}>
      <Button
        data-cy={`${Contracts.PlacementStatusDropdown}-${index}`}
        className="ant-dropdown-link"
        onClick={(e) => e.preventDefault()}
        loading={isSaving}
        icon={<StatusIcon status={status} />}
        style={{
          border: 'none',
          background: 'none',
          boxShadow: 'none',
          display: 'flex',
          alignItems: 'center',
          padding: 0,
          position: 'relative',
          opacity: isSaving ? 0.5 : 1,
          minHeight: 34,
        }}
      >
        <StatusLabel status={status && mediumStatuses[status.toLowerCase()]} />
        <IconDropdownArrow />
      </Button>
    </Dropdown>
  ) : (
    <div
      data-cy={`${Contracts.PlacementStatusLbl}-${index}`}
      style={{ position: 'relative', display: 'flex', alignItems: 'center' }}
    >
      <StatusIcon status={status} />
      <StatusLabel status={status && mediumStatuses[status.toLowerCase()]} />
    </div>
  );
};

PlacementStatus.propTypes = {
  status: PropTypes.string.isRequired,
  placementId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  index: PropTypes.number,
};

export default PlacementStatus;
