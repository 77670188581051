import _ from 'lodash';
import React from 'react';
import { toast } from 'react-toastify';

import {
  ToastError,
  ToastSuccess,
} from '../../components/Common/ToastMessages';
import {
  contractSectionTypes,
  contractStatuses,
  placementEditTypes,
  placementFields,
} from '../../constants';
import {
  displayGenericErrorMessage,
  isBonusUpdated,
  streamBlobToClient,
} from '../../helpers';
import {
  fetchContractTemplate,
  phaseApproval,
  phaseRollover,
  placementApproval,
  streamContractTemplate,
  submitPhase,
  updateContractTemplate,
} from '../../services/template.service';
import {
  addPlacementFailure,
  addPlacementStart,
  fetchContractFailure,
  fetchContractStart,
  fetchContractSuccess,
  removePlacementsFailure,
  removePlacementsStart,
  removeWeightingFailure,
  removeWeightingStart,
  submitRatesFailure,
  submitRatesStart,
  updateContractFailure,
  updateContractStart,
  updatePlacementFailure,
  updatePlacementStart,
} from '../slices/contract.slice';

export const fetchContract = (id, mediaType) => async (dispatch) => {
  try {
    dispatch(fetchContractStart());

    const { data } = await fetchContractTemplate(mediaType, id);
    dispatch(fetchContractSuccess(data));
  } catch (error) {
    dispatch(fetchContractFailure(error));
    toast(
      <ToastError message="Looks like there was an issue retrieving this contract. Please try again or contact support." />,
      { autoClose: 7000 }
    );
  }
};

export const updateContract = (id, mediaType) => async (dispatch, getState) => {
  try {
    dispatch(updateContractStart());

    const {
      contract: {
        data: { sections },
        tableData,
        bonus,
      },
    } = getState();

    const prevSections = sections.filter(({ type }) => type !== 'overview');
    const body = [];

    // Loop over each table row to check for any updates made by the user
    tableData.forEach((row) => {
      // Retrieve the section's previous values
      const prevSectionRow = prevSections.find(
        ({ id: sectionId }) => sectionId === row.id
      );

      // Compare the current section data with the previous section data
      // to determine the adjustments made by the user
      const adjustments = _.reduce(
        prevSectionRow.data,
        (result, value, key) => {
          // If value is an object, we can assume the user may have updated
          // the currentPhaseValue
          if (typeof value === 'object' && value !== null) {
            // Exclude unupdate fields or when field is Radio costPerThousand (not included in API payload since it's a calculated value)
            if (
              _.isEqual(
                value.currentPhaseValue,
                row.data[key].currentPhaseValue
              ) ||
              key === placementFields.radio.costPerThousand
            ) {
              return result;
            }
            // force costType to be included in the patch if fullColourLoad is being edited
            // The API requires this to ensure the correct data validation can be applied for fullColourCost based on the costType.
            if (key === 'fullColourLoad') {
              result.costType = {
                currentPhaseValue: row.data.costType.currentPhaseValue,
              };
            }
            return {
              ...result,
              [key]: { currentPhaseValue: row.data[key].currentPhaseValue },
            };
          }

          return result;
        },
        {}
      );

      // If updates have been made in the contract table, add operation the patch body
      if (!_.isEmpty(adjustments)) {
        body.push({
          operation: 'replace',
          type: row.type,
          id: row.id,
          adjustments,
        });
      }
    });

    // Check if the bonus data is updated and push the changes to the body array
    if (isBonusUpdated(bonus, sections)) {
      const overview = sections.find(({ type }) => type === 'overview');
      body.push({
        operation: 'replace',
        type: bonus.type,
        id: overview.id,
        adjustments: {
          contractualBonus: {
            currentPhaseValue: bonus.data.contractualBonus?.currentPhaseValue
              ? Number(bonus.data.contractualBonus?.currentPhaseValue)
              : null,
          },
        },
      });
    }

    // User has not made any updates to the table data
    if (!body.length) return;

    // Changes have been identified and pushed to the body array
    const payload = await updateContractTemplate(mediaType, id, body);

    dispatch(fetchContractSuccess(payload.data));
    toast(<ToastSuccess message="Rates have been saved." />);
  } catch (error) {
    dispatch(updateContractFailure(error));
    toast(
      <ToastError message="Looks like there was an issue updating the rates. Please try again or contact support." />,
      { autoClose: 7000 }
    );
  }
};

export const submitRates = (mediaType, id) => async (dispatch) => {
  try {
    dispatch(submitRatesStart());

    // Submit phase
    const { data } = await submitPhase(mediaType, id);

    dispatch(fetchContractSuccess(data));
    toast(<ToastSuccess message="Rates have been submitted for review." />, {
      autoClose: 7000,
    });
  } catch (error) {
    dispatch(submitRatesFailure(error?.response?.data?.details));
    displayGenericErrorMessage(error);
  }
};

/**
 * Commence a negotiation by transitioning it from Round 0 (Draft) to Round 1 (Revision)
 * @param {String} mediaType
 * @param {String} id
 */
export const rolloverPhase = (mediaType, id) => async (dispatch, getState) => {
  try {
    dispatch(submitRatesStart());

    const {
      contract: {
        data: { phase },
      },
    } = getState();

    const { data } = await phaseRollover(mediaType, id);

    dispatch(fetchContractSuccess(data));

    if (phase === 0) {
      toast(
        <ToastSuccess message="The contract is now active and the negotiation is underway." />,
        { autoClose: 7000 }
      );
    } else {
      toast(
        <ToastSuccess message="A new negotiation round has been created." />,
        {
          autoClose: 7000,
        }
      );
    }
  } catch (error) {
    displayGenericErrorMessage(error);
    dispatch(fetchContractFailure(error));
  }
};

/**
 * Update the status of a placement
 * @param {String} mediaType - The type of media
 * @param {String} resourceId - The resource ID
 * @param {String|Array<String>} placementIds - The ID(s) of the placement(s) to update
 * @param {String} action - The action to perform on the placements
 * @param {Function} [callback=null] - Optional callback function to be called on success or error
 * @returns {Function} A thunk function that dispatches actions
 */
export const updatePlacementStatus =
  (mediaType, resourceId, placementIds, action, callback = null) =>
  async (dispatch) => {
    try {
      dispatch(submitRatesStart());
      // Ensure placementIds is always an array
      const idsArray = Array.isArray(placementIds)
        ? placementIds
        : [placementIds];

      // Create the body array
      const body = idsArray.map((placementId) => ({
        action,
        placementId,
      }));

      const { data } = await placementApproval(mediaType, resourceId, body);

      dispatch(fetchContractSuccess(data));

      // Call the callback function with success status
      if (typeof callback === 'function') {
        callback(true);
        toast(
          <ToastSuccess
            message={`Selected placements have been successfully updated to ${action} status.`}
          />
        );
      }
    } catch (error) {
      dispatch(fetchContractFailure(error));
      toast(
        <ToastError message="Looks like there was an issue starting the negotiation. Please try again or contact support." />,
        { autoClose: 7000 }
      );
      // Call the callback function with error status
      if (typeof callback === 'function') {
        callback(false, error);
      }
    }
  };

/**
 * Approve or reject the contract.
 * Approving the contract will lock the contract.
 * Rejecting the contract will send it back to the Agency for Review
 * @param {String} mediaType
 * @param {String} resourceId
 * @param {String} action
 */
export const approvePhase =
  (mediaType, resourceId, action) => async (dispatch) => {
    try {
      dispatch(submitRatesStart());

      const body = {
        action,
      };

      const { data } = await phaseApproval(mediaType, resourceId, body);

      dispatch(fetchContractSuccess(data));

      if (action === contractStatuses.approved) {
        toast(
          <ToastSuccess message="Contract has been approved and is now locked." />
        );
      }

      if (action === contractStatuses.rejected) {
        toast(
          <ToastSuccess message="Contract has been sent back for review." />,
          {
            autoClose: 7000,
          }
        );
      }
    } catch (error) {
      dispatch(fetchContractFailure(error));
    }
  };

/**
 * Add a placement to a contract
 * @param {String} mediaType
 * @param {Object} fields
 * @returns
 */
export const addPlacement =
  (contractId, mediaType, fields, sectionType, weightingId = null, callback) =>
  async (dispatch) => {
    try {
      dispatch(addPlacementStart());

      const body = [
        {
          operation: 'add',
          type: sectionType, // Note: Type will differ based on mediaType
          adjustments: {
            ...(weightingId !== null ? { weightingId } : {}), // Only add weightingId when it's not null
            ...fields,
          },
        },
      ];

      const { data } = await updateContractTemplate(
        mediaType,
        contractId,
        body,
        placementEditTypes.ADD_PLACEMENT_DIALOG
      );

      dispatch(fetchContractSuccess(data));

      toast(
        <ToastSuccess message="A new placement has been added to this contract." />,
        { autoClose: 7000 }
      );

      return callback();
    } catch (error) {
      dispatch(addPlacementFailure(error));
      return toast(
        <ToastError message="Looks like there was an issue adding a placement. Please try again or contact support." />,
        { autoClose: 7000 }
      );
    }
  };

/**
 * Update a placement to a contract
 * @param {String} mediaType
 * @param {Object} fields
 * @returns
 */
export const updatePlacement =
  (
    contractId,
    mediaType,
    placementId,
    fields,
    sectionType,
    weightingId = null,
    callback
  ) =>
  async (dispatch) => {
    try {
      dispatch(updatePlacementStart());

      const body = [
        {
          operation: 'replace',
          type: sectionType, // Note: Type will differ based on mediaType
          id: placementId,
          adjustments: {
            ...(weightingId !== null ? { weightingId } : {}), // Only add weightingId when it's not null
            ...fields,
          },
        },
      ];

      const { data } = await updateContractTemplate(
        mediaType,
        contractId,
        body,
        placementEditTypes.EDIT_PLACEMENT_DIALOG
      );

      dispatch(fetchContractSuccess(data));

      toast(
        <ToastSuccess message="Placement has been succesfully updated." />,
        {
          autoClose: 7000,
        }
      );

      return callback();
    } catch (error) {
      dispatch(updatePlacementFailure(error));
      return toast(
        <ToastError message="Looks like there was an issue updating a placement. Please try again or contact support." />,
        { autoClose: 7000 }
      );
    }
  };

/**
 * Remove placement from a contract
 * @param {String} contractId
 * @param {String} mediaType
 * @param {Object} sectionIds
 * @returns
 */
export const removePlacements =
  (contractId, mediaType, sectionType, sectionIds, comment, callback) =>
  async (dispatch) => {
    try {
      dispatch(removePlacementsStart());

      const body = sectionIds.map((sectionId) => ({
        operation: 'remove',
        type: sectionType, // Note: Type will differ based on mediaType
        id: sectionId,
        adjustments: {
          retiredComments: {
            currentPhaseValue: comment,
          },
        },
      }));

      const { data } = await updateContractTemplate(
        mediaType,
        contractId,
        body,
        placementEditTypes.RETIRE_DIALOG
      );

      dispatch(fetchContractSuccess(data));

      toast(
        <ToastSuccess message="Placement(s) have been removed from this contract." />
      );

      return callback();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Failed to remove placements: ', error);
      dispatch(removePlacementsFailure(error));
      return toast(
        <ToastError message="Looks like there was an issue removing placements. Please try again or contact support." />,
        { autoClose: 7000 }
      );
    }
  };

/**
 * Add weighting to a contract
 * @param {String} contractId
 * @param {String} mediaType
 * @param {Object} fields
 * @returns
 */
export const addWeighting =
  (contractId, mediaType, fields, callback) => async (dispatch) => {
    try {
      dispatch(addPlacementStart());

      const body = [
        {
          operation: 'add',
          type: contractSectionTypes.weighting, // Note: Type will differ based on mediaType
          adjustments: {
            ...fields,
          },
        },
      ];

      const { data } = await updateContractTemplate(
        mediaType,
        contractId,
        body
      );

      dispatch(fetchContractSuccess(data));

      toast(
        <ToastSuccess message="Classification has been added to this contract." />,
        {
          autoClose: 7000,
        }
      );

      return callback();
    } catch (error) {
      dispatch(addPlacementFailure(error));
      return toast(
        <ToastError message="Looks like there was an issue adding classification. Please try again or contact support." />,
        { autoClose: 7000 }
      );
    }
  };

/**
 * Remove weighting from a contract
 * @param {String} contractId
 * @param {String} mediaType
 * @param {Object} sectionIds
 * @returns
 */
export const removeWeighting =
  (contractId, mediaType, sectionIds) => async (dispatch) => {
    try {
      dispatch(removeWeightingStart());

      const body = sectionIds.map((sectionId) => ({
        operation: 'remove',
        type: contractSectionTypes.weighting, // Note: Type will differ based on mediaType
        id: sectionId,
      }));

      const { data } = await updateContractTemplate(
        mediaType,
        contractId,
        body
      );

      dispatch(fetchContractSuccess(data));

      toast(
        <ToastSuccess message="Classification has been removed from this contract." />
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Failed to remove weighting: ', error);
      dispatch(removeWeightingFailure(error));
      return toast(
        <ToastError message="Looks like there was an issue removing classification. Please try again or contact support." />,
        { autoClose: 7000 }
      );
    }
  };

export const updateWeighting =
  (id, mediaType) => async (dispatch, getState) => {
    try {
      dispatch(updateContractStart());

      const {
        contract: {
          data: { sections },
          tableData,
        },
      } = getState();

      const prevSections = sections.filter(({ type }) => type === 'weighting');
      const body = [];

      // Loop over each table row to check for any updates made by the user
      tableData.forEach((row) => {
        // Retrieve the section's previous values
        const prevSectionRow = prevSections.find(
          ({ id: sectionId }) => sectionId === row.id
        );

        // Compare the current section data with the previous section data
        // to determine the adjustments made by the user
        if (!_.isEmpty(prevSectionRow)) {
          const adjustments = _.reduce(
            prevSectionRow.data,
            (result, value, key) => {
              // If value is an object, we can assume the user may have updated
              // the currentPhaseValue
              if (typeof value === 'object' && value !== null) {
                return _.isEqual(
                  value.currentPhaseValue,
                  row.data[key].currentPhaseValue
                )
                  ? result
                  : {
                      ...result,
                      [key]: {
                        currentPhaseValue: row.data[key].currentPhaseValue,
                      },
                    };
              }

              return result;
            },
            {}
          );
          // If updates have been made in the contract table, add operation the patch body
          if (!_.isEmpty(adjustments)) {
            body.push({
              operation: 'replace',
              type: row.type,
              id: row.id,
              adjustments,
            });
          }
        }
      });

      // User has not made any updates to the table data
      if (!body.length) return;

      // Changes have been identified and pushed to the body array
      const payload = await updateContractTemplate(mediaType, id, body);

      dispatch(fetchContractSuccess(payload.data));
      toast(<ToastSuccess message="Classification has been saved." />);
    } catch (error) {
      dispatch(updateContractFailure(error));
      toast(
        <ToastError message="Looks like there was an issue updating the classification. Please try again or contact support." />,
        { autoClose: 7000 }
      );
    }
  };

export const downloadContract = async (id, mediaType) => {
  try {
    const response = await streamContractTemplate(mediaType, id);

    const filename = response.headers['content-disposition']
      .split(';')
      .find((n) => n.includes('filename='))
      .replace('filename=', '')
      .trim();

    streamBlobToClient(response.data, filename);
  } catch (error) {
    toast(
      <ToastError message="Looks like there was an issue downloading this contract. Please try again or contact support." />,
      { autoClose: 7000 }
    );
  }
};
