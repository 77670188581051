import './ContractHeader.less';

import { InfoCircleOutlined } from '@ant-design/icons';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Contracts } from '@mbdt/shared/datacy';
import { Button, Col, Row, Space } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { createRef, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ContractUIContext } from '../../containers/Contract/ContractUIContext';
import { contractOverviewColumns } from '../../data';
import useWindowSize from '../../hooks/useWindowSize';
import Actions from '../Actions/Actions';
import CustomModal from '../Modal/Modal';
import ContractBreadcrumbs from './ContractBreadcrumbs';
import ContractLabel from './ContractLabel';
import OverviewTable from './OverviewTable';
import YearlyRates from './YearlyRates';

const ContractHeader = () => {
  const [toggleOverviewModal, setToggleOverviewModal] = useState(false);
  const ref = createRef();
  const { contractHeaderHeight, setContractHeaderHeight } =
    useContext(ContractUIContext);

  const { mediaType: mediaTypeParam } = useParams();

  const windowSize = useWindowSize();

  const {
    data: {
      masterNetwork = '',
      network = '',
      mediaType = '',
      mediaSubType = '',
      year = '',
    },
    overview = {},
  } = useSelector((state) => state.contract);

  useEffect(() => {
    if (ref.current && ref.current.offsetHeight !== contractHeaderHeight) {
      setContractHeaderHeight(ref.current.offsetHeight);
    }
  }, [windowSize]);

  return (
    <div
      style={{
        height: 'auto',
        padding: '24px 24px 0 24px',
        background: 'white',
        lineHeight: 'initial',
      }}
      ref={ref}
      className="contract-header"
    >
      <Row>
        <Col span={11}>
          <ContractBreadcrumbs
            activeMediaType={mediaType}
            activeMediaTypePath={mediaTypeParam}
          />
          <div
            style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}
          >
            <Title
              level={4}
              style={{
                display: 'inline-flex',
                marginBottom: 0,
                marginRight: 16,
              }}
              data-cy={Contracts.HeaderMediaSubType}
            >
              {mediaSubType}
            </Title>
            <Button
              type="text"
              style={{ display: 'flex', alignItems: 'center', fontSize: 13 }}
              icon={
                <InfoCircleOutlined
                  style={{ fontSize: 16, display: 'inline-flex' }}
                />
              }
              onClick={() => setToggleOverviewModal(true)}
              data-cy={Contracts.CtaHeaderOverview}
            >
              Overview
            </Button>
          </div>
          <Space
            size={32}
            style={{
              opacity: 0.7,
              fontSize: 13,
              marginBottom: 8,
              marginTop: 8,
            }}
          >
            {mediaType && (
              <ContractLabel
                dataCy={Contracts.HeaderMediaType}
                label="Media Type"
                text={mediaType}
              />
            )}
            {masterNetwork && (
              <ContractLabel
                dataCy={Contracts.HeaderMasterNetwork}
                label="Master Network"
                text={masterNetwork}
              />
            )}
            {network && (
              <ContractLabel
                dataCy={Contracts.HeaderNetwork}
                label="Network"
                text={network}
              />
            )}
            {year && (
              <ContractLabel
                dataCy={Contracts.HeaderYear}
                label="Year"
                text={year}
              />
            )}
          </Space>
        </Col>
        <Col
          span={13}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
          }}
        >
          <Space
            direction="vertical"
            size={20}
            style={{ flexShrink: 0, alignItems: 'flex-end' }}
          >
            <Actions />
            <YearlyRates />
          </Space>
        </Col>
      </Row>

      {/* Overview Modal */}
      <CustomModal
        isOpen={toggleOverviewModal}
        handleCloseModal={setToggleOverviewModal}
        bodyStyle={{ padding: 0 }}
        footer={null}
        width={600}
      >
        <OverviewTable
          mediaType={mediaType}
          dataSource={overview.data}
          columns={contractOverviewColumns[mediaTypeParam]}
        />
      </CustomModal>
    </div>
  );
};

export default ContractHeader;
