import './ContractTable.less';

// eslint-disable-next-line import/no-extraneous-dependencies
import { Contracts } from '@mbdt/shared/datacy';
import { createSelector } from '@reduxjs/toolkit';
import { Form } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  columnWidths,
  MIN_ROWS_FOR_BULK_ACTION,
  placementEditTypes,
  placementTypes,
} from '../../constants';
import { ContractUIContext } from '../../containers/Contract/ContractUIContext';
import {
  useCheckForChangesNotification,
  useDiscardChangesNotification,
} from '../../containers/Contract/ContractUIEvents';
import { useContractForm } from '../../context/contractForm-context';
import { contractTableColumns } from '../../data';
import { renderDisplayTypePosition } from '../../helpers';
import {
  selectContractSections,
  selectVisualCues,
} from '../../store/selectors';
import { updateSectionDataSuccess } from '../../store/slices/contract.slice';
import SelectCheckbox from '../FormInputs/SelectCheckbox';
import { SavePrompt, useHasChangesToSave } from '../SavePrompt/SavePrompt';
import TemplateTable from '../TemplateTable/TemplateTable';

const makeSelectTableDataBySectionType = () =>
  createSelector(
    [selectContractSections, (_state, sectionType) => sectionType],
    (sections, sectionType) =>
      sections
        .filter(({ type }) => type === sectionType)
        .map((data, idx) => {
          const { data: placementData } = data;
          const formattedFields = {};
          _.forEach(placementData, (value, fieldName) => {
            if (value.editTypes) {
              formattedFields[fieldName] = {
                ...value,
                // set editable value on table
                editable:
                  _.includes(
                    value.editTypes,
                    placementEditTypes.EDIT_PLACEMENT_INLINE
                    // disable edit for `Retired` placements
                  ) &&
                  !(placementData.placementType === placementTypes.RETIRED),
              };
            } else {
              formattedFields[fieldName] = value;
            }
          });

          // update data with formatted fields
          data = {
            ...data,
            data: formattedFields,
          };

          return { key: data?.id || idx, ...data };
        })
  );

const ContractTableWrapper = ({ sectionId, sectionType }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const formContext = useContractForm();

  const [promptCheck, setPromptCheck] = useState(false);
  const [displaySavePrompt, setDisplaySavePrompt] = useState(false);
  const { canRemovePlacement, canEditPlacement, canReviewPlacement } =
    useSelector(selectVisualCues);
  const selectTableDataBySectionType = useMemo(
    makeSelectTableDataBySectionType,
    []
  );
  const { contractTableHeaderHeight, tableHeight } =
    useContext(ContractUIContext);

  const hasChanges = useHasChangesToSave();

  // Prevent this from trigger re-renders in the ant design table
  const tableData = useSelector(
    (state) => selectTableDataBySectionType(state, sectionType),
    (state, prevState) => _.isEqual(state, prevState)
  );

  const handleSave = async (row) => {
    await dispatch(updateSectionDataSuccess(row));
  };

  const mapColumns = (col) => {
    const newCol = {
      ...col,
      onHeaderCell: (header) => ({
        'data-cy': `${Contracts.ContractHeaderColumn}${
          header?.key || header?.title || ''
        }`,
      }),
      onCell: (record, index) => {
        const columnId = col.key ?? col.name ?? col.title ?? 'actions';
        return {
          record: {
            ...record,
            dataCy: `${Contracts.PlacementEditCell}${index}-${col.key}`,
          },
          displayType: record?.data[col.key]?.displayType,
          displayTypePos: renderDisplayTypePosition(
            record?.data[col.key]?.displayType
          ),
          isCalculatedCell: !!record?.data[col.key]?.function,
          dataIndex: col.dataIndex,
          'data-cy': `${columnId}-${index}`,
          title: col.title,
          required: record?.data[col.key]?.required,
          editable: record?.data[col.key]?.editable,
          handleSave,
          isEditing: !!(record?.data[col.key]?.editable && canEditPlacement),
          fieldName: col.key,
          sectionId,
          showPrevData: col.showPrevData,
        };
      },
    };

    if (col.children) {
      newCol.children = col.children.map(mapColumns);
    }

    return newCol;
  };

  const rowSelection = useMemo(() => {
    if (!canReviewPlacement) {
      return null;
    }
    const filteredData = tableData.filter(
      ({ data }) => data?.placementType !== placementTypes.RETIRED
    );
    if (filteredData.length < MIN_ROWS_FOR_BULK_ACTION) {
      return null;
    }

    return {
      fixed: 'left',
      width: columnWidths.checkbox,
      name: 'select',
      key: 'checkbox',
      title: () => (
        <SelectCheckbox
          id="selectAll"
          tableData={filteredData}
          dataCy="checkbox-all"
        />
      ),
      render: (record, status, index) => (
        <SelectCheckbox
          id={record.id}
          disabled={record?.data?.placementType === placementTypes.RETIRED}
          tableData={filteredData}
          dataCy={`checkbox-${index}`}
        />
      ),
    };
  }, [tableData, canEditPlacement, canRemovePlacement]);

  const columns = useMemo(() => {
    const mappedColumns = [
      ...(rowSelection ? [rowSelection] : []),
      ...contractTableColumns[sectionType],
    ].map(mapColumns);

    if (!canRemovePlacement) {
      if (rowSelection) {
        // Intentionally remove second column if rowSelection is there
        const [colSelect, colActions, ...otherCols] = mappedColumns;
        return [colSelect, ...otherCols];
      }
      // Intentionally remove first column if rowSelection is not there
      // eslint-disable-next-line no-unused-vars
      const [removeButtons, ...cols] = mappedColumns;
      return cols;
    }
    return mappedColumns;
  }, [canEditPlacement, canRemovePlacement, rowSelection]);

  const ResetFields = () => {
    form.resetFields();
  };

  useEffect(() => {
    formContext.dispatch({
      type: 'addForm',
      payload: {
        [sectionId]: form,
      },
    });
  }, []);

  useEffect(() => {
    setDisplaySavePrompt(hasChanges);
    if (promptCheck) setPromptCheck(false);
  }, [promptCheck]);

  useCheckForChangesNotification(() => {
    setPromptCheck(true);
  });
  useDiscardChangesNotification(ResetFields);

  return (
    <Form form={form} component={false}>
      {displaySavePrompt && hasChanges ? (
        <SavePrompt
          show={displaySavePrompt}
          onSavePromptClose={() => {
            setDisplaySavePrompt(false);
          }}
        />
      ) : null}
      <TemplateTable
        tableData={tableData}
        columns={columns}
        tableHeight={tableHeight}
        tableHeaderHeight={contractTableHeaderHeight}
      />
    </Form>
  );
};

ContractTableWrapper.propTypes = {
  sectionId: PropTypes.string,
  sectionType: PropTypes.string,
};

export default ContractTableWrapper;
