import './App.less';

import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import ProtectedRoute from '../../components/ProtectedRoute';
import { routes } from '../../config';
import { mediaTypes } from '../../constants';
import Contract from '../Contract/Contract';
import Contracts from '../Contracts/Contracts';
import Dashboard from '../Dashboard';
import Login from '../Login';
import Logout from '../Logout';

const App = () => {
  return (
    <Router>
      <Switch>
        <ProtectedRoute exact path={routes.DASHBOARD}>
          <Dashboard />
        </ProtectedRoute>
        <ProtectedRoute exact path={`${routes.CONTRACTS}`}>
          <Redirect
            to={`${routes.CONTRACTS}/${mediaTypes.newspapers.toLowerCase()}`}
          />
        </ProtectedRoute>
        <ProtectedRoute exact path={`${routes.CONTRACTS}/:mediaType`}>
          <Contracts />
        </ProtectedRoute>
        <ProtectedRoute exact path={`${routes.CONTRACTS}/:mediaType/:id`}>
          <Contract />
        </ProtectedRoute>
        <Route exact path={routes.LOGIN}>
          <Login />
        </Route>
        <Route exact path={routes.LOGOUT}>
          <Logout />
        </Route>
        <Route
          exact
          path={routes.NOT_FOUND}
          render={() => <div>Not Found</div>}
        />
      </Switch>
    </Router>
  );
};

export default App;
