import './TopNavigation.less';

// eslint-disable-next-line import/no-extraneous-dependencies
import { Dashboard } from '@mbdt/shared/datacy';
import { Menu } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { routes } from '../../config';
import Logo from '../Logo';
import AccountDropdown from './AccountDropdown';

const TopNavigation = () => {
  const location = useLocation();
  const activeMenu = location.pathname.split('/')[1];

  return (
    <Header className="top-navigation">
      <NavLink to="/">
        <Logo />
      </NavLink>
      <Menu
        className="navbar"
        mode="horizontal"
        defaultSelectedKeys={[`/${activeMenu}`]}
      >
        <Menu.Item key={routes.CONTRACTS}>
          <NavLink to={routes.CONTRACTS} data-cy={Dashboard.DashboardContract}>
            Contracts
          </NavLink>
        </Menu.Item>
      </Menu>

      <AccountDropdown />
    </Header>
  );
};

export default TopNavigation;
