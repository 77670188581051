import { Badge } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsContractInReview } from '../../store/selectors';

export const EditedBadge = () => {
  const isContractInReview = useSelector(selectIsContractInReview);
  return isContractInReview ? (
    <Badge
      className="site-badge-count-109"
      count="Edited"
      style={{
        backgroundColor: '#66b5ff',
        fontSize: '8px',
        padding: '0px 4px',
        height: '12px',
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
      }}
    />
  ) : (
    <></>
  );
};
