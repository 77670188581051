// eslint-disable-next-line import/no-extraneous-dependencies
import { Dashboard } from '@mbdt/shared/datacy';
import { Menu } from 'antd';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { routes } from '../../config';
import { mediaTypes } from '../../constants';

const MediaTypeMenu = () => {
  const { mediaType } = useParams();
  const [defaultSelectedKeys] = [mediaType];
  const history = useHistory();

  const handleClick = ({ key }) => history.push(`${routes.CONTRACTS}/${key}`);

  return (
    <div>
      <Title
        level={5}
        style={{ margin: '40px 0 16px 24px' }}
        data-cy={Dashboard.DashboardMediaTypeTitle}
      >
        Media Type
      </Title>
      <Menu
        mode="inline"
        defaultSelectedKeys={defaultSelectedKeys}
        style={{ height: '100%', borderRight: 0 }}
        onClick={handleClick}
      >
        {Object.entries(mediaTypes).map(([key, value]) => (
          <Menu.Item key={key} data-cy={`${Dashboard.DahsboardSideMenu}${key}`}>
            {value}
          </Menu.Item>
        ))}
      </Menu>
    </div>
  );
};

export default MediaTypeMenu;
