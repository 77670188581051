import './styles.css';

// eslint-disable-next-line import/no-extraneous-dependencies
import { Contracts } from '@mbdt/shared/datacy';
import { Button, Dropdown, Menu } from 'antd';
import Text from 'antd/lib/typography/Text';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  allMedumStatues,
  MIN_ROWS_FOR_BULK_ACTION,
  placementTypes,
} from '../../constants';
import { updatePlacementStatus } from '../../store/actions/contract.actions';
import {
  selectContractSections,
  selectSelectedPlacementRows,
  selectVisualCues,
} from '../../store/selectors';
import { setSelectedPlacementRows } from '../../store/slices/contract.slice';
import { IconAccepted, IconDropdownArrow, IconReview } from '../Icons/Icons';
import StatusLabel from './StatusLabel';

const filterPlacements = (contractSections, selectedPlacementRows = []) => {
  const selectedPlacements = contractSections?.filter(
    ({ type, id }) =>
      type?.includes('placements') && selectedPlacementRows?.includes(id)
  );

  const editablePlacements = contractSections?.filter(
    ({ data, type }) =>
      type?.includes('placements') &&
      data?.placementType !== placementTypes.RETIRED
  );

  return { selectedPlacements, editablePlacements };
};

const AllPlacementStatus = ({ status = 'default' }) => {
  const { canReviewPlacement } = useSelector(selectVisualCues);
  const [isSaving, setIsSaving] = useState(false);
  const selectedPlacementRows = useSelector(selectSelectedPlacementRows);
  const contractSections = useSelector(selectContractSections);

  const { id: resourceId, mediaType } = useParams();

  const dispatch = useDispatch();

  const { selectedPlacements, editablePlacements } = useMemo(() => {
    return filterPlacements(contractSections, selectedPlacementRows);
  }, [contractSections, selectedPlacementRows]);

  const isAllApproved = useMemo(() => {
    return selectedPlacements?.every(
      (item) => item.data.status === allMedumStatues.approved
    );
  }, [selectedPlacements]);

  const isAllRejected = useMemo(() => {
    return selectedPlacements?.every(
      (item) => item.data.status === allMedumStatues.rejected
    );
  }, [selectedPlacements]);

  const handleSelectOption = async (selectedStatus) => {
    const updatedPlacements = selectedPlacements
      .filter((item) => item.data.status !== selectedStatus)
      .map((item) => item.id);

    setIsSaving(true);

    // Update placement with new status
    await dispatch(
      updatePlacementStatus(
        mediaType,
        resourceId,
        updatedPlacements,
        selectedStatus,
        (isSuccess) => {
          if (isSuccess) {
            dispatch(setSelectedPlacementRows([]));
          }
        }
      )
    );
    setIsSaving(false);
  };

  const hasPlacementNotSelected = !(selectedPlacementRows?.length > 1);
  const disableApproveAll = hasPlacementNotSelected || isAllApproved;
  const disableRejectAll = hasPlacementNotSelected || isAllRejected;
  const enableDropdown =
    editablePlacements?.length >= MIN_ROWS_FOR_BULK_ACTION &&
    canReviewPlacement;

  const menuItems = [
    {
      key: 'approve',
      disabled: disableApproveAll,
      icon: <IconAccepted color={disableApproveAll ? 'gray' : undefined} />,
      text: allMedumStatues.approved,
      onClick: () => handleSelectOption(allMedumStatues.approved),
    },
    {
      key: 'reject',
      disabled: disableRejectAll,
      icon: <IconReview color={disableRejectAll ? 'gray' : undefined} />,
      text: allMedumStatues.rejected,
      onClick: () => handleSelectOption(allMedumStatues.rejected),
    },
  ];

  const menu = (
    <Menu className="option-menu">
      {menuItems.map(({ key, disabled, icon, text, onClick }) => (
        <Menu.Item
          data-cy={`item-${text}`}
          key={key}
          disabled={disabled}
          className={`option-item ${disabled ? 'option-disabled' : ''}`}
          onClick={onClick}
        >
          {icon}
          <Text style={{ marginLeft: 8 }}>{text}</Text>
        </Menu.Item>
      ))}
    </Menu>
  );

  const isDropdownDisabled = isSaving || hasPlacementNotSelected;

  return (
    <>
      {enableDropdown && (
        <div
          className={`dropdown-container ${
            isDropdownDisabled ? 'dropdown-disabled' : ''
          }`}
          data-cy={`${Contracts.PlacementStatusDropdown}-common`}
        >
          <Dropdown
            overlay={menu}
            disabled={isDropdownDisabled}
            trigger={['click']}
            overlayStyle={{ left: '70px' }}
          >
            <Button
              className="ant-dropdown-link option-button"
              onClick={(e) => e.preventDefault()}
              loading={isSaving}
              style={{
                opacity: isSaving ? 0.5 : 1,
              }}
            >
              <StatusLabel
                status={status && allMedumStatues[status.toLowerCase()]}
              />
              <IconDropdownArrow
                colour={isDropdownDisabled ? '#00000040' : undefined}
              />
            </Button>
          </Dropdown>
        </div>
      )}
    </>
  );
};

AllPlacementStatus.propTypes = {
  status: PropTypes.string,
};

export default AllPlacementStatus;
