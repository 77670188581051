// eslint-disable-next-line import/no-extraneous-dependencies
import { Contracts } from '@mbdt/shared/datacy';
import { Button } from 'antd';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { contractStatuses } from '../../constants';
import { ContractUIContext } from '../../containers/Contract/ContractUIContext';
import { useSaveChangesNotification } from '../../containers/Contract/ContractUIEvents';
import { useContractForm } from '../../context/contractForm-context';
import { isBonusUpdated } from '../../helpers';
import { updateContract } from '../../store/actions/contract.actions';

const SaveButton = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const dispatch = useDispatch();
  const {
    isSaving,
    tableData,
    data: { sections = [], status },
    bonus,
  } = useSelector((state) => state.contract);
  const { id: contractId, mediaType } = useParams();
  const {
    activeTab: currentTab,
    setDataHasChanged,
    setHasValidationErrors,
  } = useContext(ContractUIContext);
  const formContext = useContractForm();

  // Update rates when user clicks on the 'Save rates' button
  const handleSaveRates = async () => {
    dispatch(updateContract(contractId, mediaType));
  };

  // Detect when a value has been updated in the placements table, and enable the Save button so the
  // user is able to save the updated data
  const handleIsDataDirty = () => {
    const filteredSections = sections.filter(({ type }) => type !== 'overview');
    if (
      _.isEqual(filteredSections, tableData) &&
      !isBonusUpdated(bonus, sections)
    ) {
      setDataHasChanged(false);
      setHasValidationErrors(false);
      return setIsButtonDisabled(true);
    }

    // Check if form has errors
    let hasErrors = false;
    _.forEach(formContext.state.forms[currentTab]?.getFieldsError(), (err) => {
      hasErrors = hasErrors || !_.isEmpty(err.errors);
    });
    if (hasErrors) {
      setDataHasChanged(true);
      setHasValidationErrors(true);
      return setIsButtonDisabled(true);
    }

    setDataHasChanged(true);
    setHasValidationErrors(false);
    return setIsButtonDisabled(false);
  };

  const customSaveHandler = () => {
    handleSaveRates();
  };

  useEffect(() => {
    if (status === contractStatuses.review) {
      setIsButtonDisabled(true);
    }
  }, [status]);

  useEffect(() => {
    handleIsDataDirty();
  }, [tableData, bonus]);

  useSaveChangesNotification(customSaveHandler);

  return (
    <Button
      type="primary"
      onClick={handleSaveRates}
      disabled={isButtonDisabled}
      loading={isSaving}
      data-cy={Contracts.CtaSave}
    >
      Save
    </Button>
  );
};

export default SaveButton;
