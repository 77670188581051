import { InfoCircleOutlined } from '@ant-design/icons';
import { Modal, Tooltip, Typography } from 'antd';
import { format } from 'date-fns';
import React from 'react';

import PlacementActions from '../components/Actions/PlacementActions';
import { EditedBadge } from '../components/Common/EditedBadge';
import PlacementStatus from '../components/TemplateTable/PlacementStatus';
import { columnWidths, placementTypes, statusInfo } from '../constants';

export const columnsPlacements = [
  {
    fixed: 'left',
    width: columnWidths.actions,
    render: (record, status, index) => {
      return <PlacementActions placement={record} index={index} />;
    },
  },
  {
    title: 'Progress',
    dataIndex: ['data', 'status'],
    key: 'status',
    fixed: 'left',
    width: columnWidths.status,
    render: (status, record, index) => (
      <PlacementStatus
        status={status}
        placementId={record.id}
        disabled={record.data.placementType === placementTypes.RETIRED}
        index={index}
      />
    ),
  },
  {
    title: (
      <Typography.Text style={{ display: 'flex' }}>
        Status
        <Tooltip
          trigger="click"
          title={statusInfo}
          placement="top"
          color="#ffffff"
          mouseEnterDelay={0.2}
          overlayInnerStyle={{ color: 'rgba(0, 0, 0, 0.85)' }}
        >
          <span style={{ margin: '0 0 0 5px' }}>
            <InfoCircleOutlined />
          </span>
        </Tooltip>
      </Typography.Text>
    ),
    dataIndex: ['data', 'placementType'],
    key: 'placementType',
    width: columnWidths.placementType,
    showPrevData: false,
    fixed: 'left',
    render: (placementType, record) => {
      const commentInfo = () => {
        Modal.info({
          title: 'Retired Information',
          content: (
            <>
              <div style={{ margin: '10px 0 5px 0' }}>
                <b>Medium</b>:
                <span style={{ margin: '0 0 0 10px' }}>
                  {record?.data?.placementName}
                </span>
              </div>
              <div style={{ margin: '5px 0 5px 0' }}>
                <b>Retired On</b>:
                <span style={{ margin: '0 0 0 10px' }}>
                  {format(
                    new Date(record.data.retiredComments.updatedAt),
                    'yyyy-MM-dd, hh:mm:ss a zzz'
                  )}
                </span>
              </div>
              <div style={{ margin: '5px 0 5px 0' }}>
                <b>Retired By</b>:
                <span style={{ margin: '0 0 0 10px' }}>
                  {record?.data?.retiredComments?.updatedBy?.displayName}
                </span>
              </div>
              <div style={{ margin: '5px 0 5px 0' }}>
                <b>Comment</b>:
                <span style={{ margin: '0 0 0 10px' }}>
                  {record?.data?.retiredComments?.currentPhaseValue}
                </span>
              </div>
            </>
          ),
          onOk() {},
        });
      };
      return (
        <div>
          <Typography.Text>{placementType}</Typography.Text>
          {placementType === placementTypes.RETIRED &&
          record?.data?.retiredComments ? (
            <span style={{ margin: '0 0 0 5px' }}>
              <InfoCircleOutlined onClick={commentInfo} />
            </span>
          ) : null}
        </div>
      );
    },
  },
  {
    title: 'Medium',
    dataIndex: ['data', 'placementName'],
    key: 'placementName',
    fixed: 'left',
    width: columnWidths.placementName,
    render: (text, row) => (
      <div>
        <Typography.Text style={{ display: 'block' }}>
          {row.data.placementName}
        </Typography.Text>
        <Typography.Text
          type="secondary"
          style={{
            fontSize: 11,
            marginTop: 4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {row.data.distributionDays.currentPhaseValue}
          {row.data.editedByVendor ? <EditedBadge /> : <></>}
        </Typography.Text>
      </div>
    ),
  },
  {
    title: 'Classification',
    fixed: 'left',
    width: columnWidths.weightingName,
    render: (text, row) => {
      return (
        <div>
          <Typography.Text style={{ display: 'block' }}>
            {row.data.weighting.name}
          </Typography.Text>
        </div>
      );
    },
  },
  {
    title: 'State',
    dataIndex: ['data', 'state', 'currentPhaseValue'],
    key: 'placementState',
    width: columnWidths.state,
  },
  {
    title: 'Market',
    dataIndex: ['data', 'market', 'currentPhaseValue'],
    key: 'placementMarket',
    width: columnWidths.market,
  },
  {
    title: 'Frequency',
    dataIndex: ['data', 'frequency'],
    key: 'frequency',
    width: columnWidths.frequency,
  },
  {
    title: 'Distribution Method',
    dataIndex: ['data', 'distributionMethod'],
    key: 'distributionMethod',
    width: columnWidths.distributionMethod,
  },
  {
    title: 'Section / Positioning',
    dataIndex: ['data', 'sectionPositioning'],
    key: 'sectionPositioning',
    width: columnWidths.sectionPositioning,
    ellipsis: true,
  },
  {
    title: 'Calculation Size',
    dataIndex: ['data', 'calculationSize'],
    key: 'calculationSize',
    width: columnWidths.calculationSize,
  },
  {
    title: 'Height',
    dataIndex: ['data', 'height'],
    key: 'height',
    width: columnWidths.height,
  },
  {
    title: 'Width',
    dataIndex: ['data', 'width'],
    key: 'width',
    width: columnWidths.width,
  },
  {
    title: 'Casual Rate',
    dataIndex: ['data', 'casualRate'],
    key: 'casualRate',
    width: columnWidths.casualRate,
    showPrevData: true,
  },
  {
    title: 'Client Rate',
    dataIndex: ['data', 'clientRate'],
    key: 'clientRate',
    width: columnWidths.clientRate,
    showPrevData: true,
  },
  {
    title: 'Full Colour Load',
    dataIndex: ['data', 'fullColourLoad'],
    key: 'fullColourLoad',
    width: columnWidths.fullColourLoad,
    ellipsis: true,
    showPrevData: true,
  },
  {
    title: 'Colour Load Type',
    dataIndex: ['data', 'costType'],
    key: 'costType',
    width: columnWidths.costType,
    showPrevData: false,
  },
  {
    title: 'Full Page Colour Cost',
    dataIndex: ['data', 'fullPageColourCost'],
    key: 'fullPageColourCost',
    width: columnWidths.fullPageColourCost,
    showPrevData: true,
  },
  {
    title: 'Discount',
    dataIndex: ['data', 'discount'],
    key: 'discount',
    width: columnWidths.discount,
    showPrevData: true,
  },
  {
    title: 'Readership',
    dataIndex: ['data', 'readership'],
    key: 'readership',
    width: columnWidths.readership,
    showPrevData: true,
  },
  {
    title: 'Readership CPM',
    dataIndex: ['data', 'readershipCostPerThousand'],
    key: 'readershipCostPerThousand',
    width: columnWidths.readershipCPM,
    showPrevData: true,
  },
  {
    title: 'Comments',
    dataIndex: ['data', 'comment'],
    key: 'comment',
    ellipsis: true,
    width: columnWidths.comment,
  },
  {
    title: 'Year on Year Variance',
    fixed: 'right',
    children: [
      {
        title: 'Full Page Rate',
        dataIndex: ['data', 'yoyFullPageRateVariance'],
        key: 'yoyFullPageRateVariance',
        width: columnWidths.yoyFullPageRateVariance,
      },
      {
        title: 'Readership CPM',
        dataIndex: ['data', 'yoyReadershipCPMVariance'],
        key: 'yoyReadershipCPMVariance',
        width: columnWidths.yoyReadershipCPMVariance,
      },
    ],
  },
];
