export const contractualBonus = [
  {
    title: 'Contractual Bonus',
    dataIndex: ['data', 'contractualBonus'],
    key: 'contractualBonus',
    showPrevData: true,
  },
  {
    title: 'Year on Year Variance',
    dataIndex: ['data', 'contractualBonusYOY'],
    key: 'contractualBonusYOY',
    showPrevData: false,
  },
];
