// eslint-disable-next-line import/no-extraneous-dependencies
import Calculations from '@mbdt/shared/calculation';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Contracts } from '@mbdt/shared/datacy';
import { Space, Statistic } from 'antd';
import Text from 'antd/lib/typography/Text';
import _, { find, isNaN } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  calcFieldLabel,
  calculationDecimalPlaces,
  displayTypeSymbol,
} from '../../constants';

const gridStyle = {
  textAlign: 'center',
  padding: '8px 12px',
};

const getPropertyValue = (property, contract) => {
  const {
    function: calculationName,
    currentPhaseValue,
    previousYearValue,
  } = property;

  const lowerCase = calculationName.toLowerCase();
  // Check if function involves YOY calculation
  // Note: All YOY calculation names should include 'yoy'
  if (lowerCase.includes('yoy')) {
    // return 'N/A' if prevYearValue is null
    if (previousYearValue === null || previousYearValue === undefined) {
      return 'N/A';
    }
  }

  if (calculationName !== null) {
    const calc = Calculations.calculateTemplateValue(
      calculationName,
      contract.sections,
      calculationDecimalPlaces
    );
    return calc;
  }
  return Calculations.formatCalculatedValue(
    currentPhaseValue,
    calculationDecimalPlaces
  );
};

const YearlyRates = () => {
  const { data: contract } = useSelector((state) => state.contract);
  const overview = find(contract.sections, { type: 'overview' });
  const {
    data: {
      weightedRate = {},
      weightedCPM = {},
      yoyWeightedRate = {},
      yoyWeightedCPM = {},
      weightedReadership = {},
      weightedAudience = {},
      evCPMYOYVariance = {},
    } = {},
  } = overview || {};

  const tableData = useSelector((state) => state.contract.tableData);

  // Retrieve the most recent section values that are entered into the contract table
  const updatedSections = [overview, ...tableData];

  // Create a revised Contract object with the most recent section values.
  // This will update the YearlyRates in real-time
  const updatedContract = {
    ...contract,
    sections: updatedSections,
  };

  // If both weightedRate and weightedCPM are empty or don't exist, don't render component
  if (_.isEmpty(weightedRate) && _.isEmpty(weightedCPM)) return null;

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}
    >
      <Space style={{ border: '1px solid #f0f0f0', fontSize: 12 }}>
        {!_.isEmpty(weightedRate) && (
          <Space direction="vertical" align="start" size={4} style={gridStyle}>
            <Text style={{ fontWeight: 500 }}>{weightedRate.label}</Text>
            <Space size={4}>
              <div data-cy={Contracts.ValueWeightedRate}>
                <Statistic
                  value={getPropertyValue(weightedRate, updatedContract)}
                  valueStyle={{ fontSize: 14, fontWeight: 500 }}
                  prefix={displayTypeSymbol.money}
                  thousandSeparator=","
                  precision={2}
                />
              </div>
            </Space>
          </Space>
        )}

        {!_.isEmpty(weightedCPM) && (
          <Space direction="vertical" align="start" size={4} style={gridStyle}>
            <Text style={{ fontWeight: 500 }}>{weightedCPM.label}</Text>
            <Space size={4}>
              <div data-cy={Contracts.ValueWeightedCPM}>
                <Statistic
                  value={getPropertyValue(weightedCPM, updatedContract)}
                  valueStyle={{ fontSize: 14, fontWeight: 500 }}
                  prefix={displayTypeSymbol.money}
                  thousandSeparator=","
                  precision={2}
                />
              </div>
            </Space>
          </Space>
        )}

        {!_.isEmpty(weightedAudience) && (
          <Space direction="vertical" align="start" size={4} style={gridStyle}>
            <Text style={{ fontWeight: 500 }}>{weightedAudience.label}</Text>
            <Space size={4}>
              <div data-cy={Contracts.ValueWeightedAudience}>
                <Statistic
                  value={getPropertyValue(weightedAudience, updatedContract)}
                  valueStyle={{ fontSize: 14, fontWeight: 500 }}
                  thousandSeparator=","
                  precision={0}
                />
              </div>
            </Space>
          </Space>
        )}

        {!_.isEmpty(weightedReadership) && (
          <Space direction="vertical" align="start" size={4} style={gridStyle}>
            <Text style={{ fontWeight: 500 }}>{weightedReadership.label}</Text>
            <Space size={4}>
              <div data-cy={Contracts.ValueWeightedReadership}>
                <Statistic
                  value={getPropertyValue(weightedReadership, updatedContract)}
                  valueStyle={{ fontSize: 14, fontWeight: 500 }}
                  prefix={
                    !!getPropertyValue(weightedReadership, updatedContract)
                  }
                  thousandSeparator=","
                  precision={0}
                />
              </div>
            </Space>
          </Space>
        )}
      </Space>

      <Space
        style={{
          border: '1px solid #f0f0f0',
          fontSize: 12,
          background: '#f3acb0',
        }}
      >
        {!_.isEmpty(yoyWeightedRate) && (
          <Space direction="vertical" align="start" size={4} style={gridStyle}>
            <Text style={{ fontWeight: 500 }}>
              {calcFieldLabel[yoyWeightedRate.key]}
            </Text>
            <Space size={4}>
              <div data-cy={Contracts.ValueYoyWeightedRate}>
                <Statistic
                  value={getPropertyValue(yoyWeightedRate, updatedContract)}
                  valueStyle={{ fontSize: 14, fontWeight: 500 }}
                  suffix={
                    !isNaN(
                      Number(getPropertyValue(yoyWeightedRate, updatedContract))
                    )
                      ? displayTypeSymbol.percentage
                      : null
                  }
                  precision={2}
                />
              </div>
            </Space>
          </Space>
        )}

        {!_.isEmpty(yoyWeightedCPM) && (
          <Space direction="vertical" align="start" size={4} style={gridStyle}>
            <Text style={{ fontWeight: 500 }}>
              {calcFieldLabel[yoyWeightedCPM.key]}
            </Text>
            <Space size={4}>
              <div data-cy={Contracts.ValueYoyWeightedCPM}>
                <Statistic
                  value={getPropertyValue(yoyWeightedCPM, updatedContract)}
                  valueStyle={{ fontSize: 14, fontWeight: 500 }}
                  suffix={
                    !isNaN(
                      Number(getPropertyValue(yoyWeightedRate, updatedContract))
                    )
                      ? displayTypeSymbol.percentage
                      : null
                  }
                  precision={2}
                />
              </div>
            </Space>
          </Space>
        )}

        {!_.isEmpty(evCPMYOYVariance) && (
          <Space direction="vertical" align="start" size={4} style={gridStyle}>
            <Text style={{ fontWeight: 500 }}>
              {calcFieldLabel[evCPMYOYVariance.key]}
            </Text>
            <Space size={4}>
              <div data-cy={Contracts.ValueEvCPMYOYVariance}>
                <Statistic
                  value={getPropertyValue(evCPMYOYVariance, updatedContract)}
                  valueStyle={{ fontSize: 14, fontWeight: 500 }}
                  suffix={
                    !isNaN(
                      Number(getPropertyValue(yoyWeightedRate, updatedContract))
                    )
                      ? displayTypeSymbol.percentage
                      : null
                  }
                  precision={2}
                />
              </div>
            </Space>
          </Space>
        )}
      </Space>
    </div>
  );
};

export default YearlyRates;
