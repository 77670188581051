import { Checkbox } from 'antd';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectSelectedPlacementRows } from '../../store/selectors';
import { setSelectedPlacementRows } from '../../store/slices/contract.slice';

const CustomCheckbox = ({ id, disabled, tableData = [], dataCy }) => {
  const dispatch = useDispatch();
  const selectedRowKeys = useSelector(selectSelectedPlacementRows) || [];
  const isAllSelected = id === 'selectAll';

  const handleChange = useCallback(
    (e) => {
      const { checked } = e.target;
      if (isAllSelected) {
        // Handle "Select All" logic
        if (checked) {
          const allRowKeys = tableData.map((r) => r.key);
          dispatch(setSelectedPlacementRows(allRowKeys));
        } else {
          dispatch(setSelectedPlacementRows([]));
        }
      } else {
        // Handle individual row selection logic
        const newSelectedRowKeys = checked
          ? [...selectedRowKeys, id]
          : selectedRowKeys.filter((key) => key !== id);
        dispatch(setSelectedPlacementRows(newSelectedRowKeys));
      }
    },
    [dispatch, id, isAllSelected, tableData, selectedRowKeys]
  );

  const checked = useMemo(() => {
    if (isAllSelected) {
      return (
        selectedRowKeys.length > 0 &&
        selectedRowKeys.length === tableData.length
      );
    }
    return selectedRowKeys.includes(id);
  }, [isAllSelected, selectedRowKeys, tableData.length, id]);

  const indeterminate = useMemo(() => {
    return (
      isAllSelected &&
      selectedRowKeys.length > 0 &&
      selectedRowKeys.length < tableData.length
    );
  }, [isAllSelected, selectedRowKeys.length, tableData.length]);

  return (
    <Checkbox
      className="centered-cell"
      checked={checked}
      indeterminate={indeterminate}
      onChange={handleChange}
      disabled={disabled}
      data-cy={dataCy}
    />
  );
};

CustomCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
    })
  ),
  dataCy: PropTypes.string,
};

CustomCheckbox.defaultProps = {
  tableData: [],
  disabled: false,
};

export default memo(CustomCheckbox);
