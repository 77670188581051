import { SearchOutlined } from '@ant-design/icons';
// eslint-disable-next-line import/no-extraneous-dependencies
import Calculations from '@mbdt/shared/calculation';
import { Button, Input, Space } from 'antd';
import _ from 'lodash';
import React from 'react';
import { toast } from 'react-toastify';

import { ToastError } from '../components/Common/ToastMessages';
import {
  calculationDecimalPlaces,
  displayTypePosition,
  displayTypes,
  errorMessages,
} from '../constants';

export const getUniqueSections = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

// TODO: Replace the case strings with the constants created in GRV2-67
export const renderDisplayTypePosition = (displayType) => {
  switch (displayType) {
    case displayTypes.money:
      return displayTypePosition.prefix;
    case displayTypes.percentage:
      return displayTypePosition.suffix;
    default:
      return null;
  }
};

// TODO: Re-think this and potentially use redux middleware to control error handling
export const displayGenericErrorMessage = (error) => {
  const errorName = error?.response?.data?.details[0]?.name;

  // Display toast message
  toast(
    <ToastError message={errorMessages[errorName] || errorMessages.default} />,
    {
      autoClose: 7000,
    }
  );
};

export const displayProvidedErrorMessage = (error) => {
  const [errorMessage] = error?.response?.data?.details[0]?.messages;

  // Display toast message
  toast(<ToastError message={errorMessage} />, {
    autoClose: 7000,
  });
};

/**
 * Check if table cell data is an object
 * @param {*} record
 * @param {*} fieldName
 */
export const checkIsRecordObject = (record, fieldName) =>
  record &&
  record.data &&
  typeof record.data[fieldName] === 'object' &&
  record.data[fieldName] !== null; // Need this since JavaScript null values are objects and we need to ignore null

/**
 * Retrieve an object's key based on its value
 * @param {Object} object
 * @param {String} value
 */
export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const searchFilter = () => ({
  // eslint-disable-next-line react/prop-types
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div
      style={{
        padding: 8,
      }}
    >
      <Input
        autoFocus
        placeholder="Search"
        value={selectedKeys[0]}
        onChange={(e) => {
          setSelectedKeys(e.target.value ? [e.target.value] : []);
        }}
        onPressEnter={() => {
          confirm();
        }}
        style={{
          marginBottom: 8,
          display: 'block',
        }}
      />
      <Space
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
        >
          Search
        </Button>
        <Button onClick={() => clearFilters()} size="small">
          Reset
        </Button>
      </Space>
    </div>
  ),

  filterIcon: () => <SearchOutlined />,
});

/**
 *
 * @param {Object} placement A placement object
 * @param {{key: String, path: String, transform: Function}[]} listOfPropertiesToExtract A collection of configuration to extract properties from the placement object
 * @returns {Object} An object containing the extracted properties
 */
export const extractContractPlacementValues = (
  placement,
  listOfPropertiesToExtract
) => {
  const { data: placementData = {} } = placement || {};
  return listOfPropertiesToExtract.reduce((acc, prop) => {
    // Attempt to find the prop
    const keyValue = _.get(placementData, prop.path);
    if (keyValue !== null) {
      // If we need to transform the value, do it now.
      const tranformedValue = prop.transform
        ? prop.transform(keyValue)
        : keyValue;
      return { ...acc, [prop.key]: tranformedValue };
    }
    return acc;
  }, {});
};

/**
 * Returns calculated values from fieldname function
 * @param {Object} data - Record object
 * @param {string} fieldName - Gield name
 * @returns {number | string} Calculated value or "N/A"
 */
export const calculate = (data, fieldName) => {
  if (!data) {
    return;
  }

  const { function: calculationName, previousYearValue } = data.data[fieldName];

  const lowerCase = calculationName.toLowerCase();
  // Check if function involves YOY calculation
  // Note: All YOY calculation names should include 'yoy'
  if (lowerCase.includes('yoy')) {
    // return 'N/A' if prevYearValue is null
    if (previousYearValue === null || previousYearValue === undefined) {
      return 'N/A';
    }
  }

  return Calculations.calculatePlacementValue(
    calculationName,
    data,
    calculationDecimalPlaces
  );
};

export const streamBlobToClient = (blob, filename) => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * Checks if the contractual bonus data has been updated.
 *
 * @param {Object} tableData - The current table data.
 * @param {Array} sections - The array of sections containing the overview data.
 * @returns {boolean} - Returns true if the contractual bonus data has been updated, otherwise false.
 */
export const isBonusUpdated = (tableData, sections) => {
  // Return false if tableData is not provided
  if (!tableData?.data?.contractualBonus) return false;

  // Find the 'overview' section and extract its data
  const overview = sections.find(({ type }) => type === 'overview');
  if (!overview) return false;

  const { contractualBonus } = overview.data;

  // Normalize currentPhaseValue to a number for comparison
  const tableContractualBonus = {
    ...tableData.data.contractualBonus,
    currentPhaseValue: tableData.data.contractualBonus?.currentPhaseValue
      ? Number(tableData.data.contractualBonus?.currentPhaseValue)
      : tableData.data.contractualBonus?.currentPhaseValue,
  };
  // Compare the normalized data with the provided overview data
  return !_.isEqual(
    { contractualBonus },
    { contractualBonus: tableContractualBonus }
  );
};
