import React, { createContext, useContext, useReducer } from 'react';

const ContractFormContext = createContext();

const initialState = {
  forms: {},
  errors: {},
};

const formReducer = (state, action) => {
  switch (action.type) {
    case 'addForm': {
      return { forms: { ...state.forms, ...action.payload } };
    }
    case 'validateForm': {
      return { ...state, errors: { ...state.errors, ...action.payload } };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

// eslint-disable-next-line react/prop-types
const ContractFormProvider = ({ children }) => {
  const [state, dispatch] = useReducer(formReducer, initialState);
  const value = { state, dispatch };

  return (
    <ContractFormContext.Provider value={value}>
      {children}
    </ContractFormContext.Provider>
  );
};

const useContractForm = () => {
  const context = useContext(ContractFormContext);

  if (context === undefined) {
    throw new Error('useForm must be used within a FormProvider');
  }

  return context;
};

export { ContractFormProvider, useContractForm };
