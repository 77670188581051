import { Space, Statistic } from 'antd';
import Text from 'antd/lib/typography/Text';
import React from 'react';

import PreviousYearData from '../components/Common/PreviousYearData';
import {
  displayTypePosition,
  displayTypes,
  displayTypeSymbol,
} from '../constants';
import { renderDisplayTypePosition } from '../helpers';

export const columnsOverview = [
  {
    title: 'Overview',
    dataIndex: 'label',
    key: 'label',
    render: (value, record) => {
      return (
        <Space direction="vertical" size={2}>
          <Text>{value}</Text>
          {record.description && (
            <Text
              type="secondary"
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: 11,
                maxWidth: '275px',
              }}
            >
              {record.description}
            </Text>
          )}
        </Space>
      );
    },
  },
  {
    title: '',
    dataIndex: 'currentPhaseValue',
    key: 'currentPhaseValue',
    render: (value, record) => {
      const displayTypePos = renderDisplayTypePosition(record?.displayType);
      return (
        <div>
          <Statistic
            value={value !== null ? value : '-'}
            precision={
              (record?.displayType === displayTypes.money ||
                record?.displayType === displayTypes.percentage) &&
              2
            }
            prefix={
              !!value &&
              displayTypePos === displayTypePosition.prefix &&
              displayTypeSymbol.money
            }
            suffix={
              !!value &&
              displayTypePos === displayTypePosition.suffix &&
              displayTypeSymbol.percentage
            }
            thousandSeparator=","
            valueStyle={{ fontSize: 13 }}
          />
          <PreviousYearData
            value={record.previousYearValue}
            prefix={
              displayTypePos === displayTypePosition.prefix &&
              displayTypeSymbol.money
            }
            suffix={
              displayTypePos === displayTypePosition.suffix &&
              displayTypeSymbol.percentage
            }
          />
        </div>
      );
    },
  },
];
