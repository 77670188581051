import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { routes } from '../../config';

const ContractBreadcrumbs = ({ activeMediaType, activeMediaTypePath }) => {
  const history = useHistory();

  const navigateWithRouter = (href) => {
    history.push(href);
  };

  const cancelCrumbClick = (event) => {
    event.preventDefault();
  };

  const mediaTypePath = `${routes.CONTRACTS}/${activeMediaTypePath}`;

  return (
    <Breadcrumb
      style={{ marginBottom: 12, fontSize: 12 }}
      onClick={cancelCrumbClick}
    >
      <Breadcrumb.Item onClick={() => navigateWithRouter('/')} href="/">
        <HomeOutlined />
      </Breadcrumb.Item>
      <Breadcrumb.Item
        onClick={() => navigateWithRouter(routes.CONTRACTS)}
        href={routes.CONTRACTS}
      >
        <span>Contracts</span>
      </Breadcrumb.Item>
      <Breadcrumb.Item
        onClick={() => navigateWithRouter(mediaTypePath)}
        href={mediaTypePath}
      >
        {activeMediaType}
      </Breadcrumb.Item>
    </Breadcrumb>
  );
};

ContractBreadcrumbs.propTypes = {
  activeMediaType: PropTypes.string.isRequired,
  activeMediaTypePath: PropTypes.string.isRequired,
};

export default ContractBreadcrumbs;
