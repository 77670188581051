import { useEffect } from 'react';

/**
 * A unified hook to add 'data-cy' attributes to elements. It can work with both
 * a ref to target elements within a specific component and a global selector for broader changes.
 * It also supports setting up event listeners for dynamic UI changes.
 *
 * @param {React.RefObject|Object} refOrOptions A ref attached to the component or options for global selectors.
 * @param {Array|String} attributesOrSelector Either an array of attribute configurations or a CSS selector string.
 * @param {String} attribute Optional base name for the data-cy attribute, used with global selector.
 */
const useAddDataCyAttributes = (
  refOrOptions,
  attributesOrSelector,
  attribute
) => {
  const isRef = refOrOptions?.current !== undefined;

  useEffect(() => {
    try {
      if (isRef) {
        // Handling elements within a ref
        if (refOrOptions.current) {
          const attributes = attributesOrSelector;
          attributes.forEach(({ selector, dataCy }) => {
            const element = refOrOptions.current.querySelector(selector);
            if (element) {
              element.setAttribute('data-cy', dataCy);
            }
          });
        }
      } else {
        // Handling global selector with dynamic element updates
        const selector = attributesOrSelector;

        const addDataCyAttributes = (name) => {
          document.querySelectorAll(selector).forEach((element) => {
            element.setAttribute('data-cy', `${attribute}-${name}`);
          });
        };

        const handleFilterClick = (name) => {
          setTimeout(() => addDataCyAttributes(name), 0);
        };

        const elements = document.querySelectorAll('.ant-table-filter-trigger');
        elements.forEach((trigger) => {
          const columnTitle = trigger
            .closest('th')
            ?.querySelector('.ant-table-column-title');
          const columnName = columnTitle?.textContent?.replace?.(/\s/g, '');
          trigger.addEventListener('click', () =>
            handleFilterClick(columnName)
          );
        });

        // Cleanup
        return () => {
          elements.forEach((trigger) => {
            trigger.removeEventListener('click', handleFilterClick);
          });
        };
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [refOrOptions, attributesOrSelector, attribute]); // Re-run when dependencies change
};

export default useAddDataCyAttributes;
