import { Space } from 'antd';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { contractSectionTypes } from '../../constants';
import { ContractUIContext } from '../../containers/Contract/ContractUIContext';
import { selectVisualCues } from '../../store/selectors';
import ContractStatus from '../ContractHeader/ContractStatus';
import RoundsMenu from '../RoundsMenu/RoundsMenu';
import ActionsMenu from './ActionsMenu';
import DownloadRatesButton from './DownloadRatesButton';
import SaveButton from './SaveButton';
import SaveWeightingButton from './SaveWeightingButton';

const Actions = () => {
  const {
    canEditPlacement,
    canEditWeighting,
    canStartNegotiations,
    canSubmitForReview,
    canSubmitForRevision,
    canSubmitForApproval,
    canApproveTemplate,
    canRejectTemplate,
    canDownloadRates,
  } = useSelector(selectVisualCues);

  // Create an array comprised of visual cues relating to the Action Buttons
  const actionButtonCues = [
    canStartNegotiations,
    canSubmitForReview,
    canSubmitForRevision,
    canSubmitForApproval,
    canApproveTemplate,
    canRejectTemplate,
  ];

  // Determine whether to show or hide the Actions Menu based on whether a
  // true value exists in the actionButtonCues array
  const isActionsMenuVisible = actionButtonCues.find(
    (visualCue) => visualCue === true
  );

  const { activeTab: currentTab } = useContext(ContractUIContext);

  const isAllowedToEditWeighting =
    canEditWeighting && currentTab === contractSectionTypes.weighting;

  const isAllowedToEditRates =
    canEditPlacement && currentTab !== contractSectionTypes.weighting;

  return (
    <Space>
      {canDownloadRates && <DownloadRatesButton />}
      <ContractStatus />
      <RoundsMenu />
      {isAllowedToEditRates && <SaveButton />}
      {isAllowedToEditWeighting && <SaveWeightingButton />}
      {isActionsMenuVisible && <ActionsMenu />}
    </Space>
  );
};

export default Actions;
