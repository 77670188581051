import './Styles.less';

import { ExclamationCircleOutlined } from '@ant-design/icons';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Contracts } from '@mbdt/shared/datacy';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const TabPaneContent = ({ name, datacy, error }) => {
  return (
    <div data-cy={datacy}>
      {name}{' '}
      {error && (
        <Tooltip
          overlayClassName="error-tooltip"
          title={<span className="custom-error">{error}</span>}
        >
          <ExclamationCircleOutlined
            data-cy={`${Contracts.TabError}-${datacy}`}
            style={{ color: 'red', marginLeft: 8 }}
          />
        </Tooltip>
      )}
    </div>
  );
};

TabPaneContent.propTypes = {
  name: PropTypes.string.isRequired,
  datacy: PropTypes.string,
  error: PropTypes.string,
};

export default TabPaneContent;
