/* eslint-disable react/prop-types */
import './ContractualBonusTable.less';

// eslint-disable-next-line import/no-extraneous-dependencies
import { Contracts } from '@mbdt/shared/datacy';
import { createSelector } from '@reduxjs/toolkit';
import { Form } from 'antd';
import _ from 'lodash';
import React, { useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { bonus, placementEditTypes } from '../../constants';
import { ContractUIContext } from '../../containers/Contract/ContractUIContext';
import { useDiscardChangesNotification } from '../../containers/Contract/ContractUIEvents';
import { useContractForm } from '../../context/contractForm-context';
import { contractTableColumns } from '../../data';
import { renderDisplayTypePosition } from '../../helpers';
import {
  selectContractSections,
  selectIsContractInRevision,
  selectVisualCues,
} from '../../store/selectors';
import { updateSectionDataSuccess } from '../../store/slices/contract.slice';
import TemplateTable from '../TemplateTable/TemplateTable';

const makeSelectTableDataBySectionType = () =>
  createSelector(
    [selectContractSections, (_state, sectionType) => sectionType],
    (sections) => {
      const overview = sections?.find((item) => item.type === 'overview');
      const {
        contractualBonus = null,
        contractualBonusYOY = null,
        id = null,
      } = overview.data || {};

      // Construct the contractualBonus object
      const contractualBonusData = {
        type: 'contractual_bonus',
        label: 'Contractual Bonus',
        data: {
          contractualBonus,
          contractualBonusYOY,
        },
        id,
      };
      const sectionsData = [contractualBonusData].map((data, idx) => {
        const { data: placementData } = data;
        const formattedFields = {};
        _.forEach(placementData, (value, fieldName) => {
          if (value.editTypes) {
            formattedFields[fieldName] = {
              ...value,
              // set editable value on table
              editable: _.includes(
                value.editTypes,
                placementEditTypes.EDIT_PLACEMENT_INLINE
              ),
            };
          } else {
            formattedFields[fieldName] = value;
          }
        });

        // update data with formatted fields
        data = {
          ...data,
          data: formattedFields,
        };
        return { key: data?.id || idx, ...data };
      });
      return sectionsData;
    }
  );

const ContractualBonusTable = ({ sectionId, sectionType, mediaType }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const formContext = useContractForm();
  const { contractTableHeaderHeight, tableHeight } =
    useContext(ContractUIContext);

  const selectTableDataBySectionType = useMemo(
    makeSelectTableDataBySectionType,
    []
  );
  const isContractInRevision = useSelector(selectIsContractInRevision);

  // Prevent this from trigger re-renders in the ant design table
  const tableData = useSelector(
    (state) => selectTableDataBySectionType(state, sectionType),
    (state, prevState) => _.isEqual(state, prevState)
  );

  const { canEditPlacement, canSubmitForReview, canSubmitForApproval } =
    useSelector(selectVisualCues);

  const vendorSubmissionToAgency =
    canSubmitForReview && canEditPlacement && isContractInRevision;
  const agencyApproveLockContract = canSubmitForApproval && canEditPlacement;

  // We should mark the contractual bonus as mandatory for vendor submission and UM submit for approvals
  const isContractualBonusRequired =
    vendorSubmissionToAgency || agencyApproveLockContract;

  const handleSave = async (row) => {
    await dispatch(updateSectionDataSuccess(row));
  };

  const mapColumns = (col) => {
    const newCol = {
      ...col,
      onHeaderCell: (column) => {
        const columnKey = column.key ?? col?.children?.[0]?.key;
        return {
          'data-cy': `${sectionId}-${columnKey}`, // Ensure the attribute is set for header cells
        };
      },
      title:
        col.key === bonus.contractualBonus && isContractualBonusRequired ? (
          <>
            {col.title} <span style={{ color: 'red' }}>*</span>
          </>
        ) : (
          col.title
        ),
      onCell: (record, index) => ({
        record: {
          ...record,
          dataCy: `${Contracts.TableCellClassificationEdit}${index}-${col.key}`,
        },
        displayType: record?.data[col.key]?.displayType,
        displayTypePos: renderDisplayTypePosition(
          record?.data[col.key]?.displayType
        ),
        isCalculatedCell: !!record?.data[col.key]?.function,
        dataIndex: col.dataIndex,
        title: col.title,
        editable: record?.data[col.key]?.editable,
        required:
          record?.data[col.key].key === bonus.contractualBonus &&
          isContractualBonusRequired,
        handleSave,
        isEditing: !!(record?.data[col.key]?.editable && canEditPlacement),
        fieldName: col.key,
        sectionId,
        showPrevData: col.showPrevData,
        'data-cy': `${sectionId}-${index}-${col.key}`,
      }),
    };

    if (col.children) {
      newCol.children = col.children.map(mapColumns);
    }

    return newCol;
  };

  const columns = useMemo(
    () => contractTableColumns[`${mediaType}_${sectionType}`]?.map(mapColumns),
    [canEditPlacement, isContractualBonusRequired]
  );

  const ResetFields = () => {
    form.resetFields();
  };

  useEffect(() => {
    if (!isContractualBonusRequired) {
      formContext.dispatch({
        type: 'validateForm',
        payload: { contractual_bonus: null },
      });
    }
  }, [isContractualBonusRequired]);

  // Added to clear the contractual bonus mandatory error icon in the tabs
  useEffect(() => {
    formContext.dispatch({
      type: 'addForm',
      payload: {
        [sectionId]: form,
      },
    });
  }, []);

  useDiscardChangesNotification(ResetFields);

  return (
    <Form form={form} component={false}>
      <TemplateTable
        tableData={tableData}
        columns={columns}
        tableHeight={tableHeight}
        tableHeaderHeight={contractTableHeaderHeight}
      />
    </Form>
  );
};

export default ContractualBonusTable;
