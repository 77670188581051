import PropTypes from 'prop-types';
import React from 'react';

import { mediumStatuses } from '../../constants';
import {
  IconAccepted,
  IconDraft,
  IconReview,
  IconUnderReview,
} from '../Icons/Icons';

const StatusIcon = ({ status }) => {
  const renderStatusIcon = () => {
    switch (status) {
      // Contract is either in Draft or First Revision
      case mediumStatuses.revision:
        return <IconDraft />;

      // Contract/Placement is now in Agency to Review but yet to be approved/rejected by Agency
      case mediumStatuses.review:
        return <IconUnderReview />;

      // Placement has been accepted by Agency
      case mediumStatuses.approved:
        return <IconAccepted />;

      // Placement has been rejected by Agency
      case mediumStatuses.rejected:
        return <IconReview />;

      default:
        return <IconDraft />;
    }
  };

  return (
    <div
      style={{
        width: 22,
        height: 22,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {renderStatusIcon()}
    </div>
  );
};

StatusIcon.propTypes = {
  status: PropTypes.string,
};

export default StatusIcon;
