import { sortOptions } from '../constants';
import api from './api';
import streamApi from './pdfStreamApi';

export const fetchContracts = async (
  mediaType,
  pageLimit = 20,
  pageNumber = 0,
  orderBy = sortOptions.masterNetworkAsc
) =>
  api.get(
    `${mediaType}?pageLimit=${pageLimit}&pageNumber=${pageNumber}&orderBy=${orderBy}`
  );

export const fetchContractTemplate = async (
  mediaType,
  resourceId,
  includeCues = true
) => api.get(`${mediaType}/${resourceId}?includeCues=${includeCues}`);

export const updateContractTemplate = async (
  mediaType,
  resourceId,
  body,
  editType = '',
  includeCues = true
) =>
  api.patch(
    `${mediaType}/${resourceId}?includeCues=${includeCues}&editType=${editType}`,
    body
  );

export const phaseRollover = async (
  mediaType,
  resourceId,
  includeCues = true
) =>
  api.post(
    `${mediaType}/${resourceId}/phase-rollover?includeCues=${includeCues}`
  );

export const submitPhase = async (mediaType, resourceId, includeCues = true) =>
  api.post(
    `${mediaType}/${resourceId}/phase-submit?includeCues=${includeCues}`
  );

export const placementApproval = async (
  mediaType,
  resourceId,
  body,
  includeCues = true
) =>
  api.post(
    `${mediaType}/${resourceId}/placement-approval?includeCues=${includeCues}`,
    body
  );

export const phaseApproval = async (
  mediaType,
  resourceId,
  body,
  includeCues = true
) =>
  api.post(
    `${mediaType}/${resourceId}/phase-approval?includeCues=${includeCues}`,
    body
  );

export const streamContractTemplate = async (
  mediaType,
  resourceId,
  includeCues = false
) => streamApi.get(`${mediaType}/${resourceId}?includeCues=${includeCues}`);
