export const columnsWeighting = [
  {
    title: 'Name',
    dataIndex: ['data', 'name'],
    key: 'name',
    showPrevData: false,
  },
  {
    title: 'Classification',
    dataIndex: ['data', 'weight'],
    key: 'weight',
    showPrevData: false,
  },
  {
    title: 'Year on Year Variance',
    fixed: 'right',
    children: [
      {
        title: '',
        dataIndex: ['data', 'weightYOYVariance'],
        key: 'weightYOYVariance',
      },
    ],
  },
];
