import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { mediaTypes } from '../../constants';
import { getKeyByValue } from '../../helpers';
import EditPlacementCinema from './EditPlacementCinema';
import EditPlacementDigital from './EditPlacementDigital';
import EditPlacementMagazine from './EditPlacementMagazine';
import EditPlacementNewspaper from './EditPlacementNewspaper';
import EditPlacementOOH from './EditPlacementOOH';
import EditPlacementRadio from './EditPlacementRadio';
import EditPlacementTelevision from './EditPlacementTelevision';

const useContractActionQuery = () => {
  const { search } = useLocation();
  const action = new URLSearchParams(search).get('action');
  const recordId = new URLSearchParams(search).get('recordId');

  return {
    action,
    recordId,
  };
};

const EditPlacementModal = () => {
  const { mediaType } = useParams();
  const { action, recordId } = useContractActionQuery();
  const history = useHistory();
  const tableData = useSelector((state) => state.contract.tableData);
  const [hasMounted, setHasMounted] = useState(false);
  const [placement, setPlacement] = useState(null);

  useEffect(() => {
    if (!hasMounted) {
      return;
    }

    if (action && action === 'edit' && recordId) {
      const selected = tableData?.find((rowData) => rowData.id === recordId);
      if (selected) {
        setPlacement(selected);
      }
    }
  }, [action, recordId]);

  useEffect(() => {
    // Clear query params on mount to prevent modal from opening on refresh, etc.
    history.replace({
      search: '',
    });

    setHasMounted(true);
  }, []);

  const toggleIsOpen = () => {
    if (placement) {
      setPlacement(null);
      // Clear query params on close
      history.replace({
        search: '',
      });
    }
  };

  const renderEditPlacementModal = () => {
    switch (mediaType) {
      case getKeyByValue(mediaTypes, mediaTypes.newspapers): {
        return (
          <EditPlacementNewspaper
            isOpen={!!placement}
            placement={placement}
            setIsOpen={toggleIsOpen}
          />
        );
      }

      case getKeyByValue(mediaTypes, mediaTypes.television): {
        return (
          <EditPlacementTelevision
            isOpen={!!placement}
            placement={placement}
            setIsOpen={toggleIsOpen}
          />
        );
      }

      case getKeyByValue(mediaTypes, mediaTypes.cinema): {
        return (
          <EditPlacementCinema
            isOpen={!!placement}
            placement={placement}
            setIsOpen={toggleIsOpen}
          />
        );
      }

      case getKeyByValue(mediaTypes, mediaTypes.radio): {
        return (
          <EditPlacementRadio
            isOpen={!!placement}
            placement={placement}
            setIsOpen={toggleIsOpen}
          />
        );
      }

      case getKeyByValue(mediaTypes, mediaTypes.magazines): {
        return (
          <EditPlacementMagazine
            isOpen={!!placement}
            placement={placement}
            setIsOpen={toggleIsOpen}
          />
        );
      }

      case getKeyByValue(mediaTypes, mediaTypes.ooh): {
        return (
          <EditPlacementOOH
            isOpen={!!placement}
            placement={placement}
            setIsOpen={toggleIsOpen}
          />
        );
      }

      case getKeyByValue(mediaTypes, mediaTypes.digital): {
        return (
          <EditPlacementDigital
            isOpen={!!placement}
            placement={placement}
            setIsOpen={toggleIsOpen}
          />
        );
      }

      default:
        return null;
    }
  };

  if (!placement) {
    return <></>;
  }

  return renderEditPlacementModal();
};

export default EditPlacementModal;
