import { InfoCircleOutlined } from '@ant-design/icons';
import { Modal, Tooltip, Typography } from 'antd';
import { format } from 'date-fns';
import React from 'react';

import PlacementActions from '../components/Actions/PlacementActions';
import { EditedBadge } from '../components/Common/EditedBadge';
import PlacementStatus from '../components/TemplateTable/PlacementStatus';
import { columnWidths, placementTypes, statusInfo } from '../constants';

export const columnsTelevisionMultiplier = [
  {
    title: 'Name',
    dataIndex: 'label',
    key: 'label',
    width: columnWidths.multiplierName,
    showPrevData: false,
  },
  {
    title: '15 sec',
    dataIndex: ['data', 'multiplier15sec'],
    key: 'multiplier15sec',
    width: columnWidths.durationMultiplier,
    showPrevData: true,
  },
  {
    title: '30 sec',
    dataIndex: ['data', 'multiplier30sec'],
    key: 'multiplier30sec',
    width: columnWidths.durationMultiplier,
    showPrevData: true,
  },
  {
    title: '45 sec',
    dataIndex: ['data', 'multiplier45sec'],
    key: 'multiplier45sec',
    width: columnWidths.durationMultiplier,
    ellipsis: true,
    showPrevData: true,
  },
  {
    title: '60 sec',
    dataIndex: ['data', 'multiplier60sec'],
    key: 'multiplier60sec',
    width: columnWidths.durationMultiplier,
    showPrevData: true,
  },
  {
    title: 'Year on Year Variance',
    fixed: 'right',
    children: [
      {
        title: '15 sec',
        dataIndex: ['data', 'multiplier15secYOYVariance'],
        key: 'multiplier15secYOYVariance',
        width: columnWidths.durationMultiplierYoY,
      },
      {
        title: '30 sec',
        dataIndex: ['data', 'multiplier30secYOYVariance'],
        key: 'multiplier30secYOYVariance',
        width: columnWidths.durationMultiplierYoY,
      },
      {
        title: '45 sec',
        dataIndex: ['data', 'multiplier45secYOYVariance'],
        key: 'multiplier45secYOYVariance',
        width: columnWidths.durationMultiplierYoY,
      },
      {
        title: '60 sec',
        dataIndex: ['data', 'multiplier60secYOYVariance'],
        key: 'multiplier60secYOYVariance',
        width: columnWidths.durationMultiplierYoY,
      },
    ],
  },
];

export const columnsTelevisionPeakOffPeak = [
  {
    fixed: 'left',
    width: columnWidths.actions,
    render: (record, status, index) => {
      return <PlacementActions placement={record} index={index} />;
    },
  },
  {
    title: 'Progress',
    dataIndex: ['data', 'status'],
    key: 'status',
    fixed: 'left',
    width: columnWidths.status,
    render: (status, record, index) => (
      <PlacementStatus
        status={status}
        placementId={record.id}
        disabled={record.data.placementType === placementTypes.RETIRED}
        index={index}
      />
    ),
  },
  {
    title: (
      <Typography.Text style={{ display: 'flex' }}>
        Status
        <Tooltip
          trigger="click"
          title={statusInfo}
          placement="top"
          color="#ffffff"
          mouseEnterDelay={0.2}
          overlayInnerStyle={{ color: 'rgba(0, 0, 0, 0.85)' }}
        >
          <span style={{ margin: '0 0 0 5px' }}>
            <InfoCircleOutlined />
          </span>
        </Tooltip>
      </Typography.Text>
    ),
    dataIndex: ['data', 'placementType'],
    key: 'placementType',
    width: columnWidths.placementType,
    showPrevData: false,
    fixed: 'left',
    render: (placementType, record) => {
      const commentInfo = () => {
        Modal.info({
          title: 'Retired Information',
          content: (
            <>
              <div style={{ margin: '10px 0 5px 0' }}>
                <b>Publication</b>:
                <span style={{ margin: '0 0 0 10px' }}>
                  {record?.data?.placementName}
                </span>
              </div>
              <div style={{ margin: '5px 0 5px 0' }}>
                <b>Retired On</b>:
                <span style={{ margin: '0 0 0 10px' }}>
                  {format(
                    new Date(record.data.retiredComments.updatedAt),
                    'yyyy-MM-dd, hh:mm:ss a zzz'
                  )}
                </span>
              </div>
              <div style={{ margin: '5px 0 5px 0' }}>
                <b>Retired By</b>:
                <span style={{ margin: '0 0 0 10px' }}>
                  {record?.data?.retiredComments?.updatedBy?.displayName}
                </span>
              </div>
              <div style={{ margin: '5px 0 5px 0' }}>
                <b>Comment</b>:
                <span style={{ margin: '0 0 0 10px' }}>
                  {record?.data?.retiredComments?.currentPhaseValue}
                </span>
              </div>
            </>
          ),
          onOk() {},
        });
      };
      return (
        <div>
          <Typography.Text>{placementType}</Typography.Text>
          {placementType === placementTypes.RETIRED &&
          record?.data?.retiredComments ? (
            <span style={{ margin: '0 0 0 5px' }}>
              <InfoCircleOutlined onClick={commentInfo} />
            </span>
          ) : null}
        </div>
      );
    },
  },
  {
    title: 'Medium',
    dataIndex: ['data', 'placementName'],
    key: 'placementName',
    fixed: 'left',
    width: columnWidths.placementNameTv,
    render: (text, row) => (
      <>
        <Typography.Text style={{ display: 'block' }}>
          {row.data.placementName}
        </Typography.Text>
        {row.data.editedByVendor ? <EditedBadge /> : <></>}
      </>
    ),
  },
  {
    title: 'Classification',
    dataIndex: ['data', 'weighting'],
    key: 'classification',
    render: (text, row) => (
      <div>
        <Typography.Text style={{ display: 'block' }}>
          {row.data.weighting.name}
        </Typography.Text>
      </div>
    ),
  },
  {
    title: 'Discount',
    dataIndex: ['data', 'discount'],
    key: 'discount',
    width: columnWidths.discount,
    showPrevData: true,
  },
  {
    title: 'Average Rate',
    dataIndex: ['data', 'averageRate'],
    key: 'averageRate',
    width: columnWidths.averageRate,
    showPrevData: true,
  },
  {
    title: 'Weighted Average Rate',
    dataIndex: ['data', 'weightedAverageRate'],
    key: 'weightedAverageRate',
    width: columnWidths.averageRate,
    showPrevData: true,
  },
  {
    title: 'Average Audience - P18+',
    // fixed: 'right',
    children: [
      {
        title: 'Audience',
        dataIndex: ['data', 'averageAudienceP18'],
        key: 'averageAudienceP18',
        width: columnWidths.averageAudienceTv,
        showPrevData: true,
      },
      {
        title: 'Slip Discount',
        dataIndex: ['data', 'averageAudienceP18SlipDiscount'],
        key: 'averageAudienceP18SlipDiscount',
        width: columnWidths.averageAudienceTv,
        showPrevData: true,
      },
      {
        title: 'CPM',
        dataIndex: ['data', 'averageAudienceP18CPM'],
        key: 'averageAudienceP18CPM',
        width: columnWidths.averageAudienceTv,
        showPrevData: true,
      },
    ],
  },
  {
    title: 'Average Audience - P18-39',
    children: [
      {
        title: 'Audience',
        dataIndex: ['data', 'averageAudienceP18_39'],
        key: 'averageAudienceP18_39',
        width: columnWidths.averageAudienceTv,
        showPrevData: true,
      },
      {
        title: 'Slip Discount',
        dataIndex: ['data', 'averageAudienceP18_39SlipDiscount'],
        key: 'averageAudienceP18_39SlipDiscount',
        width: columnWidths.averageAudienceTv,
        showPrevData: true,
      },
      {
        title: 'CPM',
        dataIndex: ['data', 'averageAudienceP18_39CPM'],
        key: 'averageAudienceP18_39CPM',
        width: columnWidths.averageAudienceTv,
        showPrevData: true,
      },
    ],
  },
  {
    title: 'Average Audience - P25-54',
    children: [
      {
        title: 'Audience',
        dataIndex: ['data', 'averageAudienceP25_54'],
        key: 'averageAudienceP25_54',
        width: columnWidths.averageAudienceTv,
        showPrevData: true,
      },
      {
        title: 'Slip Discount',
        dataIndex: ['data', 'averageAudienceP25_54SlipDiscount'],
        key: 'averageAudienceP25_54SlipDiscount',
        width: columnWidths.averageAudienceTv,
        showPrevData: true,
      },
      {
        title: 'CPM',
        dataIndex: ['data', 'averageAudienceP25_54CPM'],
        key: 'averageAudienceP25_54CPM',
        width: columnWidths.averageAudienceTv,
        showPrevData: true,
      },
    ],
  },
  {
    title: 'Average Audience - P55+',
    children: [
      {
        title: 'Audience',
        dataIndex: ['data', 'averageAudienceP55'],
        key: 'averageAudienceP55',
        width: columnWidths.averageAudienceTv,
        showPrevData: true,
      },
      {
        title: 'Slip Discount',
        dataIndex: ['data', 'averageAudienceP55SlipDiscount'],
        key: 'averageAudienceP55SlipDiscount',
        width: columnWidths.averageAudienceTv,
        showPrevData: true,
      },
      {
        title: 'CPM',
        dataIndex: ['data', 'averageAudienceP55CPM'],
        key: 'averageAudienceP55CPM',
        width: columnWidths.averageAudienceTv,
        showPrevData: true,
      },
    ],
  },
  {
    title: 'Average Audience - P18-54',
    children: [
      {
        title: 'Audience',
        dataIndex: ['data', 'averageAudienceP18_54'],
        key: 'averageAudienceP18_54',
        width: columnWidths.averageAudienceTv,
        showPrevData: true,
      },
      {
        title: 'Slip Discount',
        dataIndex: ['data', 'averageAudienceP18_54SlipDiscount'],
        key: 'averageAudienceP18_54SlipDiscount',
        width: columnWidths.averageAudienceTv,
        showPrevData: true,
      },
      {
        title: 'CPM',
        dataIndex: ['data', 'averageAudienceP18_54CPM'],
        key: 'averageAudienceP18_54CPM',
        width: columnWidths.averageAudienceTv,
        showPrevData: true,
      },
    ],
  },
  {
    title: 'Comments',
    dataIndex: ['data', 'comment'],
    key: 'comment',
    ellipsis: true,
    width: columnWidths.comment,
  },
  {
    title: 'Year on Year Variance',
    fixed: 'right',
    children: [
      {
        title: 'Discount',
        dataIndex: ['data', 'discountYOYVariance'],
        key: 'discountYOYVariance',
        width: columnWidths.discountYoY,
      },
      {
        title: 'Average Rate',
        dataIndex: ['data', 'averageRateYOYVariance'],
        key: 'averageRateYOYVariance',
        width: columnWidths.averageRate,
      },
      {
        title: 'P18+',
        dataIndex: ['data', 'averageAudienceP18YOYVariance'],
        key: 'averageAudienceP18YOYVariance',
        width: columnWidths.averageAudienceTvYoY,
      },
      {
        title: 'P18+ CPM',
        dataIndex: ['data', 'averageAudienceP18CPMYOYVariance'],
        key: 'averageAudienceP18CPMYOYVariance',
        width: columnWidths.averageAudienceTvYoY,
      },
      {
        title: 'P18-39',
        dataIndex: ['data', 'averageAudienceP18_39YOYVariance'],
        key: 'averageAudienceP18_39YOYVariance',
        width: columnWidths.averageAudienceTvYoY,
      },
      {
        title: 'P18-39 CPM',
        dataIndex: ['data', 'averageAudienceP18_39CPMYOYVariance'],
        key: 'averageAudienceP18_39CPMYOYVariance',
        width: columnWidths.averageAudienceTvYoY,
      },
      {
        title: 'P25-54',
        dataIndex: ['data', 'averageAudienceP25_54YOYVariance'],
        key: 'averageAudienceP25_54YOYVariance',
        width: columnWidths.averageAudienceTvYoY,
      },
      {
        title: 'P25-54 CPM',
        dataIndex: ['data', 'averageAudienceP25_54CPMYOYVariance'],
        key: 'averageAudienceP25_54CPMYOYVariance',
        width: columnWidths.averageAudienceTvYoY,
      },
      {
        title: 'P55+',
        dataIndex: ['data', 'averageAudienceP55YOYVariance'],
        key: 'averageAudienceP55YOYVariance',
        width: columnWidths.averageAudienceTvYoY,
      },
      {
        title: 'P55+ CPM',
        dataIndex: ['data', 'averageAudienceP55CPMYOYVariance'],
        key: 'averageAudienceP55CPMYOYVariance',
        width: columnWidths.averageAudienceTvYoY,
      },
      {
        title: 'P18-54',
        dataIndex: ['data', 'averageAudienceP18_54YOYVariance'],
        key: 'averageAudienceP18_54YOYVariance',
        width: columnWidths.averageAudienceTvYoY,
      },
      {
        title: 'P18-54 CPM',
        dataIndex: ['data', 'averageAudienceP18_54CPMYOYVariance'],
        key: 'averageAudienceP18_54CPMYOYVariance',
        width: columnWidths.averageAudienceTvYoY,
      },
    ],
  },
];
