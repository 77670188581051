/* eslint-disable react/forbid-prop-types */
import './styles.css';

import { Table } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import EditableCell from '../FormInputs/EditableCell';
import CalculatedCell from './CalculatedCell';
import TemplateTableHeader from './TemplateTableHeader';

const TemplateTable = React.memo(
  ({
    tableData,
    columns,
    tableHeight = 0,
    tableHeaderHeight = 0,
    ...restProps
  }) => {
    const scrollY = tableHeight - tableHeaderHeight || '100%';

    return (
      <Table
        components={{
          header: {
            wrapper: TemplateTableHeader,
          },
          body: {
            // eslint-disable-next-line react/prop-types
            cell: ({ isCalculatedCell, ...rest }) => {
              if (isCalculatedCell) {
                return <CalculatedCell {...rest} />;
              }

              return <EditableCell {...rest} />;
            },
          },
        }}
        bordered
        dataSource={tableData}
        columns={columns}
        rowClassName={() => 'editable-row'}
        pagination={false}
        className="contract-table"
        scroll={{ x: 'max-content', y: scrollY }}
        {...restProps}
      />
    );
  }
);

TemplateTable.displayName = 'TemplateTable';

TemplateTable.propTypes = {
  tableData: PropTypes.array,
  columns: PropTypes.array.isRequired,
  tableHeight: PropTypes.number,
  tableHeaderHeight: PropTypes.number,
};

export default TemplateTable;
