import { createContext, useEffect, useState } from 'react';

import {
  contractPlacementOptionsHeight,
  contractTabBarHeight,
  topNavigationHeight,
} from '../../constants';
import useWindowSize from '../../hooks/useWindowSize';

export const ContractUIContext = createContext(null);

export const useContractUIContext = () => {
  const windowSize = useWindowSize();
  const [activeTab, setActiveTab] = useState('');
  const [contractHeaderHeight, setContractHeaderHeight] = useState(0);
  const [contractTableHeaderHeight, setContractTableHeaderHeight] = useState(0);
  const [tableHeight, setTableHeight] = useState(0);
  const [dataHasChanged, setDataHasChanged] = useState(false);
  const [hasValidationErrors, setHasValidationErrors] = useState(false);

  useEffect(() => {
    setTableHeight(
      windowSize.height -
        topNavigationHeight -
        contractHeaderHeight -
        contractTabBarHeight -
        contractPlacementOptionsHeight
    );
  }, [windowSize, contractHeaderHeight]);

  return {
    activeTab,
    setActiveTab,
    contractHeaderHeight,
    setContractHeaderHeight,
    contractTableHeaderHeight,
    setContractTableHeaderHeight,
    tableHeight,
    dataHasChanged,
    setDataHasChanged,
    hasValidationErrors,
    setHasValidationErrors,
  };
};
