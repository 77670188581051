import Text from 'antd/lib/typography/Text';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { allMedumStatues } from '../../constants';
import { selectIsContractInDraft } from '../../store/selectors';

const StatusLabel = ({ status }) => {
  const isContractInDraft = useSelector(selectIsContractInDraft);

  const renderStatusLabel = () => {
    switch (status) {
      // Contract is either in Draft or First Revision
      case allMedumStatues.revision:
        // Contract is in Draft
        if (isContractInDraft) return allMedumStatues.draft;

        // Contract/Placement is in it's first round of revision (ie. has not been reviewed yet)
        return allMedumStatues.revision;

      // Contract/Placement is now in Agency to Review but yet to be approved/rejected by Agency
      case allMedumStatues.review:
        return allMedumStatues.review;

      // Placement has been accepted by Agency
      case allMedumStatues.approved:
        return allMedumStatues.approved;

      // Placement has been rejected by Agency
      case allMedumStatues.rejected:
        return allMedumStatues.rejected;
      case allMedumStatues.default:
        return allMedumStatues.default;
      default:
        return allMedumStatues.draft;
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Text style={{ fontSize: 12, marginLeft: 6 }}>{renderStatusLabel()}</Text>
    </div>
  );
};

StatusLabel.propTypes = {
  status: PropTypes.string.isRequired,
};

export default StatusLabel;
