import { Space } from 'antd';
import React from 'react';

import { contractPlacementOptionsHeight } from '../../constants';
import WeightingActions from './WeightingActions';

const WeightingOptions = () => {
  return (
    <Space
      style={{
        padding: '8px 24px',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        height: contractPlacementOptionsHeight,
      }}
      align="right"
    >
      <WeightingActions />
    </Space>
  );
};

export default WeightingOptions;
