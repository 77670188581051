/* eslint-disable consistent-return */
import { InputNumber } from 'antd';
import React from 'react';

import { displayTypeSymbol } from '../../constants';

const InputMoney = React.memo(({ ...props }) => {
  const renderInputFormatter = (value) => {
    return `${value ? displayTypeSymbol.money : ''}${value}`.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ','
    );
  };

  const renderInputParser = (value) => {
    return value.replace(/\$\s?|(,*)/g, '');
  };

  return (
    <InputNumber
      suffix={displayTypeSymbol.money}
      formatter={renderInputFormatter}
      parser={renderInputParser}
      {...props}
    />
  );
});

InputMoney.displayName = 'InputMoney';

// TODO: Update these propTypes
/* eslint-disable react/forbid-prop-types */
InputMoney.propTypes = {};

export default InputMoney;
