import { Space } from 'antd';
import Text from 'antd/lib/typography/Text';
import PropTypes from 'prop-types';
import React from 'react';

const ContractLabel = ({ label, text, dataCy }) => {
  return (
    <Space direction="vertical">
      <Text
        style={{
          textTransform: 'uppercase',
          fontSize: 12,
          fontWeight: 500,
          opacity: 0.7,
        }}
      >
        {label}
      </Text>
      <Text data-cy={dataCy}>{text}</Text>
    </Space>
  );
};

ContractLabel.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  dataCy: PropTypes.string,
};

export default ContractLabel;
