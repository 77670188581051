// eslint-disable-next-line import/no-extraneous-dependencies
import { Contracts } from '@mbdt/shared/datacy';
import { Button, Form, Input, Modal } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { deleteConfirmation, placementTypes } from '../../constants';
import { ContractUIContext } from '../../containers/Contract/ContractUIContext';
import { removePlacements } from '../../store/actions/contract.actions';

const { TextArea } = Input;

const useContractActionQuery = () => {
  const { search } = useLocation();
  const action = new URLSearchParams(search).get('action');
  const recordId = new URLSearchParams(search).get('recordId');

  return {
    action,
    recordId,
  };
};

const RetirePlacementModal = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { sectionTypes, isRemovingPlacement } = useSelector(
    (state) => state.contract
  );
  const tableData = useSelector((state) => state.contract.tableData);
  const { activeTab } = useContext(ContractUIContext);
  const sectionType = sectionTypes?.find(
    (section) => section.type === activeTab
  );
  const { id: contractId, mediaType } = useParams();
  const { recordId, action } = useContractActionQuery();
  const history = useHistory();
  const [placement, setPlacement] = useState(null);
  const [isSelectedRowActive, setIsSelectedRowActive] = useState(false);

  const handleClose = () => {
    // Clear query params on close
    setPlacement(null);
    form.resetFields();
    history.replace({
      search: '',
    });
  };

  const handleSubmit = () => {
    form.validateFields().then(async () => {
      const commment = isSelectedRowActive
        ? form.getFieldValue(['comment'])
        : '';
      await dispatch(
        removePlacements(
          contractId,
          mediaType,
          sectionType.type,
          [recordId], // pass array of ids
          commment,
          handleClose
        )
      );
    });
  };

  // const showRemoveConfirmModal = async () => {
  //   confirm({
  //     title: deleteConfirmation.placements.title,
  //     icon: <ExclamationCircleOutlined />,
  //     content: isSelectedRowActive ? (
  //       <>
  //         <Form preserve={false} name="add-comment" layout="vertical" form={form}>
  //           <Form.Item
  //             label="Comment"
  //             name="comment"
  //             rules={[
  //               {
  //                 required: true,
  //                 message: deleteConfirmation.placements.required,
  //               },
  //             ]}
  //           >
  //             <TextArea
  //               rows={4}
  //               placeholder={deleteConfirmation.placements.commentPlaceholder}
  //             />
  //           </Form.Item>
  //         </Form>
  //       </>
  //     ) : (
  //       deleteConfirmation.placements.description
  //     ),
  //     okText: deleteConfirmation.placements.submitButtonLabel,
  //     async onOk() {
  //       const commment = isSelectedRowActive ? form.getFieldValue(['comment']) : '';
  //       await form.validateFields();
  //       dispatch(
  //         removePlacements(
  //           contractId,
  //           mediaType,
  //           sectionType.type,
  //           [recordId], // pass array of ids
  //           commment
  //         )
  //       );
  //     },
  //     onCancel() {
  //       clear();
  //     },
  //     width: 500,
  //   });
  // };

  useEffect(() => {
    if (action && action === 'retire' && recordId) {
      const selected = tableData?.find((rowData) => rowData.id === recordId);
      if (selected) {
        setPlacement(selected);
        setIsSelectedRowActive(
          selected.data.placementType === placementTypes.ACTIVE
        );
      }
    }
  }, [action, recordId]);

  return (
    <Modal
      width={500}
      visible={!!placement}
      title={deleteConfirmation.placements.title}
      onCancel={handleClose}
      destroyOnClose
      forceRender
      footer={[
        <Button
          disabled={isRemovingPlacement}
          key="back"
          onClick={handleClose}
          data-cy={`${Contracts.RetirePlacementModal}-${Contracts.PlacementModalCancel}`}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={isRemovingPlacement}
          loading={isRemovingPlacement}
          onClick={handleSubmit}
          data-cy={`${Contracts.RetirePlacementModal}-${Contracts.PlacementModalConfirm}`}
        >
          Confirm
        </Button>,
      ]}
      data-cy={Contracts.RetirePlacementModal}
    >
      {isSelectedRowActive ? (
        <Form preserve={false} name="add-comment" layout="vertical" form={form}>
          <Form.Item
            label="Comment"
            name="comment"
            rules={[
              {
                required: true,
                message: deleteConfirmation.placements.required,
              },
            ]}
          >
            <TextArea
              disabled={isRemovingPlacement}
              rows={4}
              placeholder={deleteConfirmation.placements.commentPlaceholder}
              data-cy={Contracts.FieldComment}
            />
          </Form.Item>
        </Form>
      ) : (
        deleteConfirmation.placements.description
      )}
    </Modal>
  );
};
export default RetirePlacementModal;
